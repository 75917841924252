import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, union, props } from '@ngrx/store';
import * as fromOptions from '@options/store/options.reducer';

// CompanyProfile / CompanyInfo / Navigation
// CompanyProfile / EditApiKey / REQUEST_API_KEY_CLICKED
// CompanyProfile / Edit Merchant / ADD_MERCHANT_CLICKED | EDIT_MERCHANT_CLICKED Effect
// CompanyProfile / Edit Store / ADD_STORE_CLICKED | EDIT_STORE_CLICKED Effect
// CompanyProfile / Edit Webhook / ADD_WEBHOOK_CLICKED | EDIT_WEBHOOK_CLICKED Effect
// Onboarding / Process / SET_STEP_COMPANY_INFO Effect
// Onboarding / EditStore / ADD_STORE_CLICKED | EDIT_STORE_CLICKED Effect
// Home / Organisation ORGANISATION_DATA_NEEDED Effect
export const optionsNeeded = createAction(
  '[ Options ][ Flow Init Actions Effect ] OPTIONS_NEEDED',
  props<{neededKeys: fromOptions.IOptionSlice[]}>()
);

export const fetchOptions = createAction(
  '[ Options ][ OPTIONS_NEEDED Effect ] FETCH_OPTIONS',
  props<{missingKeys: fromOptions.IOptionSlice[]}>()
);

export const fetchOptionsSuccess = createAction(
  '[ Options ][ FETCH_OPTIONS Effect ] FETCH_OPTIONS_SUCCESS',
  props<{options: {[key in fromOptions.IOptionSlice]?: any[]}}>()
);

export const fetchOptionsFailure = createAction(
  '[ Options ][ FETCH_OPTIONS Effect ] FETCH_OPTIONS_FAILURE',
  props<{errorMsg: string}>()
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

const all = union({
  optionsNeeded,
  fetchOptions,
  fetchOptionsSuccess,
  fetchOptionsFailure,
  receiveReset,
});
export type Union = typeof all;
