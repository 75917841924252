import { ISignupStep } from '@interfaces/signup.interface';

// Metadata for conf for each step - current/total for progress
export const SIGNUP_STEP_META: {
  [key in ISignupStep]: {
    count: {current: number, total: number}
  }
} = {
  'step-details': {
    count: {current: 0, total: 2},
  },
  'step-verification': {
    count: {current: 1, total: 2},
  },
  'step-password-create': {
    count: {current: 2, total: 2},
  },
  'step-complete': {
    count: {current: 3, total: 2},
  },
  'step-password-recover': {
    count: {current: 1, total: 2},
  },
  'step-password-recovered': {
    count: {current: 2, total: 2},
  },
};
