import { MiscUtils } from '@utils/misc';
import { HttpEvent, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { tap, delay, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';

// Service used to mock responses of endpoints that are temporarily not usable (eg no data, 404 etc)
@Injectable()
export class MockAdapterService {

  private delayResult<T>(payload: T, message: string): Observable<T> {
    return of(payload).pipe(
      tap(() => console.log(message)),
      delay(1000)
    );
  }

  private delayError<T>(payload: any, message: string): Observable<T> {
    return of(null).pipe(
      tap(() => console.log(message)),
      delay(1000),
      switchMap(() => throwError(payload))
    );
  }

  uploadImage(merchantId: string, file: File, resultUrl?: string): Observable<HttpEvent<string>> {
    const pulseTimeouts = [1000, 1500, 2000, 2500, 3000];
    return new Observable(observer => {
      for (let i = 0; i < pulseTimeouts.length; i++) {
        if (i < pulseTimeouts.length - 1) {
          MiscUtils.setObservableTimeout(() => observer.next({
            type: 1,
            loaded: Math.round((file.size / 5) * (i + 1)),
            total: file.size
          } as HttpProgressEvent), pulseTimeouts[i]);
        } else {
          MiscUtils.setObservableTimeout(() => {
            observer.next(new HttpResponse({body: resultUrl}));
            observer.complete();
          }, pulseTimeouts[i]);
        }
      }
    });
  }
}
