import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStreams } from '@interfaces/misc.interface';
import * as SignupActions from '@signup/store/signup.actions';
import { SignupFacade } from '@signup/store/signup.facade';
import { FormsUtils } from '@utils/forms';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import { ResendActivationEmailFacade } from '@auth/resend-activation-email/store/resend-activation-email.facade';
import { environment } from '@environments/environment';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';

@Component({
  selector: 'app-step-details',
  templateUrl: './step-details.component.html',
  styleUrls: ['./step-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AppearAnimationsFactory.fadeUp('fadeState')
  ]
})
export class StepDetailsComponent implements OnInit, OnDestroy {

  termsAndConditionsLink = environment.termsAndConditionsLink;
  privacyPolicyLink = environment.privacyPolicyLink;
  isRequesting$ = this.rxSignup.isRequesting$;

  form: FormGroup;
  errors: ErrorStreams = {};
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private rxSignup: SignupFacade,
    private rxResendActivationEmail: ResendActivationEmailFacade
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      first: [null, Validators.required],
      last: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      consent: [null, Validators.requiredTrue]
    });

    this.errors = FormsUtils.getErrorStreams(this.destroy$, {
      first$: this.form.get('first'),
      last$: this.form.get('last'),
      email$: this.form.get('email'),
      consent$: this.form.get('consent'),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onOpenPrivacyPolicy() {
    this.rxSignup.dispatch(SignupActions.privacyPolicyLinkClicked());
  }

  onSubmit() {
    if (this.form.valid) {
      const { first, last, email } = this.form.value;
      this.rxSignup.dispatch(SignupActions.oktaAccountDetailsSubmitted({first, last, email}));
    } else {
      FormsUtils.showAllErrors(this.form);
      this.notifier.show({type: 'warning', message: 'Please correct the errors and try again.'});
    }
  }

  onResendActivationEmail() {
    this.rxResendActivationEmail.dispatch(ResendActivationEmailActions.resendActivationEmailClicked());
  }

}
