import * as AppActions from '@app/store/app.actions';
import { ActionReducerMap } from '@ngrx/store';

import { InjectionToken } from '@angular/core';
import * as fromUI from '@ui/store/ui.reducer';
import * as fromOptions from '@options/store/options.reducer';
import * as fromRouter from '@ngrx/router-store';

// Registering reducers has to use InjectionToken pattern for dev to be able to use compose(combineReducers)(reducers) later when nesting them
// https://github.com/ngrx/platform/issues/306#issuecomment-323829336

export interface AppState {
  [reportName: string]: {};
  ui: fromUI.State;
  options: fromOptions.State;
  router: fromRouter.RouterReducerState<any>;
}

export const reducerToken = new InjectionToken<ActionReducerMap<AppState, AppActions.Union>>('App Reducers');

export function getReducers() {
  return {
    ui: fromUI.reducer,
    options: fromOptions.reducer,
    router: fromRouter.routerReducer
  };
}

export const reducerProvider = [
  {provide: reducerToken, useFactory: getReducers}
];
