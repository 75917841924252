import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import { FormsUtils } from '@utils/forms';
import { ResendActivationEmailFacade } from '@auth/resend-activation-email/store/resend-activation-email.facade';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ErrorStreams } from '@interfaces/misc.interface';
import { ComponentCanDeactivate } from '@models/component-can-deactivate.model';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-resend-activation-email',
  templateUrl: './resend-activation-email.component.html',
  styleUrls: ['./resend-activation-email.component.scss'],
  host: {'[class.card-dialog__component--narrow]': 'true'},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendActivationEmailComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  isRequesting$ = this.rxResendActivationEmail.isRequesting$;

  form: FormGroup;
  errors: ErrorStreams = {};

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private rxResendActivationEmail: ResendActivationEmailFacade
  ) {
    super();
  }

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required]],
    });

    this.errors = FormsUtils.getErrorStreams(this.destroy$, {
      email$: this.form.get('email'),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const email = this.form.value.email;
      this.rxResendActivationEmail.dispatch(ResendActivationEmailActions.emailForActivationResendSubmitted({email}));
    } else {
      FormsUtils.showAllErrors(this.form);
      this.notifier.show({type: 'warning', message: 'Please correct errors and try again.'});
    }
  }

  canDeactivate(): boolean {
    return !this.form.pristine;
  }
}
