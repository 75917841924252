// ! [AUTOGENERATED BY /scripts/set-env.ts]
export const environment = {
  version: require('../../package.json').version,
  production: false,
  apiOrigin: 'https://api.uat-slyp.com.au',
  mySlypV2RedirectUrl: 'https://my-v2.uat-slyp.com.au',
  okta: {
    issuer: 'https://dev-698520.okta.com/oauth2/default',
    redirectUri: '{ORIGIN}/auth/callback',
    clientId: '0oa9gto63mXBlrvhY356',
    pkce: false,
  },
  demo: {
    merchantId: '',
    apiKey: '',
    isActive: !!0,
    purpose: 'demo MySlyp using local API'
  },
  onboarding: {
    POSCrmFormUrl: 'https://api.hsforms.com/submissions/v3/integration/submit/2953347/6b654c37-2988-4ab0-8425-124dcd063104',
    POSRedirect: {
      kounta: {
        redirectURI: 'https://my.kounta.com/authorize?client_id=HLb7IGPNxqRWFr3G&redirect_uri={ORIGIN}/auth/signup&response_type=code&state={STATE}',
      },
      hike: {
        redirectURI: 'https://api.hikeup.com/oauth/authorize?client_id=UAT%20Smart%20Receipts-adc8f21df6&redirect_uri={ORIGIN}/auth/signup&response_type=code&scope=all&state={STATE}',
      },
    },
  },
  ngrxDevTools: !!1,
  debugOnboarding: !!0,
  debugSignup: !!0,
  slypInfoMail: 'info@slyp.com.au',
  slypSupportMail: 'support@slyp.com.au',
  devEmptyLogin: !!undefined,
  googleAnalyticsId: '0',
  isTestModeAdjustable: !!0,
  productCatalogLearnMoreLink: 'https://support.google.com/merchants/answer/7439882?hl=en',
  sentryDsn: 'https://24c53290037648e78151c7329cb28207@o185564.ingest.sentry.io/5238794',
  isSentryConfigured: false,
  contentful: {
    accessToken: 'rHpMrj9cPffEUgOp3QY_OrPP43s5sGlkeR_IgoFdBxc',
    space: 'ap1uecbieg9z',
    contentTypeIds: {
      termsAndConditionsEntry: 'termsAndConditionsEntry',
    },
  },
  termsAndConditionsLink: 'https://www.slyp.com.au/terms',
  privacyPolicyLink: 'https://www.slyp.com.au/privacy-policy',
  isResendSmsReceiptDisabled: !!1,
};
    