import { IStandardOpeningHoursDay, IWeekday } from '@interfaces/store.interface';

export const WEEKDAYS: IWeekday[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const WEEKDAYS_LABELS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const STORE_STANDARD_DAYS: IStandardOpeningHoursDay[] = [...WEEKDAYS, 'default_public_holiday'];

export const DATETIME_FORMAT_RECEIPT_TOTAL = 'DD MMMM YYYY [at] h:mma';
export const DATETIME_FORMAT_RECEIPT_HEADER = 'DD/MM/YYYY [at] h:mma';
export const DATETIME_FORMAT_RECEIPT_GST = 'DD/MM/YYYY, h:mma';
export const DATETIME_FORMAT_RECEIPT_FEED = 'MMM DD, YYYY - HH:mm';
export const DATE_FORMAT_RECEIPT_DETAIL = 'MMM DD, YYYY';
export const TIME_FORMAT_RECEIPT_DETAIL = 'HH:mm';

export const DATE_FORMAT_BACKEND = 'DD/MM/YYYY';
export const DATE_FORMAT_BACKEND_SHORT_YEAR = 'DD/MM/YY';
export const DATE_FORMAT_BACKEND_DASH = 'DD-MM-YYYY';

export const TIME_WEEKDAY = 'hh:mm a';
export const TIME_FORMAT_12 = 'hh:mm a';
export const TIME_FORMAT_24 = 'HH:mm';
export const TIME_FORMAT_MILITARY = 'HHmm';
export const TIME_FORMAT_HOUR_12 = 'ha';
export const DATE_FORMAT_YEAR = 'YYYY';
export const DATE_FORMAT_MONTH = 'MM/YYYY';
export const DAY_MONTH_FORMAT = 'DD/MM';
export const SHORT_MONTH_NAME_FORMAT = 'MMM';

export const SECONDS_IN_AN_HOUR = 3600;
export const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * 24;
export const MAX_DAYS_IN_A_MONTH = 31;
export const MAX_DAYS_IN_A_YEAR = 365;

export const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * MAX_DAYS_IN_A_MONTH;

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: DATE_FORMAT_BACKEND_SHORT_YEAR,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: DATE_FORMAT_BACKEND_SHORT_YEAR,
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DEFAULT_TIMEZONE = 'Australia/Sydney';
