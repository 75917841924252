import { MatIconModule } from '@angular/material/icon';
import { BrandComponent } from '@components/brand/brand.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    BrandComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    BrandComponent
  ],
  providers: [],
})
export class BrandModule {}
