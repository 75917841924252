import { ResendActivationEmailComponent } from '@auth/resend-activation-email/resend-activation-email.component';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmService } from '@components/confirm/confirm.service';

@Injectable()
export class ResendActivationEmailMountService {

  private dialogRef: MatDialogRef<ResendActivationEmailComponent> = null;

  constructor(private confirm: ConfirmService) {}

  openDialog() {
    this.dialogRef = this.confirm.openDeactivationProtectedDialog<ResendActivationEmailComponent>({
      contentComponent: ResendActivationEmailComponent,
      widthVariant: 'narrow'
    });
  }

  closeDialog() {
    if (!!this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }
}
