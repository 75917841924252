import * as ForgotPasswordActions from '@auth/forgot-password/store/forgot-password.actions';
import { createReducer, on } from '@ngrx/store';


export interface State {
  isRequesting: boolean;
  view: 'form' | 'success';
}

const initialState: State = {
  isRequesting: false,
  view: 'form',
};


export const reducer = createReducer<State, ForgotPasswordActions.Union>(
  initialState,
  on(ForgotPasswordActions.requestForgotPasswordEmail, state => ({
    ...state,
    isRequesting: true
  })),
  on(ForgotPasswordActions.requestForgotPasswordEmailSuccess, state => ({
    ...state,
    isRequesting: false,
    view: 'success'
  })),
  on(ForgotPasswordActions.requestForgotPasswordEmailFailure, state => ({
    ...state,
    isRequesting: false
  })),
  on(
    ForgotPasswordActions.forgotPasswordClicked,
    ForgotPasswordActions.receiveReset,
    state => ({
      ...initialState,
    })
  ),
);
