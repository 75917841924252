<div class="badge-circle big primary p-4 mb-5">
  <mat-icon>lock</mat-icon>
</div>
<div class="row no-gutters mb-4">
  <div class="col">
    <h2 class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin">Create {{ isPasswordRecovery ? 'new' : 'a' }} password</h2>
    <p class="sds-color-base-grey_medium mb-0">Secure your account by setting a password below.</p>
  </div>
</div>
<form [formGroup]="form" [appBlockRedundantSubmit]="isRequesting$ | async" (validNgSubmit)="onSubmit()">
  <div class="row no-gutters">
    <div class="col col-12 d-flex sds-form-field-btn-container with-trailing-btn">
      <mat-form-field appearance="fill" class="flex-grow-1 sds-form-field primary">
        <mat-label>Password</mat-label>
        <input matInput [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password" required appValidateOnBlur data-test="password">
      </mat-form-field>
      <div class="form-field-btn-wrapper">
        <div class="sds-btn form-field-btn" (click)="onTogglePasswordVisibility()">
          <mat-icon [class.sds-color-brand-primary]="!isPasswordHidden">remove_red_eye</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3 no-gutters">
    <app-multi-view class="col validation-wrapper" [current]="form.valid ? 'success' : 'errors'">
      <ng-container *appMultiViewItem="'errors'">
        <div class="row no-gutters" *ngLet="errorsList$ | async as errorsList">
          <div class="col col-6 col-lg-4 d-flex align-items-center validation-item"
            *ngFor="let validator of passwordValidators; trackBy: trackValidatorsBy"
            [class.satisfied]="!form.pristine && !errorsList.includes(validator.key)"
          >
            <div class="badge-dot primary mr-3"></div>
            <span class="sds-color-base-grey_medium-25W sds-text-caption-1">{{validator.label}}</span>
          </div>
        </div>
      </ng-container>

      <div *appMultiViewItem="'success'" class="badge-toast success align-self-start">
        <mat-icon>done</mat-icon>
        <span class="title">Your password is secure and you're all set!</span>
      </div>
    </app-multi-view>
  </div>
  <div class="row no-gutters">
    <div class="col">
      <button type="submit" class="my-3 sds-btn primary emphasized" [disabled]="form.invalid" data-test="createAccount">
        <app-dimmer class="sds-bg-brand-primary sds-color-base-white"
          [isDimmed]="isRequesting$ | async"
          [spinnerDiameter]="20"
          [strokeWidth]="1.5"
        ></app-dimmer>
        {{ isPasswordRecovery ? 'SAVE NEW PASSWORD' : 'CREATE AN ACCOUNT' }}
      </button>
    </div>
  </div>
</form>
