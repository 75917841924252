import { IPaginatedResponse } from '@interfaces/pagination.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IMerchantAttributes } from '@interfaces/merchant.interface';
import { IMerchantApiKeyAllowedRole, IApiKey } from '@interfaces/api-key.interface';
import { IWebhook } from '@interfaces/webhook.interface';
import { map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ICloudPOSProviderName } from '@interfaces/pos.interface';


@Injectable()
export class MerchantArtifactsAdapterService {

  constructor(private http: HttpClient) {}

  fetchAttributes(id: string): Observable<IMerchantAttributes> {
    return this.http.get<IMerchantAttributes>(`/v1/merchants/${id}/attributes/`);
  }

  patchAttributes(id: string, attrsUpdate: {[key in keyof IMerchantAttributes]?: IMerchantAttributes[key]}): Observable<IMerchantAttributes> {
    return this.http.put<IMerchantAttributes>(`/v1/merchants/${id}/attributes/`, attrsUpdate);
  }

  fetchApiKeys(merchantId: string): Observable<IApiKey[]> {
    return this.http.get<IPaginatedResponse<IApiKey>>(`/v1/merchants/${merchantId}/api-keys`)
      .pipe(map(result => result.items));
  }

  requestApiKey(merchantId: string, allowedRole: IMerchantApiKeyAllowedRole, owner: string): Observable<IApiKey> {
    return this.http.post<IApiKey>(`/v1/merchants/${merchantId}/api-keys`, {allowedRole, owner});
  }

  revokeApiKey(merchantId: string, apiKeyId: string): Observable<void> {
    return this.http.delete<void>(`/v1/merchants/${merchantId}/api-keys/${apiKeyId}`);
  }

  fetchWebhooks(merchantId: string): Observable<IWebhook[]> {
    return this.http.get<IPaginatedResponse<IWebhook>>(`/v1/merchants/${merchantId}/webhooks`)
      .pipe(map(result => result.items));
  }

  fetchWebhook(merchantId: string, webhookId: string): Observable<IWebhook> {
    return this.http.get<IWebhook>(`/v1/merchants/${merchantId}/webhooks/${webhookId}`);
  }

  fetchLoyaltyWebhook(id: string): Observable<IWebhook> {
    return this.http.get<IPaginatedResponse<IWebhook>>(`/v1/merchants/${id}/webhooks`)
      .pipe(
        map(({items}) => items.filter(item => item.event === 'receipt_loyalty_enrolled')),
        map(loyaltyWebhooks => loyaltyWebhooks.sort((prev, next) => prev.created_at - next.created_at)), // sort asc by created_at
        map(loyaltyWebhooks => loyaltyWebhooks.length ? loyaltyWebhooks[0] : null)
      );
  }

  createWebhook(merchantId: string, webhook: IWebhook): Observable<IWebhook> {
    return this.http.post<IWebhook>(`/v1/merchants/${merchantId}/webhooks/`, webhook);
  }

  updateWebhook(merchantId: string, webhookId: string, webhook: IWebhook): Observable<void> {
    return this.http.put<void>(`/v1/merchants/${merchantId}/webhooks/${webhookId}/`, webhook);
  }

  deleteWebhook(merchantId: string, webhookId: string): Observable<void> {
    return this.http.delete<void>(`/v1/merchants/${merchantId}/webhooks/${webhookId}/`);
  }

  upsertLoyaltyWebhook(merchant: string, url: string, webhook: IWebhook): Observable<IWebhook | void> {
    const data = {merchant, url};

    if (webhook) {
      return this.updateWebhook(merchant, webhook.id, {...webhook, ...data});
    } else {
      return this.createWebhook(
        merchant,
        {
          ...data,
          event: 'receipt_loyalty_enrolled',
          secret: uuid(),
        }
      );
    }
  }

  checkPosProviderSyncStatus(merchantId: string, providerName: ICloudPOSProviderName): Observable<boolean> {
    return this.http.get<any>(`/v1/merchants/${merchantId}/plugin-metadata/${providerName.toLowerCase()}`)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
