import { take, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SigninFacade } from '@signin/store/signin.facade';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private rxSignin: SigninFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.rxSignin.state$.pipe(
      take(1),
      switchMap(state => {
        if (state.isAuthenticated && request.url.includes('v1')) { // only include auth token for calls to MySlyp API
          request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${state.slypToken.accessToken}`, // TODO differentiate between Slyp API calls and 3rd party
            }
          });
        }
        return next.handle(request);
      })
    );
  }
}
