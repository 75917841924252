import { MY_HUB_ROUTE } from '@configs/routing';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from '@app/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { RoleGuard } from '@guards/role.guard';
import { MYSLYP_V2_ROUTES } from '@configs/routing';
import { MySlypV2Component } from '@app/home/myslyp-v2/myslyp-v2.component';
import { SignOutComponent } from './home/sign-out/sign-out.component';

const routes: Routes = [
  { path: '', redirectTo: MY_HUB_ROUTE.url, pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canLoad: [AuthGuard], canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'onboarding', loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule), canLoad: [AuthGuard], canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: MYSLYP_V2_ROUTES.onboarding.url, component: MySlypV2Component, data: { redirectPath: 'onboarding' }, canLoad: [AuthGuard], canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  { path: 'not-found', component: NotFoundComponent, canActivate: [AuthGuard] },
  { path: 'sign-out', component: SignOutComponent },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  providers: [AuthGuard, RoleGuard],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
