<div class="card-title">
  <span>Didn't receive your signup activation link?</span>
  <div class="flex-grow-1"></div>
  <mat-icon class="sds-color-base-grey_medium-25W sds-btn-any no-appearance" mat-dialog-close data-test="close">close</mat-icon>
</div>

<form [appBlockRedundantSubmit]="isRequesting$ | async" (validNgSubmit)="onSubmit()" [formGroup]="form">
  <div class="card-body">
    <div class="row no-gutters">
      <div class="col">
        <p class="sds-color-base-grey_medium-25W">
          Tell us your email and we'll send a new activation link if applicabble.
        </p>
      </div>
    </div>
    <div class="row no-gutters">
      <mat-form-field appearance="fill" class="col-12 sds-form-field primary">
        <mat-label>Email</mat-label>
        <input matInput type="text" name="email" formControlName="email"  data-test="email" required appValidateOnBlur>
        <mat-error *ngIf="errors['email$'] | async as error" data-test="error">{{ error }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="card-actions">
    <button type="button" class="sds-btn" data-test="cancel" mat-dialog-close>cancel</button>
    <div class="flex-grow-1"></div>
    <button type="submit" class="sds-btn primary emphasized" data-test="submit">
      <app-dimmer class="sds-bg-brand-primary sds-color-base-white"
        [isDimmed]="isRequesting$ | async"
        [spinnerDiameter]="20"
        [strokeWidth]="1.5"
      ></app-dimmer>
      SUBMIT
    </button>
  </div>
</form>
