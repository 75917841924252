import { Injectable } from '@angular/core';
import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import * as OptionsActions from '@options/store/options.actions';
import * as OptionsSelectors from '@options/store/options.selectors';
import * as fromOptions from '@options/store/options.reducer';

@Injectable()
export class OptionsFacade {

  areOptionsLoading$ = this.rxStore.select(OptionsSelectors.selectIsLoadingState);
  options$ = (key: fromOptions.IOptionSlice) => this.rxStore.select(OptionsSelectors.selectOptions(key));

  constructor(private rxStore: Store<fromApp.AppState>) {}

  dispatch(action: OptionsActions.Union) {
    this.rxStore.dispatch(action);
  }

}
