import * as OptionsActions from '@options/store/options.actions';
import { createAction, union } from '@ngrx/store';
import * as UIActions from '@ui/store/ui.actions';
import { GLOBAL_RESET_SIGNAL_TYPE } from './shared-actions';


export interface IErrorNotificationPayload {
  errorTrigger?: string;
  errorMsg?: string;
}

export const notifyError = createAction(
  '[ APP ][ Failure Effect ] NOTIFY_ERROR',
  ({errorTrigger, errorMsg}: IErrorNotificationPayload) => {
    if (!errorTrigger) {
      return {
        message: errorMsg,
      }
    }

    return {
      message: `Something went wrong while ${errorTrigger}. ${errorMsg}`
    }
  }
);

export const signalReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

const all = union({
  notifyError,
  signalReset
});
export type Union
  = typeof all
  | OptionsActions.Union
  | UIActions.Union
  ;
