import { DragDropUtils } from '@utils/drag-drop';
import * as UISelectors from '@ui/store/ui.selectors';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as NavigationActions from '@navigation/store/navigation.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UIActions from '@ui/store/ui.actions';
import { map, withLatestFrom, tap, filter } from 'rxjs/operators';
import * as fromApp from '@app/store/app.reducer';

@Injectable()
export class UIEffects {

  screenResized$ = createEffect(() => this.actions$.pipe(
    ofType(UIActions.screenResized),
    map(() => UIActions.recalculateIsMobile())
  ));

  adjustSidenav$ = createEffect(() => this.actions$.pipe(
    ofType(
      UIActions.recalculateIsMobile,
      UIActions.screenWidthUpdateNeeded
    ),
    withLatestFrom(this.rxStore.select(UISelectors.selectIsDesktop)),
    map(([_, isDesktop]) => NavigationActions.adjustSidenavToScreenWidth({isDesktop}))
  ));

  adjustScrollElementToDrag$ = createEffect(() => this.actions$.pipe(
    ofType(UIActions.adjustScrollElementToDrag),
    withLatestFrom(this.rxStore.select(UISelectors.selectScrollingElement)),
    filter(([_, scrollingElement]) => !!scrollingElement),
    map(([{pointerPosition}, scrollingElement]) => ({
      scrollingElement,
      top: DragDropUtils.getScrollTargetForDrag({...pointerPosition}, scrollingElement)
    })),
    filter(({top}) => top !== null),
    tap(({top, scrollingElement}) => scrollingElement.scroll({top, behavior: 'smooth'}))
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private rxStore: Store<fromApp.AppState>
  ) {}

}
