import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthSelectors from '@auth/store/auth.selectors';

@Injectable()
export class AuthFacade {
  isAuthLoaded$ = this.rxStore.select(AuthSelectors.selectIsAuthLoaded);
  constructor(private rxStore: Store<fromAuth.AppState>) {}
}
