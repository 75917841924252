import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as fromOptions from '@options/store/options.reducer';

export const selectState = createFeatureSelector<fromApp.AppState, fromOptions.State>('options');

export const selectIsLoadingState = createSelector(
  selectState,
  state => state.isLoading
);

export const selectMissingOptionsKeys = (keys: fromOptions.IOptionSlice[]) =>
  createSelector(
    selectState,
    ({options}) => keys.filter(key => !options[key] || !options[key].length)
  );

export const selectOptions = (key: fromOptions.IOptionSlice) =>
  createSelector(
    selectState,
    ({options}) => options[key] || []
  );
