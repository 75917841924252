import { IMerchantAttributes } from '@interfaces/merchant.interface';
import { IPOSProviderOption, IPOSProviderName } from '@interfaces/pos.interface';
import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, union, props } from '@ngrx/store';

export const resyncClicked = createAction(
  '[ CompanyProfile/POS ][ PosComponent ] RESYNC_CLICKED',
);

export const confirmResync = createAction(
  '[ CompanyProfile/POS ][ RESYNC_CLICKED Effect ] CONFIRM_RESYNC',
  props<{provider: IPOSProviderOption}>()
);

export const initResync = createAction(
  '[ CompanyProfile/Pos ][ CONFIRM_RESYNC Effect ] INIT_RESYNC',
  props<{provider: IPOSProviderOption}>()
);

export const connectWithPOSClicked = createAction(
  '[ CompanyProfile/POS ][ PosComponent ] CONNECT_WITH_POS_CLICKED',
);

export const initSync = createAction(
  '[ CompanyProfile/Pos ][ PosSelectComponent ] INIT_SYNC',
  props<{provider: IPOSProviderOption}>()
);

export const openCredentialsDialog = createAction(
  '[ CompanyProfile/Pos ][ INIT_RESYNC | INIT_SYNC Effect ] OPEN_CREDENTIALS_DIALOG',
);

export const redirectToPos = createAction(
  '[ CompanyProfile/Pos ][ INIT_RESYNC | INIT_SYNC Effect ] REDIRECT_TO_POS',
);

export const handlePosRedirectCallback = createAction(
  '[ CompanyProfile/Pos ][ Auth/Routing/ROUTE_TO_COMPANY_PROFILE_POS_REDIRECT Effect ] HANDLE_POS_REDIRECT_CALLBACK',
  props<{code: string, providerName: IPOSProviderOption['value'], flowType: 'sync' | 'resync'}>()
);

export const posCredentialsSubmitted = createAction(
  '[ CompanyProfile/Pos ][ PosAuthenticateComponent ] POS_CREDENTIALS_SUBMITTED',
  props<{username: string, password: string, clientCode: string}>()
);

export const verifyPos = createAction(
  '[ CompanyProfile/Pos ][ HANDLE_POS_REDIRECT_CALLBACK | POS_CREDENTIALS_SUBMITTED Effect ] VERIFY_POS',
  props<{credentials?: {username: string, password: string, clientCode: string}, code?: string}>()
);

export const syncPos = createAction(
  '[ CompanyProfile/Pos ][ VERIFY POS Effect ] SYNC_POS',
  props<{credentials?: {username: string, password: string, clientCode: string}, code?: string}>()
);

export const posFailure = createAction(
  '[ CompanyProfile/Pos ][ VERIFY_POS | SYNC_POS Effect ] POS_FAILURE',
  props<{errorMsg: string}>()
);

export const posFailureDismissed = createAction(
  '[ CompanyProfile/Pos ][ CompanyProfile/Pos/PosLoadingMountService ] POS_FAILURE_DISMISSED',
);

export const posSuccess = createAction(
  '[ CompanyProfile/Pos ][ POLL_POS_SYNC_STATUS_SUCCESS Effect ] POS_SUCCESS',
);

export const requestLegacyPOSApiKey = createAction(
  '[ CompanyProfile/Pos ][ INIT_SYNC Effect ] REQUEST_LEGACY_POS_API_KEY',
);

export const requestLegacyPOSApiKeySuccess = createAction(
  '[ CompanyProfile/Pos ][ REQUEST_LEGACY_POS_API_KEY Effect ] REQUEST_LEGACY_POS_API_KEY_SUCCESS',
);

export const requestLegacyPOSApiKeyFailure = createAction(
  '[ CompanyProfile/Pos ][ REQUEST_LEGACY_POS_API_KEY Effect ] REQUEST_LEGACY_POS_API_KEY_FAILURE',
  props<{errorMsg: string}>()
);

export const savePosProviderNameToAttributes = createAction(
  '[ CompanyProfile/Pos ][ POS_SUCCESS | REQUEST_LEGACY_POS_API_KEY_SUCCESS Effect ] SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES',
);

export const savePosProviderNameToAttributesSuccess = createAction(
  '[ CompanyProfile/Pos ][ SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES Effect ] SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES_SUCCESS',
);

export const savePosProviderNameToAttributesFailure = createAction(
  '[ CompanyProfile/Pos ][ SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES Effect ] SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES_FAILURE',
  props<{errorMsg: string}>()
);

export const notifyResyncComplete = createAction(
  '[ CompanyProfile/Pos ][ POS_SUCCESS Effect ] NOTIFY_RESYNC_COMPLETE',
);

export const notifySyncComplete = createAction(
  '[ CompanyProfile/Pos ][ SAVE_POS_PROVIDER_NAME_TO_ATTRIBUTES_SUCCESS Effect ] NOTIFY_SYNC_COMPLETE',
);

export const resumePosSync = createAction(
  '[ CompanyProfile/Pos ][ Auth/Routing/ROUTE_TO_COMPANY_PROFILE_INTERRUPTED_POS_SYNC Effect ] RESUME_POS_SYNC',
  props<{providerName: IPOSProviderName}>()
);

export const subscribeToPOSSyncStatusPolling = createAction(
  '[ CompanyProfile/Pos ][ SYNC_POS | RESUME_POS_SYNC Effect ] SUBSCRIBE_TO_POS_SYNC_STATUS_POLLING',
);

export const unsubscribeFromPOSSyncStatusPolling = createAction(
  '[ CompanyProfile/Pos ][ POLL_POS_SYNC_STATUS_SUCCESS Effect ] UNSUBSCRIBE_FROM_POS_SYNC_STATUS_POLLING',
);

export const pollPOSSyncStatus = createAction(
  '[ CompanyProfile/Pos ][ SUBSCRIBE_TO_UPLOAD_STATUS_REFRESH -> PosEffects.pollPOSSyncStatus$$ ] POLL_POS_SYNC_STATUS',
);

export const pollPOSSyncStatusSuccess = createAction(
  '[ CompanyProfile/Pos ][ POLL_POS_SYNC_STATUS Effect ] POLL_POS_SYNC_STATUS_SUCCESS',
  props<{attrs: IMerchantAttributes}>()
);

export const pollPOSSyncStatusFailure = createAction(
  '[ CompanyProfile/Pos ][ POLL_POS_SYNC_STATUS Effect ] POLL_POS_SYNC_STATUS_FAILURE',
  props<{errorMsg: string}>()
);

export const reset = createAction(
  '[ CompanyProfile/Pos ][ NOTIFY_SYNC_COMPLETE | NOTIFY_RESYNC_COMPLETE Effect ] RESET',
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

const all = union({
  resyncClicked,
  confirmResync,
  initResync,
  connectWithPOSClicked,
  initSync,
  openCredentialsDialog,
  redirectToPos,
  handlePosRedirectCallback,
  posCredentialsSubmitted,
  verifyPos,
  syncPos,
  posFailure,
  posFailureDismissed,
  posSuccess,
  requestLegacyPOSApiKey,
  requestLegacyPOSApiKeySuccess,
  requestLegacyPOSApiKeyFailure,
  savePosProviderNameToAttributes,
  savePosProviderNameToAttributesSuccess,
  savePosProviderNameToAttributesFailure,
  notifyResyncComplete,
  notifySyncComplete,
  resumePosSync,
  subscribeToPOSSyncStatusPolling,
  unsubscribeFromPOSSyncStatusPolling,
  pollPOSSyncStatus,
  pollPOSSyncStatusSuccess,
  pollPOSSyncStatusFailure,
  reset,
  receiveReset
});
export type Union = typeof all;
