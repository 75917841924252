import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as fromAuth from '@auth/store/auth.reducer';

export const selectState = createFeatureSelector<fromApp.AppState, fromAuth.State>('auth');

export const selectIsAuthLoaded = createSelector(
  selectState,
  state => !!state
);
