import { Component, OnInit } from '@angular/core';
import { SigninFacade } from '@app/auth/signin/store/signin.facade';
import * as SigninActions from '@signin/store/signin.actions';

@Component({
  template: '',
})
export class SignOutComponent implements OnInit {
  constructor(
    private rxSignin: SigninFacade,
  ) { }

  ngOnInit() {
    this.rxSignin.dispatch(SigninActions.signOutOkta());
  }
}