<div class="brand__transition-wrapper position-relative">
  <ng-container *ngTemplateOutlet="icon"></ng-container>
</div>

<ng-template #icon>
  <ng-container [ngSwitch]="mode">
    <!-- To be shown on light background -->
    <mat-icon *ngSwitchCase="'light'" class="brand__vector vector--light position-absolute" [svgIcon]="'brand-dark'"></mat-icon>
    <!-- To be shown on dark background -->
    <mat-icon *ngSwitchCase="'dark'" class="brand__vector vector--dark position-absolut" [svgIcon]="'brand-light'"></mat-icon>
  </ng-container>
</ng-template>
