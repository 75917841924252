export const unserializablePropsStoreSanitizer = (state, index) => {
  if (state.router) {
    state = {
      ...state,
      router: '[SANITISED ROUTER STATE]'
    };
  }

  return state;
};
