import { IOktaIDToken, ISlypToken } from '@interfaces/auth.interface';
import { createReducer, on } from '@ngrx/store';
import * as SigninActions from '@signin/store/signin.actions';
import { dummyOktaIDToken, dummySlypToken } from '@app/core/configs/auth';


export interface State {
  oktaIdToken: IOktaIDToken;
  slypToken: ISlypToken;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

const initialState: State = {
  oktaIdToken: null,
  slypToken: null,
  isAuthenticated: false,
  isAuthenticating: false,
};


export const reducer = createReducer<State, SigninActions.Union>(
  initialState,
  on(SigninActions.setDummySession, state => ({
    ...state,
    isAuthenticated: true,
    isAuthenticating: false,
    oktaIdToken: dummyOktaIDToken,
    slypToken: dummySlypToken
  })),
  on(SigninActions.retrieveSavedOktaSession, state => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: true
  })),
  on(SigninActions.signInWithOkta, state => ({
    ...state,
    isAuthenticating: true
  })),
  on(SigninActions.setOktaIDToken, (state, {oktaIdToken}) => ({
    ...state,
    oktaIdToken
  })),
  on(SigninActions.setSlypToken, (state, {slypToken}) => ({
    ...state,
    isAuthenticated: true,
    slypToken
  })),
  on(SigninActions.authenticationProcessCompleted, state => ({
    ...state,
    isAuthenticating: false
  })),
  on(SigninActions.receiveReset, state => ({
    ...initialState,
  })),
);
