import { Injectable } from "@angular/core";
import { USER_ROLE_PERMISSIONS } from "@configs/role";
import { UserRoleType } from "@interfaces/role.interface";

@Injectable()
export class RoleService {
  constructor() {}

  isRouteAllowedForRole(role: UserRoleType, routePath: string[]) {
    const access = USER_ROLE_PERMISSIONS[role];
    if (!access) {
      return false;
    }
    // When not defined, allow all routes
    if (!access.allow) {
      return true;
    }
    const allowed = access.allow.map(
      (paths) => new Set(paths.url.split("/").filter((path) => !!path))
    );
    return allowed.some((paths) => routePath.every((path) => paths.has(path)));
  }

  isUrlPathAllowedForRole(role: UserRoleType, urlPath: string) {
    const access = USER_ROLE_PERMISSIONS[role];
    if (!access) {
      return false;
    }

    // When allow field is not defined, allow all URL paths
    if (!access.allow) {
      return true;
    }
    return access.allow.some((paths) => paths.url === urlPath);
  }
}
