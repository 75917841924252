import { ISvgIconName } from '@interfaces/icon.interface';

export const ICONS_PATH = '/assets/icons/';

export const SVG_ICONS_NAMES: ISvgIconName[] = [
  'whats-new',
  'business',
  'menu',
  'receipt',
  'tree',
  'receipt-attempted',
  'receipt-delivered',
  'receipt-opened',
  'pos-failed',
  'pos-loading',
  'dashboard',
  'plot',
  'stats',
  'returns',
  'business-center',
  'content-copy',
  'account-box',
  'instagram',
  'facebook',
  'twitter',
  'brand-dark',
  'brand-light',
  'exit',
  'company-details',
  'connect',
  'link',
  'design-receipt',
  'mastercard',
  'visa',
  'amex',
  'hero-coupons',
  'hero-campaign-rates',
  'loyalty',
  'store',
  'enabled',
  'disabled',
  'ext-link',
  'redirect',

  // MySlyp 2.0 navigation
  'grid',
  'file-text',
  'tag',
  'briefcase',
  'align-justify',
  'users',
  'bar-chart-2'
];
