import { Injectable } from '@angular/core';
import * as fromAuth from '@auth/store/auth.reducer';
import { Store } from '@ngrx/store';
import * as SigninActions from '@signin/store/signin.actions';
import * as SigninSelectors from '@signin/store/signin.selectors';


@Injectable()
export class SigninFacade {

  state$ = this.rxStore.select(SigninSelectors.selectState);
  isAuthenticated$ = this.rxStore.select(SigninSelectors.selectIsAuthenticated);
  isAuthenticating$ = this.rxStore.select(SigninSelectors.selectIsAuthenticating);
  fullName$ = this.rxStore.select(SigninSelectors.selectUserFullName);
  userFirstName$ = this.rxStore.select(SigninSelectors.selectGivenName);
  userInitials$ = this.rxStore.select(SigninSelectors.selectUserInitials);
  userRole$ = this.rxStore.select(SigninSelectors.selectUserRole);
  humanReadableUserRole$ = this.rxStore.select(SigninSelectors.selectHumanReadableUserRole);
  merchantId$ = this.rxStore.select(SigninSelectors.selectMerchantId);
  slypToken$ = this.rxStore.select(SigninSelectors.selectSlypToken);
  email$ = this.rxStore.select(SigninSelectors.selectEmail);

  constructor(private rxStore: Store<fromAuth.AppState>) {}

  dispatch(action: SigninActions.Union) {
    this.rxStore.dispatch(action);
  }

}
