import * as SigninSelectors from '@signin/store/signin.selectors';
import { SIGNUP_STEP_META } from '@configs/signup';
import { createSelector } from '@ngrx/store';
import * as AuthSelectors from '@auth/store/auth.selectors';

export const selectState = createSelector(
  AuthSelectors.selectState,
  state => state.signup
);

export const selectIsRequestingState = createSelector(
  selectState,
  SigninSelectors.selectIsAuthenticating,
  ({isRequesting}, isAuthenticating) => isRequesting || isAuthenticating
);

export const selectIsSignupInProgress = createSelector(
  selectState,
  state => state.isInProgress
);

export const selectIsPasswordRecoveryInProgress = createSelector(
  selectState,
  state => state.isPasswordRecoveryInProgress
);

export const selectStep = createSelector(
  selectState,
  state => state.step
);

export const selectStepsCount = createSelector(
  selectStep,
  step => SIGNUP_STEP_META[step].count
);

export const selectOktaVerificationSecret = createSelector(
  selectState,
  state => state.oktaVerificationSecret
);

export const selectOktaRecoverySecret = createSelector(
  selectState,
  state => state.oktaRecoverySecret
);

export const selectAccountEmail = createSelector(
  selectState,
  state => state.email
);
