export const SDS_COLORS = {
  brand: {
    primary_50B: '#005C59',
    primary_25B: '#008B86',
    primary: '#00BAB3',
    primary_25W: '#40CBC6',
    primary_50W: '#80DCD9',
    secondaryA_50B: '#0D2C3E',
    secondaryA_25B: '#14435D',
    secondaryA: '#1B5A7D',
    secondaryA_25W: '#54839D',
    secondaryA_50W: '#8DACBE',
    secondaryB_50B: '#004456',
    secondaryB_25B: '#006781',
    secondaryB: '#008AAD',
    secondaryB_25W: '#40A7C1',
    secondaryB_50W: '#80C4D6',
  },
  base: {
    greyDark: '#1C1D26',
    greyMedium_50B: '#2F353C',
    greyMedium_25B: '#47505A',
    greyMedium: '#606B79',
    greyMedium_25W: '#87909A',
    greyMedium_50W: '#AFB5BC',
    greyLight_50B: '#DEE0E2',
    greyLight_25B: '#EAECEF',
    greyLight: '#F4F6F9',
    greyLight_25W: '#F9FBFF',
    white: '#FFFFFF',
  },
  system: {
    success_50B: '#456124',
    success_25B: '#689237',
    success: '#8BC34A',
    success_25W: '#A8D277',
    success_50W: '#C5E1A4',
    warning_50B: '#7A5D2D',
    warning_25B: '#B88C44',
    warning: '#F6BB5B',
    warning_25W: '#F8CC84',
    warning_50W: '#FADDAD',
    danger_50B: '#580121',
    danger_25B: '#850332',
    danger: '#B20444',
    danger_25W: '#C54272',
    danger_50W: '#D881A1',
  }
};
