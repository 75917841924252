<div class="badge-circle big primary p-4 mb-5">
  <mat-icon>person</mat-icon>
</div>
<div class="row no-gutters mb-4">
  <div class="col">
    <h2 class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin">Your account has been successfully created</h2>
    <p class="sds-color-base-grey_medium mb-0">Time to setup smart receipts! This should only take 5 minutes. Here's what is involved:</p>
  </div>
</div>
<div class="row mb-2 no-gutters">
  <div class="col col-6 d-flex align-items-center mb-3" *ngFor="let item of items; trackBy: trackBy">
    <div class="badge-circle mr-3 sds-border-color-base-grey_medium-opacity-50">
      <mat-icon class="sds-color-base-grey_medium-opacity-50" [svgIcon]="item.icon" [ngClass]="item.icon"></mat-icon>
    </div>
    <span class="sds-color-base-grey_dark">{{item.label}}</span>
  </div>
</div>
<div class="row no-gutters">
  <div class="col">
    <button class="my-3 sds-btn primary emphasized" (click)="onCompleteSignup()"  data-test="setupSmartReceipts">SETUP SMART RECEIPTS</button>
  </div>
</div>
