import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { REM_SIZE } from '@app/core/configs/ui';

export interface IConfirmDialogContext {
  question: string;
  elaboration?: string; // omit to hide
  yesAnswer: string;
  noAnswer?: string; // omit to hide no option
  onYes?: () => any; // omit to do nothing beside closing the dialog,
  onNo?: () => any; // omit to do nothing beside closing the dialog,
  color?: 'primary' | 'secondary' | 'danger';
  isCentered?: boolean;
}

export const CONFIRM_DIALOG_CONTEXT_DEFAULT: IConfirmDialogContext = {
  question: 'Are you sure?',
  yesAnswer: 'Yes',
  color: 'secondary',
  isCentered: true,
};

@Component({
  selector: 'app-confirm-dialog',
  host: {
    '[class.confirm-dialog]': 'true',
    '[class.card-dialog__component--medium]': 'true',
  },
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements AfterViewInit {

  @ViewChild('yesButton') yesButton: ElementRef;
  @ViewChild('noButton') noButton: ElementRef;
  @ViewChild('content') content: ElementRef;

  @Output() yes = new EventEmitter<void>();
  @Output() no = new EventEmitter<void>();

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: IConfirmDialogContext
  ) {}

  ngAfterViewInit() {
    this.handleBigButtons();
  }

  private handleBigButtons() {
    const currentWidth = this.content.nativeElement.offsetWidth;
    const actionsWidth = this.getActionsWidth();

    if (currentWidth < actionsWidth) {
      this.dialogRef.updateSize(actionsWidth + 'px');
    }
  }

  private getActionsWidth() {
    const yesWidth = this.yesButton.nativeElement.offsetWidth;
    const noWidth = this.noButton ? this.noButton.nativeElement.offsetWidth + REM_SIZE : 0;
    return yesWidth + noWidth + 3 * REM_SIZE;
  }

  onNo() {
    this.no.emit();
    if (this.context.onNo) {
      this.context.onNo();
    }
  }

  onYes() {
    this.yes.emit();
    if (this.context.onYes) {
      this.context.onYes();
    }
  }
}
