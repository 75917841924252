import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    captureException(error.originalError || error);
    throw error;
  }
}
