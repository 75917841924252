import { InjectionToken } from '@angular/core';
import * as fromApp from '@app/store/app.reducer';
import * as AuthActions from '@auth/store/auth.actions';
import { ActionReducerMap, combineReducers, compose } from '@ngrx/store';
import * as fromRouting from '@routing/store/routing.reducer';
import * as fromSignIn from '@signin/store/signin.reducer';
import * as fromSignUp from '@signup/store/signup.reducer';
import * as fromForgotPassword from '@auth/forgot-password/store/forgot-password.reducer';
import * as fromResendActivationEmail from '@auth/resend-activation-email/store/resend-activation-email.reducer';

export interface State {
  signin: fromSignIn.State;
  signup: fromSignUp.State;
  routing: fromRouting.State;
  forgotPassword: fromForgotPassword.State;
  resendActivationEmail: fromResendActivationEmail.State;
}

export interface AppState extends fromApp.AppState {
  auth: State;
}

export const reducers: ActionReducerMap<State, AuthActions.Union> = {
  signin: fromSignIn.reducer,
  signup: fromSignUp.reducer,
  routing: fromRouting.reducer,
  forgotPassword: fromForgotPassword.reducer,
  resendActivationEmail: fromResendActivationEmail.reducer,
};

export const reducer = compose(combineReducers)(reducers);

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('App Reducers');

export function getReducers() {
  return reducers;
}

export const reducerProvider = [
  {provide: reducerToken, useFactory: getReducers}
];
