import { Directive, HostBinding, HostListener, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[appImageSmooth]'
})
export class ImageSmoothDirective implements OnInit {

  @HostBinding('class.image-smooth--loading') isLoading = true;
  @HostBinding('class.image-smooth') isSmooth = true;

  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngOnInit() {
    if (this.el.nativeElement.complete) {
      this.isLoading = false;
    }
  }

  @HostListener('load') onLoad() {
    this.isLoading = false;
  }

  @HostListener('error') onError() {
    this.isLoading = false;
  }

}
