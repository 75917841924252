import { UserRoleType } from '@app/core/interfaces/role.interface';
import { createSelector } from '@ngrx/store';
import * as AuthSelectors from '@auth/store/auth.selectors';

export const selectState = createSelector(
  AuthSelectors.selectState,
  state => state.signin
);

export const selectIsAuthenticated = createSelector(
  selectState,
  state => state.isAuthenticated
);

export const selectIsAuthenticating = createSelector(
  selectState,
  state => state.isAuthenticating
);

export const selectOktaIdToken = createSelector(
  selectState,
  state => state.oktaIdToken
);

export const selectUserLogin = createSelector(
  selectOktaIdToken,
  token => token ? token.claims.email : null
);

export const selectGivenName = createSelector(
  selectOktaIdToken,
  token => token ? token.claims.given_name : null
);

export const selectFamilyName = createSelector(
  selectOktaIdToken,
  token => token ? token.claims.family_name : null
);

export const selectUserFullName = createSelector(
  selectGivenName,
  selectFamilyName,
  (first, last) => [first, last]
    .filter(chunk => !!chunk)
    .join(' ')
);

export const selectUserInitials = createSelector(
  selectGivenName,
  selectFamilyName,
  (first, last) => [first, last]
    .filter(chunk => !!chunk)
    .map(chunk => chunk[0].toUpperCase())
    .join('')
);

export const selectEmail = createSelector(
  selectOktaIdToken,
  token => token ? token.claims.email : null
);

export const selectSlypToken = createSelector(
  selectState,
  state => state.slypToken
);

export const selectUserRole = createSelector(
  selectSlypToken,
  token => token ? token.userRole : null
);

export const selectHumanReadableUserRole = createSelector(
  selectUserRole,
  (role) => {
    switch (role) {
      case UserRoleType.storeOps:
        return ["Operator", "Store Operator"] as const;
      case UserRoleType.merchantDefault:
      default:
        return ["Admin", "Administrator"] as const;
    }
  }
);

export const selectMerchantId = createSelector(
  selectSlypToken,
  token => token ? token.merchantId : null
);

export const selectUserId = createSelector(
  selectSlypToken,
  token => token ? token.userId : null
);
