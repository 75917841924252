import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressComponent {
  @Input() currentStepIndex = 0;
  @Input() steps: number[] = [];

  trackBy = (_, index) => index;
}
