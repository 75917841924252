import { environment } from '@environments/environment';
import * as ForgotPasswordActions from '@auth/forgot-password/store/forgot-password.actions';
import { ForgotPasswordFacade } from '@auth/forgot-password/store/forgot-password.facade';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStreams } from '@interfaces/misc.interface';
import { FormsUtils } from '@utils/forms';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  host: {'[class.card-dialog__component--narrow]': 'true'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  isRequesting$ = this.rxForgotPassword.isRequesting$;
  view$ = this.rxForgotPassword.view$;

  supportMail = environment.slypSupportMail;

  form: FormGroup;
  errors: ErrorStreams = {};

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private rxForgotPassword: ForgotPasswordFacade
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      username: [null, [Validators.required]],
    });

    this.errors = FormsUtils.getErrorStreams(this.destroy$, {
      username$: this.form.get('username'),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const username = this.form.value.username;
      this.rxForgotPassword.dispatch(ForgotPasswordActions.usernameSubmitted({username}));
    } else {
      FormsUtils.showAllErrors(this.form);
      this.notifier.show({type: 'warning', message: 'Please correct errors and try again.'});
    }
  }

}
