import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from '@auth/auth-routing.module';

import { NgLetModule } from '@ngrx-utils/store';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule, } from '@angular/material/input';
import { MatIconModule, } from '@angular/material/icon';
import { MatDialogModule, MAT_DIALOG_DATA, } from '@angular/material/dialog';

const materialModules = [
  MatInputModule,
  MatIconModule,
  MatDialogModule,
  MatCheckboxModule
];

import { BrandModule } from '@components/brand/brand.module';
import { MultiViewModule } from '@components/multi-view/multi-view.module';
import { DimmerModule } from '@components/dimmer/dimmer.module';
import { ConfirmModule } from '@components/confirm/confirm.module';
import { DebugPanelModule } from '@components-development/debug-panel/debug-panel.module';

const componentModules = [
  BrandModule,
  MultiViewModule,
  DimmerModule,
  ConfirmModule,
  DebugPanelModule,
];

import { ValidateOnBlurModule } from '@directives/validate-on-blur/validate-on-blur.module';
import { ImageSmoothModule } from '@directives/image-smooth/image-smooth.module';
import { BlockRedundantSubmitModule } from '@directives/block-redundant-submit/block-redundant-submit.module';
import { BlockRedundantClickModule } from '@directives/block-redundant-click/block-redundant-click.module';

const directiveModules = [
  ValidateOnBlurModule,
  ImageSmoothModule,
  BlockRedundantSubmitModule,
  BlockRedundantClickModule
];

import { AuthComponent } from '@auth/auth.component';
import { SigninComponent } from '@signin/signin.component';
import { SignupComponent } from '@signup/signup.component';
import { ProgressComponent } from '@signup/progress/progress.component';
import { StepDetailsComponent } from '@signup/steps/step-details/step-details.component';
import { StepVerificationComponent } from '@signup/steps/step-verification/step-verification.component';
import { StepPasswordComponent } from '@signup/steps/step-password/step-password.component';
import { StepCompleteComponent } from '@signup/steps/step-complete/step-complete.component';
import { StepPasswordRecoveredComponent } from '@auth/signup/steps/step-password-recovered/step-password-recovered.component';
import { PrivacyPolicyComponent } from '@signup/privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from '@auth/forgot-password/forgot-password.component';
import { ResendActivationEmailComponent } from '@auth/resend-activation-email/resend-activation-email.component';

import * as fromAuth from '@auth/store/auth.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RoutingEffects } from '@routing/store/routing.effects';
import { SignupEffects } from '@signup/store/signup.effects';
import { SigninEffects } from '@signin/store/signin.effects';
import { ForgotPasswordEffects } from '@auth/forgot-password/store/forgot-password.effects';
import { ResendActivationEmailEffects } from '@auth/resend-activation-email/store/resend-activation-email.effects';

const ngrxEffects = [
  SigninEffects,
  SignupEffects,
  RoutingEffects,
  ForgotPasswordEffects,
  ResendActivationEmailEffects
];

import { SessionService } from '@services/session.service';
import { SignupService } from '@app/core/services/signup.service';

// --- services
import { MerchantArtifactsAdapterService } from '@adapters/merchant-artifacts-adapter.service';
import { ContentfulAdapterService } from '@adapters/contentful-adapter.service';

const adapterServices = [
  MerchantArtifactsAdapterService,
  ContentfulAdapterService,
];

import { AuthFacade } from '@auth/store/auth.facade';
import { SigninFacade } from '@signin/store/signin.facade';
import { SignupFacade } from '@signup/store/signup.facade';
import { RoutingFacade } from '@routing/store/routing.facade';
import { ForgotPasswordFacade } from '@auth/forgot-password/store/forgot-password.facade';
import { EmailsService } from '@services/emails.service';
import { ResendActivationEmailFacade } from '@auth/resend-activation-email/store/resend-activation-email.facade';

const ngrxFacades = [
  AuthFacade,
  SigninFacade,
  SignupFacade,
  RoutingFacade,
  ForgotPasswordFacade,
  ResendActivationEmailFacade
];

import { ResendActivationEmailMountService } from '@auth/resend-activation-email/resend-activation-email-mount.service';
import { RoleService } from '@app/core/services/role.service';

const dialogMountServices = [
  ResendActivationEmailMountService
];



@NgModule({
  declarations: [
    AuthComponent,
    SigninComponent,
    SignupComponent,
    PrivacyPolicyComponent,
    ProgressComponent,
    StepDetailsComponent,
    StepVerificationComponent,
    StepPasswordComponent,
    StepCompleteComponent,
    ForgotPasswordComponent,
    StepPasswordRecoveredComponent,
    ResendActivationEmailComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    materialModules,
    componentModules,
    directiveModules,
    NgLetModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', fromAuth.reducerToken),
    EffectsModule.forFeature(ngrxEffects),
  ],
  providers: [
    ngrxFacades,
    dialogMountServices,
    adapterServices,
    SessionService,
    SignupService,
    EmailsService,
    RoleService,
    fromAuth.reducerProvider,
    {provide: MAT_DIALOG_DATA, useValue: {}},
  ],
})
export class AuthModule {}
