export const AUTH_ROUTES = {
  self: {url: '/auth', label: 'Auth'},
  signup: {url: '/auth/signup', label: 'Signup'},
  signin: {url: '/auth/signin', label: 'Signin'},
};

export const ONBOARDING_ROUTE = {url: '/onboarding', label: 'Onboarding'};

export const SMART_RECEIPT_ROUTES = {
  self: {url: '/home/smart-receipt', label: 'Slyp Receipt'},
  design: {url: '/home/smart-receipt/design', label: 'Design'},
  socialMedia: {url: '/home/smart-receipt/social-media', label: 'Social Media'},
  returnPolicy: {url: '/home/smart-receipt/return-policy', label: 'Return Policy'},
  productCatalog: {url: '/home/smart-receipt/product-catalog', label: 'Product Catalogue'},
};

export const COMPANY_PROFILE_ROUTES = {
  self: {url: '/home/company-profile', label: 'Company'},
  companyInfo: {url: '/home/company-profile/company-info', label: 'Company Info'},
  stores: {url: '/home/company-profile/stores', label: 'Stores'},
  apiKeys: {url: '/home/company-profile/api-keys', label: 'API Keys'},
  profile: {url: '/home/company-profile/profile', label: 'Profile'},
  webhooks: {url: '/home/company-profile/webhooks', label: 'Webhooks'},
  pos: {url: '/home/company-profile/pos', label: 'POS'},
};

export const MY_HUB_ROUTE = {url: '/home/my-hub', label: 'My Hub'};
export const USER_PROFILE_ROUTE = {url: '/home/user-profile', label: 'User Profile'};

export const RECEIPTS_ROUTES = {
  self: {url: '/home/receipts', label: 'Receipts'},
  feed: {url: '/home/receipts/feed', label: 'Receipt Feed'},
};

export const PROMOTIONS_ROUTES = {
  self: { url: '/home/promotions', label: 'Promotions' },
}

export const MYSLYP_V2_ROUTES = {
  userManagement: { url: '/home/user-management', label: 'User Management' },
  reports: { url: '/home/reports', label: 'Reports' },
  onboarding: {
    url: 'myslyp-v2/onboarding', // In this case url cannot start with "/"
    label: 'Onboarding'
  }
}
