import { Injectable } from '@angular/core';
import * as fromAuth from '@auth/store/auth.reducer';
import { Store } from '@ngrx/store';
import * as RoutingActions from '@routing/store/routing.actions';
import * as RoutingSelectors from '@routing/store/routing.selectors';


@Injectable()
export class RoutingFacade {

  isDecidingRoute$ = this.rxStore.select(RoutingSelectors.selectIsDecidingRouteState);
  reachedDecidedRoute$ = this.rxStore.select(RoutingSelectors.selectReachedDecidedRoute);
  isOnboardingInProgress$ = this.rxStore.select(RoutingSelectors.selectIsOnboardingInProgress);

  isProfileRouteActive$ = this.rxStore.select(RoutingSelectors.selectIsProfileRouteActive);

  sectionTitle$ = this.rxStore.select(RoutingSelectors.selectSectionTitle);

  constructor(private rxStore: Store<fromAuth.AppState>) {}

  dispatch(action: RoutingActions.Union) {
    this.rxStore.dispatch(action);
  }
}
