import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MultiViewComponent } from '@components/multi-view/multi-view.component';
import { EntriesPipeModule } from '@pipes/entries/entries-pipe.module';
import { MultiViewItemDirective } from './multi-view-item.directive';

@NgModule({
  declarations: [
    MultiViewComponent,
    MultiViewItemDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    MultiViewComponent,
    MultiViewItemDirective
  ],
})
export class MultiViewModule {}
