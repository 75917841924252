import { IConfirmDialogContext } from '@components/confirm/confirm-dialog/confirm-dialog.component';
import { IIconCycleMode, ICollapseMode } from '@interfaces/ui.interface';

import { SDS_COLORS } from './colors';
export const STATITICS_SERIES_COLORS = [
  SDS_COLORS.brand.secondaryA,
  SDS_COLORS.brand.primary,
  SDS_COLORS.base.greyMedium,
];

export const BREAKPOINT_TABLET = 992;
export const BREAKPOINT_MOBILE = 768;

export const DIALOG_COMPONENT_WIDTH_WIDE = 768;
export const DIALOG_COMPONENT_WIDTH_MEDIUM = 640;
export const DIALOG_COMPONENT_WIDTH_NARROM = 500;

export const REM_SIZE = 14;

export const COLLAPSING_MODES: IIconCycleMode<ICollapseMode>[] = [
  {mode: 'collapsed', icon: 'expand_more'},
  {mode: 'expanded', icon: 'expand_less'},
];


export const TOAST_TIMEOUT_UAT = 15000;
export const TOAST_TIMEOUT = 4000;
export const TOAST_TIMEOUT_SHORT = 2000;

export const UNSAVED_CHANGES_PROMPT_CONFIG: IConfirmDialogContext = {
  question: 'Abort?',
  elaboration: 'You seem to have unsaved changes that will be lost if you quit.',
  yesAnswer: 'Yes, quit',
  noAnswer: 'No, let me finish',
};
