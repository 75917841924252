import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';
import * as ForgotPasswordActions from '@auth/forgot-password/store/forgot-password.actions';
import { ForgotPasswordFacade } from '@auth/forgot-password/store/forgot-password.facade';
import { AUTH_ROUTES } from '@configs/routing';
import { ErrorStreams } from '@interfaces/misc.interface';
import * as SigninActions from '@signin/store/signin.actions';
import { SigninFacade } from '@signin/store/signin.facade';
import { FormsUtils } from '@utils/forms';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  animations: [
    AppearAnimationsFactory.fade('formState')
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit, OnDestroy {

  isPasswordHidden = true;
  isAuthenticating$ = this.rxSignin.isAuthenticating$;
  signupRoute = AUTH_ROUTES.signup.url;

  form: FormGroup;
  errors: ErrorStreams = {};
  redirectToV2Signup = `${environment.mySlypV2RedirectUrl}/onboarding/create-account`
  imgPath = '/assets/images/auth/sign-in/';

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private rxSignin: SigninFacade,
    private rxForgotPassword: ForgotPasswordFacade
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupForm() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

    this.errors = FormsUtils.getErrorStreams(this.destroy$, {
      email$: this.form.get('email'),
      password$: this.form.get('password'),
    });
  }

  onTogglePasswordVisibility() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  onForgotPassword() {
    this.rxForgotPassword.dispatch(ForgotPasswordActions.forgotPasswordClicked());
  }


  onSignIn() {
    if (this.form.valid) {
      this.rxSignin.dispatch(SigninActions.signIn(this.form.value));
    } else {
      FormsUtils.showAllErrors(this.form);
      this.notifier.show({ type: 'warning', message: 'Please correct errors and try again.' });
    }
  }

}
