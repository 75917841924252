<div class="row row-brand no-gutters">
  <div class="col">
    <app-brand></app-brand>
  </div>
</div>
<div class="row no-gutters mb-4">
  <div class="col">
    <h2 class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin">Create account</h2>
    <p class="sds-color-base-grey_medium mb-0">Make the end of a transaction, the beginning of a relationship. Create an account and start sending smart receipts today.</p>
  </div>
</div>
<form [formGroup]="form" [appBlockRedundantSubmit]="isRequesting$ | async" (validNgSubmit)="onSubmit()"  data-test="createAccountForm">
  <div class="row no-gutters">
    <div class="col">
      <p class="sds-color-base-grey_medium-25W sds-text-caption-2 mb-2">
        ACCOUNT CONTACT
      </p>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col">
      <div class="row no-gutters">
        <mat-form-field appearance="fill" class="col-6 sds-form-field primary pr-2">
          <mat-label>First name</mat-label>
          <input matInput type="text" formControlName="first" data-test="firstname" required appValidateOnBlur>
          <mat-error *ngIf="errors['first$'] | async as error" data-test="error">{{ error }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="col-6 sds-form-field primary pl-2">
          <mat-label>Last name</mat-label>
          <input matInput type="text" formControlName="last" data-test="lastname" required appValidateOnBlur>
          <mat-error *ngIf="errors['last$'] | async as error" data-test="error">{{ error }}</mat-error>
        </mat-form-field>
      </div>
      <div class="row no-gutters">
        <mat-form-field appearance="fill" class="col-12 sds-form-field primary">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" data-test="email" required appValidateOnBlur>
          <mat-error *ngIf="errors['email$'] | async as error" data-test="error">{{ error }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col">
      <mat-checkbox formControlName="consent" [disableRipple]="true" data-test="consent" class="ml-1 sds-checkbox primary sds-color-base-grey_medium-25W">
        By creating an account, you agree to our
        <a class="sds-btn-any" [href]="termsAndConditionsLink" target="_blank">Terms of Service</a>
        and
        <a class="sds-btn-any" [href]="privacyPolicyLink" target="_blank">Privacy Policy</a>
      </mat-checkbox>

      <div *ngIf="errors['consent$'] | async" [@fadeState] class="badge-toast danger align-self-start" data-test="error">
        <mat-icon>clear</mat-icon>
        <span class="title">You must accept Slyp's Terms of Service before creating an account.</span>
      </div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col">
      <button type="submit" class="my-3 sds-btn primary emphasized" data-test="createAccount">
        <app-dimmer class="sds-bg-brand-primary sds-color-base-white"
          [isDimmed]="isRequesting$ | async"
          [spinnerDiameter]="20"
          [strokeWidth]="1.5"
        ></app-dimmer>
        CREATE AN ACCOUNT
      </button>
    </div>
  </div>
</form>
