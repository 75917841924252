import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { IOktaIDToken, ISlypToken, IOktaSession } from '@interfaces/auth.interface';
import { createAction, props, union } from '@ngrx/store';

export const setDummySession = createAction(
  '[ Auth/Signin ][ Auth/Routing/DECIDE_UNAUTHENTICATED_USER_ROUTE Effect ] SET_DUMMY_SESSION'
);

export const retrieveSavedOktaSession = createAction(
  '[ Auth/Signin ][ Auth/Routing/DECIDE_UNAUTHENTICATED_USER_ROUTE Effect ] RETRIEVE_SAVED_OKTA_SESSION'
);

export const renewOktaIdToken = createAction(
  '[ Auth/Signin ][ REAUTHORIZE_OKTA Effect ] RENEW_OKTA_ID_TOKEN',
  props<{ cookieIdToken: IOktaIDToken }>()
);

export const signIn = createAction(
  '[ Auth/Signin ][ SignInComponent ] SIGN_IN',
  props<{ email: string, password: string }>()
);

export const signInWithOkta = createAction(
  '[ Auth/Signin ][ SIGN_IN | Auth/Signup/ACTIVATE_ACCOUNT_SUCCESS | Auth/Signup/RECOVER_PASSWORD_SUCCESS Effect ] SIGN_IN_WITH_OKTA',
  props<{ email: string, password: string }>()
);

export const fetchOktaIDTokenForSession = createAction(
  '[ Auth/Signin ][ SIGN_IN_WITH_OKTA Effect ] FETCH_OKTA_ID_TOKEN_FOR_SESSION',
  props<{ session: IOktaSession }>()
);

export const setOktaIDToken = createAction(
  '[ Auth/Signin ][ RENEW_OKTA_ID_TOKEN | FETCH_OKTA_ID_TOKEN_FOR_SESSION Effect ] SET_OKTA_ID_TOKEN',
  props<{ oktaIdToken: IOktaIDToken }>()
);

export const authorizeWithSlypApi = createAction(
  '[ Auth/Signin ][ SET_SESSION_OKTA Effect ] AUTHORIZE_WITH_SLYP_API'
);

export const authorizeWithSlypApiUsingTestCredentials = createAction(
  '[ Auth/Signin ][ SET_SESSION_OKTA Effect ] AUTHORIZE_WITH_SLYP_API_USING_TEST_CREDENTIALS'
);

export const setSlypToken = createAction(
  '[ Auth/Signin ][ AUTHORIZE_WITH_SLYP_API | AUTHORIZE_WITH_SLYP_API_USING_TEST_CREDENTIALS Effect ] SET_SLYP_TOKEN',
  props<{ slypToken: ISlypToken }>()
);

export const authenticationProcessCompleted = createAction(
  '[ Auth/Signin ][ Auth/Routing/DECIDE_AUTHENTICATED_USER_ROUTE ] AUTHENTICATION_PROCESS_COMPLETED'
);

export const handleAuthFailure = createAction(
  '[ Auth/Signin ][ Effect ] HANDLE_AUTH_FAILURE',
  props<{ msg: string }>()
);

export const signOutOnboarding = createAction(
  '[ Auth/Signin ][ Onboarding ] SIGN_OUT'
);

export const signOutGeneral = createAction(
  '[ Auth/Signin ][ General ] SIGN_OUT'
);

export const signOutSidebar = createAction(
  '[ Auth/Signin ][ Home/ProfileComponent | CompanyInfoComponent ] SIGN_OUT'
);

export const signOutUserProfile = createAction(
  '[ Auth/Signin ][ Home/UserProfile/SAVE_PROFILE_DATA_SUCCESS Effect ] SIGN_OUT_USER_PROFILE'
);

export const signOutOkta = createAction(
  '[ Auth/Signin ][ SIGN_OUT | RETRIEVE_SAVED_OKTA_SESSION | SIGN_OUT_USER_PROFILE_SAVE Effect ] SIGN_OUT_OKTA'
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

export const clearOktaSession = createAction(
  '[ Auth/Signin ][ RETRIEVE_SAVED_OKTA_SESSION | HANDLE_AUTH_FAILURE Effect ] CLEAR_OKTA_SESSION'
);
export const closeOktaSession = createAction(
  '[ Auth/Signin ][ CLEAR_SAVED_OKTA_SESSION Effect ] CLOSE_OKTA_SESSION'
);


const all = union({
  setDummySession,
  retrieveSavedOktaSession,
  renewOktaIdToken,
  signIn,
  signInWithOkta,
  fetchOktaIDTokenForSession,
  setOktaIDToken,
  authorizeWithSlypApi,
  authorizeWithSlypApiUsingTestCredentials,
  setSlypToken,
  authenticationProcessCompleted,
  handleAuthFailure,
  signOutOnboarding,
  signOutUserProfile,
  signOutSidebar,
  signOutOkta,
  signOutGeneral,
  receiveReset,
  clearOktaSession,
  closeOktaSession
});
export type Union = typeof all;
