<div class="badge-circle big primary p-4 mb-5">
  <mat-icon>lock</mat-icon>
</div>
<div class="row no-gutters mb-4">
  <div class="col">
    <h2 class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin">Your password has been successfully changed!</h2>
    <p class="sds-color-base-grey_medium mb-0">You can now use it to sign in as usual.</p>
  </div>
</div>
<div class="row no-gutters">
  <div class="col">
    <button class="my-3 sds-btn primary emphasized" (click)="onComplete()" data-test="continue">CONTINUE</button>
  </div>
</div>
