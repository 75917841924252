import { UserRoleType } from "@interfaces/role.interface";
import { MYSLYP_V2_ROUTES, RECEIPTS_ROUTES } from "@configs/routing";

type RoleRouteAccess = { allow?: { url: string }[] };

const ALLOW_ALL = { allow: null };
const DENY_ALL = { allow: [] };

// Defines user roles and their allowed routes. Modifications here will also change
// the visible items in the home side nav bar for that specified role.
export const USER_ROLE_PERMISSIONS: Record<UserRoleType, RoleRouteAccess> = {
  [UserRoleType.storeOps]: {
    allow: [RECEIPTS_ROUTES.feed, RECEIPTS_ROUTES.self, MYSLYP_V2_ROUTES.reports],
  },
  [UserRoleType.merchantDefault]: ALLOW_ALL,
};
