import * as SignupActions from '@signup/store/signup.actions';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SignupFacade } from '@signup/store/signup.facade';

@Component({
  selector: 'app-step-complete',
  templateUrl: './step-complete.component.html',
  styleUrls: ['./step-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepCompleteComponent {

  items = [
    {icon: 'connect', label: 'Connect your POS'},
    {icon: 'company-details', label: 'Company Details'},
    {icon: 'link', label: 'Information connected'},
    {icon: 'design-receipt', label: 'Design your Receipt'},
  ];

  constructor(private rxSignup: SignupFacade) { }

  onCompleteSignup() {
    this.rxSignup.dispatch(SignupActions.signupProcessFinished());
  }

  trackBy = (_, index) => index;
}
