import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import * as ResendActivationEmailSelectors from '@auth/resend-activation-email/store/resend-activation-email.selectors';
import * as fromAuth from '@auth/store/auth.reducer';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';


@Injectable()
export class ResendActivationEmailFacade {

  isRequesting$ = this.rxStore.select(ResendActivationEmailSelectors.selectIsRequestingState);

  constructor(private rxStore: Store<fromAuth.AppState>) {}

  dispatch(action: ResendActivationEmailActions.Union) {
    this.rxStore.dispatch(action);
  }

}
