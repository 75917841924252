import { createReducer, on } from '@ngrx/store';
import * as SignupActions from '@signup/store/signup.actions';
import { ISignupStep } from '@interfaces/signup.interface';

export interface State {
  step: ISignupStep;
  isInProgress: boolean;
  isPasswordRecoveryInProgress: boolean;
  isRequesting: boolean;
  email: string;
  oktaVerificationSecret: string;
  oktaRecoverySecret: string;
}

const initialState: State = {
  step: 'step-details', // default
  isInProgress: false,
  isPasswordRecoveryInProgress: false,
  isRequesting: false,
  email: null,
  oktaVerificationSecret: null,
  oktaRecoverySecret: null,
};


export const reducer = createReducer<State, SignupActions.Union>(
  initialState,
  on(SignupActions.createOktaAccount, state => ({
    ...state,
    isRequesting: true
  })),
  on(
    SignupActions.activateAccount,
    state => ({
      ...state,
      isInProgress: true,
      isRequesting: true
    })
  ),
  on(
    SignupActions.recoverPassword,
    state => ({
      ...state,
      isPasswordRecoveryInProgress: true,
      isRequesting: true
    })
  ),
  on(
    SignupActions.createOktaAccountSuccess,
    SignupActions.createOktaAccountFailure,
    SignupActions.activateAccountSuccess,
    SignupActions.activateAccountFailure,
    SignupActions.recoverPasswordSuccess,
    SignupActions.recoverPasswordFailure,
    state => ({
      ...state,
      isRequesting: false
    })
  ),
  on(SignupActions.oktaAccountDetailsSubmitted, (state, {email}) => ({
    ...state,
    email
  })),
  on(SignupActions.decodeOktaVerificationUri, (state, {email, secret}) => ({
    ...state,
    email,
    oktaVerificationSecret: secret
  })),
  on(SignupActions.decodeOktaRecoveryUri, (state, {email, secret}) => ({
    ...state,
    email,
    oktaRecoverySecret: secret
  })),
  on(SignupActions.setStepDetails, state => ({
    ...state,
    step: 'step-details',
  })),
  on(SignupActions.setStepVerification, state => ({
    ...state,
    step: 'step-verification',
  })),
  on(SignupActions.setStepPasswordCreate, state => ({
    ...state,
    step: 'step-password-create',
  })),
  on(SignupActions.setStepComplete, state => ({
    ...state,
    step: 'step-complete',
  })),
  on(SignupActions.setStepPasswordRecover, state => ({
    ...state,
    step: 'step-password-recover',
  })),
  on(SignupActions.setStepPasswordRecovered, state => ({
    ...state,
    step: 'step-password-recovered',
  })),
  on(SignupActions.passwordRecoveryProcessFinished, state => ({
    ...state,
    isPasswordRecoveryInProgress: false,
  })),
  on(
    SignupActions.resetSignup,
    SignupActions.receiveReset,
    state => ({ ...initialState, })
  ),
);
