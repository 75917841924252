import { Component, OnInit, Input, HostBinding, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';

@Component({
  selector: 'app-dimmer',
  templateUrl: './dimmer.component.html',
  styleUrls: ['./dimmer.component.scss'],
  animations: [
    AppearAnimationsFactory.fade('fadeState')
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimmerComponent implements OnInit {

  @Input() hasSpinner = true;
  @Input() spinnerDiameter = 100;
  @Input() strokeWidth = 3;

  @HostBinding('class.is-dimmed')
  @Input() isDimmed = false;

  @Input() targetParentClass;
  private relativeParent: HTMLElement;

  constructor(private ref: ElementRef) {}

  ngOnInit() {
    this.relativeParent = this.findParent(this.ref.nativeElement.parentElement, this.targetParentClass);
    this.relativeParent.classList.add('app-dimmer-container');
  }

  private findParent(defaultParent: HTMLElement, targetParentClass?: string): HTMLElement {
    if (!targetParentClass) {
      return defaultParent;
    }
    let currentParent = defaultParent;
    while (currentParent) {
      if (currentParent.classList.contains(this.targetParentClass)) {
        return currentParent;
      }
      currentParent = currentParent.parentElement;
    }
    return defaultParent;
  }

}
