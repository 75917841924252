import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISlypOktaAuthResponse, ISlypToken } from '@interfaces/auth.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoleType } from '@interfaces/role.interface';

@Injectable()
export class SignupService {

  constructor(private http: HttpClient) {}

  signUpWithOkta(firstname: string, lastname: string, email: string, termsAndConditionsVersion: string): Observable<{id: string}> {
    return this.http.post<{id: string}>('/v1/okta/signup', {
      firstname,
      lastname,
      username: email,
      consented_terms_and_conditions_version: termsAndConditionsVersion
    });
  }

  activateWithOkta(email: string, password: string, secret: string): Observable<ISlypToken> {
    return this.http.post<ISlypOktaAuthResponse>('/v1/okta/activate', {username: email, password, secret})
      .pipe(map(response => ({
        accessToken: response.pingdata_response.jwt_token,
        merchantId: response.pingdata_response.userRole.merchant_default || response.pingdata_response.userRole.store_ops,
        userRole: UserRoleType.storeOps in response.pingdata_response.userRole ? UserRoleType.storeOps : UserRoleType.merchantDefault,
        userId: response.user_id,
      })));
  }

  recoverPassword(username: string, password: string, secret: string): Observable<void> {
    return this.http.post<void>('/v1/managed-users/recover-password', {username, password, secret});
  }
}
