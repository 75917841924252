import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@auth/store/auth.reducer';
import * as SigninSelectors from '@signin/store/signin.selectors';
import { take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './myslyp-v2.component.html',
})
export class MySlypV2Component implements OnInit {
  private accessToken: string;
  private redirectPath: string;

  constructor(
    private rxStore: Store<fromAuth.AppState>,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.pipe(
      take(1)
    ).subscribe(data => {
      this.redirectPath = data.redirectPath;
    })

    this.rxStore.pipe(
      select(SigninSelectors.selectSlypToken),
      take(1)
    ).subscribe(data => {
      this.accessToken = data.accessToken;
      this.redirectToMySlypV2();
    })
  }

  redirectToMySlypV2() {
    window.location.replace(`${environment.mySlypV2RedirectUrl}/redirect/${this.redirectPath}?accessToken=${this.accessToken}`)
  }
}