import { HttpErrorResponse } from '@angular/common/http';
import { MiscUtils } from '@utils/misc';

interface ILivePromotionsUpdateError {
    errorList: {
      reasonCode: string;
      reasonMessage: string;
    }[];
}

export class ErrorsUtils {

  static getMsg({response, path = ['error', 'msg']}: {response: HttpErrorResponse | {}, path?: string[]}): string {
    try {
      const error = JSON.parse(MiscUtils.getNested(response, path) as string);
      const msg = MiscUtils.getNested(error, ['errorCauses', '0', 'errorSummary']);
      return msg as string;
    } catch (err) {
      return `${MiscUtils.getNested(response, path) || ''}`;
    }
  }


  // The error shape we receive from the promotion end point is different to what's
  // used throughout the rest of MySlyp so it needs to be handled here
  static getLivePromotionsUpdateErrorMessage({response}: {response: HttpErrorResponse | {}, path?: string[]}) {
    const errorMessageMapping = {
      'PROMOTION-EXPIRED': 'You cannot display promotions that are past their redemption date.',
      'PROMOTION-IS_SINGLE_USE': 'Promotions with unique redemption code cannot be displayed to all customers.',
      'PROMOTION-MAX_PROMOTIONS_BREACHED': 'You cannot display more than three live promotions.'
    }
    try {
      const code = MiscUtils.getNested(response, ['error', 'code' ]) as string;
      switch(code) {
        case 'PROMOTION-MAX_PROMOTIONS_BREACHED':
          return errorMessageMapping[code];
        default:
          const err = MiscUtils.getNested(response, ['error', 'details', 'validationErrors']) as ILivePromotionsUpdateError[];
          const msg = err.reduce((acc, cur) => [...acc, errorMessageMapping[cur.errorList[0].reasonCode] || cur.errorList[0].reasonMessage], []);
          return msg[0];
      }
    } catch (err) {
      return ErrorsUtils.getMsg({ response });
    }
  }

  static getPromotionCreateUpdateErrorMessage({response}: {response: HttpErrorResponse | {}}) {
    const errorMessageMapping = {
      'external_id must be unique': 'Promotion ID must be unique among all your promotions.',
    }
    const errMessage = MiscUtils.getNested(response, ['error', 'message']) as string;
    return errorMessageMapping[errMessage] || errMessage || ErrorsUtils.getMsg({ response });
  }
}
