import { AUTH_ROUTES } from '@configs/routing';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SigninComponent } from '@signin/signin.component';
import { SignupComponent } from '@signup/signup.component';
import { AuthComponent } from '@auth/auth.component';

const routes: Routes = [
  {
    path: 'auth', component: AuthComponent, data: { sectionTitle: AUTH_ROUTES.self.label }, children: [
      { path: '', pathMatch: 'full', redirectTo: 'signin' },
      { path: 'signup', component: SignupComponent, data: { viewTitle: AUTH_ROUTES.signup.label } },
      { path: 'signin', component: SigninComponent, data: { viewTitle: AUTH_ROUTES.signin.label } },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
