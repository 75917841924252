import { environment } from '@environments/environment';
import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SentryErrorHandler } from './sentry.handler';
import { SentryErrorInterceptor } from './sentry.interceptor';
import * as Sentry from '@sentry/browser';

interface ModuleOptions {
  dsn: string;
}

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
})
export class SentryModule {
  public static forRoot(options: ModuleOptions): ModuleWithProviders<SentryModule> {
    Sentry.init({
      dsn: options.dsn,
      release: environment.version
    });

    return {
      ngModule: SentryModule,
      providers: [
        {provide: ErrorHandler, useClass: SentryErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: SentryErrorInterceptor, multi: true},
      ],
    };
  }
}
