import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IPaginatedResponse } from '@interfaces/pagination.interface';
import { IDataTypeOption } from '@interfaces/misc.interface';
import { SELECT_AN_INDUSTRY_ID } from '@configs/misc';

@Injectable()
export class DataTypesAdapterService {

  constructor(private http: HttpClient) {}

  fetchCategories(): Observable<IDataTypeOption[]> {
    return this.http.get<IPaginatedResponse<IDataTypeOption>>('/v1/data-types/categories?limit=1000')
      .pipe(map(result => result.items));
  }

  fetchSubCategories(): Observable<IDataTypeOption[]> {
    return this.http.get<IPaginatedResponse<IDataTypeOption>>('/v1/data-types/sub-categories?limit=1000')
      .pipe(map(result => result.items));
  }

  fetchTimezones(): Observable<string[]> {
    return this.http.get<IPaginatedResponse<string>>('/v1/data-types/timezones?limit=1000')
      .pipe(
        map(result => result.items),
        map(items => items.filter(item => item.includes('Australia'))) // TODO temporary
      );
  }

  fetchMerchantTypes(): Observable<string[]> {
    return this.http.get<IPaginatedResponse<string>>('/v1/data-types/merchant-types?limit=1000')
      .pipe(map(result => result.items));
  }

  fetchIndustries(): Observable<IDataTypeOption[]> {
    return this.http.get<IPaginatedResponse<IDataTypeOption>>('/v1/data-types/industries?limit=1000')
      .pipe(
        map(result => result.items),
        map(items => items.filter(item => item.id !== SELECT_AN_INDUSTRY_ID)), // TODO temporary - for "Select an industry" option
      );
  }

}
