import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ITermsAndConditionsEntry } from '@interfaces/terms-and-conditions.interface';
import { createClient, EntryCollection } from 'contentful';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const CONFIG = environment.contentful;

@Injectable()
export class ContentfulAdapterService {

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  fetchLatestTermsAndConditionsVersion(): Observable<string> {
    // @TODO - Hack due to removal of contentful
    // SLYP-7479

    // const promise: Promise<EntryCollection<ITermsAndConditionsEntry>> = this.cdaClient
    //   .getEntries({
    //     content_type: CONFIG.contentTypeIds.termsAndConditionsEntry,
    //     order: '-fields.publishedAt',
    //     skip: 0,
    //     limit: 1
    //   });

    const promise = Promise.resolve({
      items: [{
        fields: {
          version: '3.1' // Latest version as in https://www.slyp.com.au/about-us/terms-of-service/
        }
      }]
    });

    return from(promise)
      .pipe(
        map(res => res.items[0] || null),
        map(tacRecord => tacRecord.fields.version)
      );
  }

}
