<app-multi-view [current]="view$ | async">
  <ng-container *appMultiViewItem="'form'">
    <div class="card-title">
      <span>Forgot your password?</span>
      <div class="flex-grow-1"></div>
      <mat-icon class="sds-color-base-grey_medium-25W sds-btn-any no-appearance" mat-dialog-close  data-test="close"  >close</mat-icon>
    </div>

    <form class="flex-grow-1 d-flex flex-column" [formGroup]="form" [appBlockRedundantSubmit]="isRequesting$ | async" (validNgSubmit)="onSubmit()">
      <div class="card-body flex-grow-1">
        <div class="row no-gutters">
          <div class="col">
            <p class="sds-color-base-grey_medium-25W">
              Tell us your username and we'll send password reset link.
            </p>
          </div>
        </div>
        <div class="row no-gutters">
          <mat-form-field appearance="fill" class="col-12 sds-form-field primary">
            <mat-label>Username (email)</mat-label>
            <input matInput type="text" name="username" formControlName="username" data-test="username" required appValidateOnBlur>
            <mat-error *ngIf="errors['username$'] | async as error" data-test="error">{{ error }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="card-actions">
        <button type="button" class="sds-btn" data-test="cancel" mat-dialog-close>cancel</button>
        <div class="flex-grow-1"></div>
        <button type="submit" class="sds-btn primary emphasized"  data-test="submit">
          <app-dimmer class="sds-bg-brand-primary sds-color-base-white"
            [isDimmed]="isRequesting$ | async"
            [spinnerDiameter]="20"
            [strokeWidth]="1.5"
          ></app-dimmer>
          SUBMIT
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *appMultiViewItem="'success'">
    <div class="card-title">
      <span>Thanks!</span>
      <div class="flex-grow-1"></div>
      <mat-icon class="sds-color-base-grey_medium-25W sds-btn-any no-appearance" mat-dialog-close>close</mat-icon>
    </div>

    <div class="card-body d-flex flex-column align-items-center text-center">
      <div class="badge-circle big primary p-4 my-4">
        <mat-icon>thumb_up</mat-icon>
      </div>

      <p class="sds-color-base-grey_medium sds-heading-5">We've got your request!</p>

      <p class="sds-color-base-grey_medium">
        You’ll receive an email at <b>{{ form.value.username }}</b>. Please follow the instructions it contains.
      </p>
      <p class="mb-4 sds-color-base-grey_medium">
        In the meantime, if you have any questions <a class="sds-color-brand-primary" [href]="'mailto:'+supportMail">email us</a>.
      </p>
    </div>
  </ng-container>
</app-multi-view>
