import { Injectable } from '@angular/core';
import * as AppActions from '@app/store/app.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifierService } from 'angular-notifier';
import { tap } from 'rxjs/operators';


@Injectable()
export class AppEffects {

  fetchApiKeysSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AppActions.notifyError),
    tap(({message}) => this.notifier.show({type: 'error', message}))
  ), {dispatch: false});

  constructor (
    private actions$: Actions,
    private notifier: NotifierService,
  ) {}

}
