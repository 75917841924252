import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtmlPipeModule } from '@pipes/safe-html/safe-html-pipe.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmService } from './confirm.service';

@NgModule({
  declarations: [
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    SafeHtmlPipeModule,
  ],
  exports: [
    ConfirmDialogComponent
  ],
  providers: [
    ConfirmService,
    {provide: MAT_DIALOG_DATA, useValue: {}},
  ],
})
export class ConfirmModule {}
