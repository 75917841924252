import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorsUtils } from '@app/core/utils/errors';
import * as AppActions from '@app/store/app.actions';
import { ForgotPasswordComponent } from '@auth/forgot-password/forgot-password.component';
import * as ForgotPasswordActions from '@auth/forgot-password/store/forgot-password.actions';
import * as fromAuth from '@auth/store/auth.reducer';
import { DIALOG_COMPONENT_WIDTH_NARROM } from '@configs/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EmailsService } from '@services/emails.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';


@Injectable()
export class ForgotPasswordEffects {

  forgotPasswordClicked$ = createEffect(() => this.actions$.pipe(
    ofType(ForgotPasswordActions.forgotPasswordClicked),
    tap(() => this.dialogs.open(ForgotPasswordComponent, {
      width: DIALOG_COMPONENT_WIDTH_NARROM + 'px',
      panelClass: ['card-dialog']
    }))
  ), {dispatch: false});

  usernameSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType(ForgotPasswordActions.usernameSubmitted),
    map(({username}) => ForgotPasswordActions.requestForgotPasswordEmail({username}))
  ));

  requestForgotPasswordEmail$ = createEffect(() => this.actions$.pipe(
    ofType(ForgotPasswordActions.requestForgotPasswordEmail),
    mergeMap(({username}) => this.emails.requestForgotPasswordEmail(username).pipe(
      map(() => ForgotPasswordActions.requestForgotPasswordEmailSuccess()),
      catchError(response => of(ForgotPasswordActions.requestForgotPasswordEmailFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    )),
  ));

  requestForgotPasswordEmailFailure$ = createEffect(() => this.actions$.pipe(
    ofType(ForgotPasswordActions.requestForgotPasswordEmailFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'requesting password reset email', errorMsg}))
  ));

  constructor (
    private actions$: Actions,
    private rxStore: Store<fromAuth.AppState>,
    private dialogs: MatDialog,
    private emails: EmailsService
  ) {}

}
