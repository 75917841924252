<div class="badge-circle big primary p-4 mb-5">
  <mat-icon>email</mat-icon>
</div>
<div class="row no-gutters mb-4">
  <div class="col">
    <h2 class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin">A verification link has been sent to your email account.</h2>
    <p class="sds-color-base-grey_medium mb-0">To verify your account, simply click on the link sent to <b>{{email$ | async}}</b>.</p>
  </div>
</div>
<div class="row no-gutters">
  <div class="col">
    <button class="my-3 sds-btn" [appBlockRedundantClick]="isRequesting$ | async" (validClick)="onResendEmail()" data-test="resendEmail"  >
      <app-dimmer class="sds-bg-base-grey_medium sds-color-base-white"
        [isDimmed]="isRequesting$ | async"
        [spinnerDiameter]="20"
        [strokeWidth]="1.5"
      ></app-dimmer>
      RESEND EMAIL
    </button>
  </div>
</div>
