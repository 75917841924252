import { Injectable } from '@angular/core';
import * as fromApp from '@app/store/app.reducer';
import { Store } from '@ngrx/store';
import * as UIActions from '@ui/store/ui.actions';
import * as UISelectors from '@ui/store/ui.selectors';

@Injectable()
export class UIFacade {

  isMobile$ = this.rxStore.select(UISelectors.selectIsMobile);
  isTablet$ = this.rxStore.select(UISelectors.selectIsTablet);
  isDesktop$ = this.rxStore.select(UISelectors.selectIsDesktop);

  constructor(private rxStore: Store<fromApp.AppState>) {}

  dispatch(action: UIActions.Union) {
    this.rxStore.dispatch(action);
  }

}
