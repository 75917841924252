import { FormGroup, FormArray } from '@angular/forms';
import { debounceTime, map, takeUntil, take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { ErrorStreams } from '@interfaces/misc.interface';
import { Observable, combineLatest } from 'rxjs';


export class FormsUtils {

  private static genericErrorMessages = {
    static: {
      required: 'Is required.',
      email: 'Has to match "name@domain" pattern.',
      positiveinteger: 'Has to be a positive number.',
      negativeinteger: 'Has to be a negative number.',
      integer: 'Has to be a number.',
      nowhitespace: 'Cannot contain whitespace.',
      addressrequired: 'Find and choose an option.',
      hexcolor: 'Needs to be a hex like #FFFFFF.',
      positivetimedifference: 'Lower time range bound has to occur before the upper one.',
      positivedatetimedifference: 'Lower time range bound has to occur before the upper one.',
      matchingpasswords: 'Password have to match.',
      phonenumbere164: 'Needs to match E164 format (like +61 2xx xxx xxx).',
      phone_pattern: 'Allowed characters are numbers, +, ( and )',
    },
    dynamic: {
      minlength: ({requiredLength, actualLength}) => `Has to be ${requiredLength} characters min (${actualLength}).`,
      maxlength: ({requiredLength, actualLength}) => `Has to be ${requiredLength} characters max (${actualLength}).`,
      maxlength_no_whitespace: ({requiredLength, actualLength}) => `Has to be ${requiredLength} characters max not counting whitespace (${actualLength}).`,
      exactlength: ({requiredLength, actualLength}) => `Has to be ${requiredLength} characters exactly (${actualLength}).`,
      domain: ({requiredDomain}) => `Has to include "${requiredDomain}" domain.`,
      notequal: ({how, otherControlLabel}) => `Has to be ${how} than ${otherControlLabel}.`,
    }
  };

  private static getFirstApplicableError(control: AbstractControl, customErrorMessages?: {[key: string]: string}): string {
    const hasError = control.touched && control.invalid && control.errors && Object.keys(control.errors).length;

    if (!hasError) {
      return '';
    }

    const firstErrorCode = Object.keys(control.errors)[0];

    return (customErrorMessages && customErrorMessages[firstErrorCode])
      || this.genericErrorMessages.static[firstErrorCode]
      || (this.genericErrorMessages.dynamic[firstErrorCode] && this.genericErrorMessages.dynamic[firstErrorCode](control.errors[firstErrorCode]))
      || ''
      ;
  }

  // for when error is to be updated without value change (eg on touched) use appValidateOnBlur directive
  private static getErrorStream(control: AbstractControl, destroy$: Subject<void>, customErrorMessages?: {[key: string]: string}): BehaviorSubject<string> {

    const sub$ = new BehaviorSubject<string>(this.getFirstApplicableError(control, customErrorMessages));

    control.statusChanges.pipe(
      takeUntil(destroy$),
      debounceTime(150),
      map(() => this.getFirstApplicableError(control, customErrorMessages)),
    ).subscribe(error => sub$.next(error));

    destroy$.pipe(take(1)).subscribe(() => sub$.complete());

    return sub$;
  }

  static getErrorStreams(
    destroy$: Subject<void>,
    config: {[formControlName$: string]: AbstractControl},
    customErrorMessages?: {[key: string]: string}
  ): ErrorStreams {
    const streams = Object.entries(config).reduce((conf: ErrorStreams, [formControlName, control]) => ({
      ...conf,
      [formControlName]: this.getErrorStream(control, destroy$, customErrorMessages)
    }), {});
    return streams;
  }

  static filterOptions$({filter$, options$, filterBy, destroy$}: {
    filter$: Observable<string>, // value that options are to be filtered with
    options$: Observable<any[]>, // list of options
    filterBy?: string | string[], // property of a single option that a list of options is to be filtered by
    destroy$: Subject<void> // takeUntil signal
  }): BehaviorSubject<any[]> {
    const filteredOptions$ = new BehaviorSubject([]);

    const predicate = !filterBy
      // flat
      ? (option, filter) => option && option.toLowerCase().includes(filter.toLowerCase())
      : !Array.isArray(filterBy)
        // single prop
        ? (option, filter) => option && option[filterBy] && option[filterBy].toLowerCase().includes(filter.toLowerCase())
        // array of props
        : (option, filter) => filterBy.some(filterByVariant => option && option[filterByVariant] && option[filterByVariant].toLowerCase().includes(filter.toLowerCase()))
      ;

    combineLatest([
      filter$.pipe(debounceTime(250)),
      options$
    ]).pipe(
      takeUntil(destroy$),
      map(([filter, options]) => !filter
        ? options
        : options.filter(option => predicate(option, filter))
      )
    ).subscribe(options => filteredOptions$.next(options));

    destroy$
      .pipe(take(1))
      .subscribe(() => filteredOptions$.complete());

    return filteredOptions$;
  }

  // Recursively mark all of forms controls as touched - to reflect validity in the template
  static showAllErrors(form: FormGroup | FormArray) {
    if (form.controls) {
      Object.keys(form.controls).forEach(controlName => {
        const control = form.get(controlName);
        control.markAsTouched();
        control.updateValueAndValidity();
        this.showAllErrors(control as (FormGroup | FormArray));
      });
    }
  }

}
