import { Injectable } from '@angular/core';
import * as fromAuth from '@auth/store/auth.reducer';
import { Store } from '@ngrx/store';
import * as ForgotPasswordActions from '@auth/forgot-password/store/forgot-password.actions';
import * as ForgotPasswordSelectors from '@auth/forgot-password/store/forgot-password.selectors';

@Injectable()
export class ForgotPasswordFacade {

  isRequesting$ = this.rxStore.select(ForgotPasswordSelectors.selectIsRequesting);
  view$ = this.rxStore.select(ForgotPasswordSelectors.selectView);

  constructor(private rxStore: Store<fromAuth.AppState>) {}

  dispatch(action: ForgotPasswordActions.Union) {
    this.rxStore.dispatch(action);
  }

}
