import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent implements OnInit {

  @Input() buttons: {label: string, func: (...args: any[]) => any}[];

  constructor() { }

  ngOnInit() {
  }

}
