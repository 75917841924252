import { createSelector } from '@ngrx/store';
import * as AuthSelectors from '@auth/store/auth.selectors';

export const selectState = createSelector(
  AuthSelectors.selectState,
  state => state.resendActivationEmail
);

export const selectIsRequestingState = createSelector(
  selectState,
  state => state.isRequesting
);

export const selectEmail = createSelector(
  selectState,
  state => state.email
);
