import { Injectable } from '@angular/core';
import * as fromAuth from '@auth/store/auth.reducer';
import * as AuthSelectors from '@auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import * as SignupActions from '@signup/store/signup.actions';
import * as SignupSelectors from '@signup/store/signup.selectors';


@Injectable()
export class SignupFacade {

  isRequesting$ = this.rxStore.select(SignupSelectors.selectIsRequestingState);
  state$ = this.rxStore.select(SignupSelectors.selectState);
  step$ = this.rxStore.select(SignupSelectors.selectStep);
  stepsCount$ = this.rxStore.select(SignupSelectors.selectStepsCount);
  accountEmail$ = this.rxStore.select(SignupSelectors.selectAccountEmail);

  constructor(private rxStore: Store<fromAuth.AppState>) {}

  dispatch(action: SignupActions.Union) {
    this.rxStore.dispatch(action);
  }

}
