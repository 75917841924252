import { createAction, union, props } from '@ngrx/store';

export const screenResized = createAction(
  '[ UI ][ AppComponent ] SCREEN_RESIZED',
);

export const recalculateIsMobile = createAction(
  '[ UI ][ SCREEN_RESIZED Effect ] RECALCULATE_IS_MOBILE',
);

export const screenWidthUpdateNeeded = createAction(
  '[ UI ][ HomeComponent ] SCREEN_WIDTH_UPDATE_NEEDED',
);

export const registerScrollingElement = createAction(
  '[ UI ][ HomeComponent ] REGISTER_SCROLLING_ELEMENT',
  props<{scrollingElementId: string}>()
);

export const unregisterScrollingElement = createAction(
  '[ UI ][ HomeComponent ] UNREGISTER_SCROLLING_ELEMENT'
);

export const adjustScrollElementToDrag = createAction(
  '[ UI ][ StructureItemStoreComponent ] ADJUST_SCROLL_ELEMENT_TO_DRAG',
  props<{pointerPosition: {x: number, y: number}}>()
);

const all = union({
  screenResized,
  recalculateIsMobile,
  screenWidthUpdateNeeded,
  registerScrollingElement,
  unregisterScrollingElement,
  adjustScrollElementToDrag,
});
export type Union = typeof all;
