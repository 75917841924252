import { createReducer, on } from '@ngrx/store';
import * as OptionsActions from '@options/store/options.actions';

export type IOptionSlice
  = 'industries'
  | 'allowedApiKeyRoles'
  | 'merchantTypes'
  | 'timezones'
  | 'merchantWebhookEvents'
  | 'receiptFeedFilterableFields'
  | 'receiptDeliveryChannels'
  | 'receiptCardTypes'
  ;

export interface State {
  isLoading: boolean;
  options: {[key in IOptionSlice]?: any[]};
}

const initialState: State = {
  isLoading: false,
  options: {},
};

export const reducer = createReducer(
  initialState,
  on(OptionsActions.fetchOptionsSuccess, (state, {options}) => ({
    ...state,
    isLoading: false,
    options: {
      ...state.options,
      ...options
    }
  })),
  on(OptionsActions.fetchOptions, state => ({
    ...state, isLoading: true,
  })),
  on(OptionsActions.fetchOptionsFailure, state => ({
    ...state,
    isLoading: false,
  })),
  on(
    OptionsActions.receiveReset,
    state => ({ ...initialState })
  ),
);
