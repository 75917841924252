import { MiscUtils } from '@utils/misc';
import * as UIActions from '@ui/store/ui.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  scrollingElementId: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const initialState: State = {
  scrollingElementId: null,
  isMobile: false,
  isTablet: false,
  isDesktop: true,
};

export const reducer = createReducer(
  initialState,
  on(UIActions.recalculateIsMobile, state => ({
    ...state,
    isMobile: MiscUtils.isMobile(),
    isTablet: MiscUtils.isTablet(),
    isDesktop: MiscUtils.isDesktop(),
  })),
  on(UIActions.registerScrollingElement, (state, {scrollingElementId}) => ({
    ...state,
    scrollingElementId
  })),
  on(UIActions.unregisterScrollingElement, state => ({
    ...state,
    scrollingElementid: null
  })),
);
