import { TIME_FORMAT_12, DATE_FORMAT_BACKEND } from '@configs/datetime';
import { FormGroup, AbstractControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

export const HTTP_LINK_PATTERN = /^((http:\/\/)|(https:\/\/)).+$/;

export class CustomValidators {

  // FormGroup level
  static addressRequired(control: AbstractControl): ValidationErrors {
    const addressGroup = control as FormGroup;
    const isAnyFieldInvalid = Object.keys(addressGroup.controls).find(controlName => addressGroup.controls[controlName].invalid);
    return !!isAnyFieldInvalid ? {addressrequired: true} : null;
  }

  // FormGroup level
  static matchingPasswords = (passwordControlKey: string, passwordConfirmControlKey: string) =>
    (control: AbstractControl): ValidationErrors => {
      const passwordsGroup = control as FormGroup;
      const arePasswordsMatching = passwordsGroup.get(passwordControlKey).value === passwordsGroup.get(passwordConfirmControlKey).value;
      return passwordsGroup.touched && !arePasswordsMatching ? {matchingpasswords: true} : null;
    }

  static integer = (comparatorCondition: 'any' | 'positive' | 'negative' = 'any') =>
    (control: AbstractControl): ValidationErrors => {
      if (control.value == null) {
        return null;
      }

      const strippedValue = (control.value + '').replace(/\s/g, '');

      if (strippedValue.length === 0) {
        return null;
      }

      // what value should actually be -> test for wrong value
      const comparator = comparatorCondition === 'positive'
        ? v => v <= 0
        : comparatorCondition === 'negative'
        ? v => v >= 0
        : null
        ;

      const value = parseInt(strippedValue);
      return isNaN(value) || (comparator && comparator(value)) || !/^[0-9]+$/g.test(strippedValue)
        ? {[(comparatorCondition !== 'any' ? comparatorCondition : '') + 'integer']: true}
        : null;
    }

  static exactLength = (requiredLength: number, ignoreWhitespace?: boolean) =>
    (control: AbstractControl): ValidationErrors => {
      let value = control.value;

      if (value == null) {
        return null;
      }

      if (ignoreWhitespace) {
        value = value.replace(/\s/g, '');
      }

      const actualLength = (value + '').length;

      return actualLength !== requiredLength && actualLength !== 0 ? {exactlength: {actualLength, requiredLength}} : null;
    }

  static includes = (pattern: RegExp, key: string, allowEmpty?: boolean) =>
    (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return !allowEmpty ? {[`includes_${key}`]: true} : null;
      }
      return pattern.test(control.value) ? null : {[`includes_${key}`]: true};
    }

  static zeroMinLength = (minLength: number) =>
    (control: AbstractControl): ValidationErrors => {
      const originalError = Validators.minLength(minLength)(control);
      if (!originalError) {
        return control.value == null || control.value === '' ? {minlength: {requiredLength: minLength, actualLength: 0}} : null;
      }
      return originalError;
    }

  static noWhitespace(control: AbstractControl): ValidationErrors {
    if (control.value == null) {
      return null;
    }

    return /\s/.test(control.value) ? {nowhitespace: true} : null;
  }

  static noEmptyString = () => (control: AbstractControl): ValidationErrors => {
    if (control.value == null) {
      return null;
    }

    return control.value.trim().length === 0 ? {noemptystring: true} : null;
  }

  static hexColor(control: AbstractControl): ValidationErrors {
    const isHexColor  = !control.value || /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(control.value);
    return isHexColor ? null : {hexcolor: true};
  }

  static phoneNumberE164(control: AbstractControl): ValidationErrors {
    if (control.value == null) {
      return null;
    }
    const trimmed = control.value ? control.value.replace(/\s/g, '') : '';
    try {
      const results = phoneUtil.parseAndKeepRawInput(trimmed, 'AU');
      const isValid = phoneUtil.isValidNumber(results);
      return isValid ? null : {phonenumbere164: true};
    } catch (err) {
      return {phonenumbere164: true};
    }
  }

  // FormGroup level
  static positiveTimeDifference = (fromControlName: string, toControlName: string, overrideControlName?: string) =>
    (control: AbstractControl): ValidationErrors => {
      const from = control.get(fromControlName).value;
      const to = control.get(toControlName).value;

      const shouldNeglect = overrideControlName && !control.get(overrideControlName).value; // this is intended for isClosed in edit-store

      if (shouldNeglect || from == null || to == null) {
        return null;
      }

      const difference = moment(to, TIME_FORMAT_12).diff(moment(from, TIME_FORMAT_12));

      return difference > 0 ? null : {positivetimedifference: true};
    }

  // FormGroup level
  static positiveDateTimeDifference = (fromTimeControlName: string, fromDateControlName: string, toTimeControlName: string, toDateControlName: string) =>
    (control: AbstractControl): ValidationErrors => {
      const fromTime = control.get(fromTimeControlName).value;
      const fromDate = control.get(fromDateControlName).value;
      const toTime = control.get(toTimeControlName).value;
      const toDate = control.get(toDateControlName).value;

      if (!fromTime || !fromDate || !toTime || !toDate) {
        return null;
      }

      const from = moment(fromDate.format(DATE_FORMAT_BACKEND) + fromTime, DATE_FORMAT_BACKEND + TIME_FORMAT_12);
      const to = moment(toDate.format(DATE_FORMAT_BACKEND) + toTime, DATE_FORMAT_BACKEND + TIME_FORMAT_12);
      const difference = to.diff(from);

      return difference > 0 ? null : {positivedatetimedifference: true};
    }

  static notEqual = (how: 'bigger' | 'lesser', otherControlName: string, otherControlLabel: string) =>
    (control: AbstractControl): ValidationErrors => {

      if (control.parent == null) {
        return null;
      }

      const less = how === 'bigger' ? control.parent.get(otherControlName).value : control.value;
      const more = how === 'lesser' ? control.parent.get(otherControlName).value : control.value;

      if (less == null || more == null) {
        return null;
      }

      const difference = more - less;

      return difference > 0 ? null : {notequal: {how, otherControlLabel}};
    }

  static streamPluggable = (errorCode: string, stream$: BehaviorSubject<{invalid: boolean}>) =>
    (control: AbstractControl): Promise<ValidationErrors> => {
      const resolve = async () => await stream$
        .pipe(
          take(1),
          map(({invalid}) => invalid ? {[errorCode]: true} : null)
        ).toPromise();
      return resolve();
    }

  /**
   * Returned validator will return an error if value stripped of whitespace is longer than provided maxLength
   * @param maxLength Max valid length of control value
   * @returns ValidatorFn ready to be used in reactive forms
   */
  static maxLengthDisregardWhitespace = (maxLength: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      let value = control.value;

      if (value == null) {
        return null;
      }

      value = (value + '').replace(/\s/g, '');
      const actualLength = value.length;

      return actualLength > maxLength ? {maxlength_no_whitespace: {actualLength, requiredLength: maxLength}} : null;
    }

  /**
   * Returned validator will return an error of provided key if existing value does not match provided pattern.
   * @param key Used to correlate errors returned by this validator with custom error message defined in ErrorStreams instance
   * @param pattern Pattern by which form control value is to be tested
   * @param ignoreWhitespace If true, strip value of whitespace before validating
   * @returns ValidatorFn ready to be used in reactive forms
   */
  static pattern = (key: string, pattern: RegExp, ignoreWhitespace?: boolean): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
      let value = control.value;

      if (control.value == null || control.value === '') {
        return null;
      }

      if (ignoreWhitespace) {
        value = value.replace(/\s/g, '');
      }

      return pattern.test(value) ? null : {[key]: true};
    }

  /**
   * Allow numbers, +, ( and ). If not matched, return error.
   * @returns CustomValidators.pattern validator configured to validate phone number
   */
  static phoneNumberPattern(): ValidatorFn {
    return this.pattern('phone_pattern', /^[\+\(\)\d]+$/, true);
  }

  /**
   * A validator based on a custom function
   */
  static custom = (name: string, func: (any) => Boolean): ValidatorFn =>
  (control: AbstractControl): ValidationErrors => {
    return func(control.value) ? null : { [name]: true };
  }
}
