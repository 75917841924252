import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { of } from "rxjs";
import { take, filter, tap, map, catchError } from "rxjs/operators";
import { SigninFacade } from "@signin/store/signin.facade";
import * as fromApp from "@app/store/app.reducer";
import { Store } from "@ngrx/store";
import { notifyError } from "@app/store/app.actions";
import { RoleService } from "@services/role.service";

@Injectable()
export class RoleGuard implements CanActivate, CanLoad {
  constructor(
    private rxAppStore: Store<fromApp.AppState>,
    private roleService: RoleService,
    private rxSignin: SigninFacade
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.isAuthorisedRole(route.url.map((segment) => segment.path));
  }

  canLoad(_: Route, segments: UrlSegment[]): Promise<boolean> {
    return this.isAuthorisedRole(segments.map((segment) => segment.path));
  }

  private isAuthorisedRole(route: string[]): Promise<boolean> {
    return this.rxSignin.slypToken$
      .pipe(
        filter((token) => !!token),
        take(1),
        map(({ userRole }) => this.roleService.isRouteAllowedForRole(userRole, route)),
        tap((authorised) => !authorised &&
          this.rxAppStore.dispatch(
            notifyError({
              errorMsg:
                "You are not authorised to view this page. Please contact your administrator for access.",
            }))
        ),
        catchError(_ => of(false).pipe(
          tap(_ => this.rxAppStore.dispatch(
            notifyError({
              errorMsg:
                "There was an error checking authorisation",
            })
          ))
        ))
      )
      .toPromise();
  }
}
