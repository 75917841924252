import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, props, union } from '@ngrx/store';

export const privacyPolicyLinkClicked = createAction(
  '[ Auth/Signup ][ StepDetailsComponent ] PRIVACY_POLICY_LINK_CLICKED',
);

export const setStepDetails = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) ] SET_STEP_DETAILS',
);

export const setStepVerification = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) | CREATE_OKTA_ACCOUNT_SUCCESS Effect ] SET_STEP_VERIFICATION',
);

export const setStepPasswordCreate = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) | DECODE_OKTA_VERIFICATION_URI Effect ] SET_STEP_PASSWORD_CREATE',
);

export const setStepComplete = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) | PICK_UP_SIGNUP_AT_COMPLETE Effect ] SET_STEP_COMPLETE',
);

export const setStepPasswordRecover = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) | DECODE_OKTA_RECOVERY_URI Effect ] SET_STEP_PASSWORD_RECOVER',
);

export const setStepPasswordRecovered = createAction(
  '[ Auth/Signup ][ SignupComponent (debug) | PICK_UP_SIGNUP_AT_PASSWORD_RECOVERED Effect ] SET_STEP_PASSWORD_RECOVERED',
);

export const oktaAccountDetailsSubmitted = createAction(
  '[ Auth/Signup ][ StepDetailsComponent ] OKTA_ACCOUNT_DETAILS_SUBMITTED',
  props<{first: string, last: string, email: string}>()
);

export const createOktaAccount = createAction(
  '[ Auth/Signup ][ OKTA_ACCOUNT_DETAILS_SUBMITTED Effect ] CREATE_OKTA_ACCOUNT',
  props<{first: string, last: string, email: string}>()
);

export const createOktaAccountSuccess = createAction(
  '[ Auth/Signup ][ CREATE_OKTA_ACCOUNT Effect ] CREATE_OKTA_ACCOUNT_SUCCESS',
);

export const createOktaAccountFailure = createAction(
  '[ Auth/Signup ][ CREATE_OKTA_ACCOUNT Effect ] CREATE_OKTA_ACCOUNT_FAILURE',
  props<{errorMsg: string}>()
);

export const pickUpSignupAtEmailVerification = createAction(
  '[ Auth/Signup ][ Auth/Routing/ROUTE_TO_SIGNUP_EMAIL_VERIFICATION Effect ] PICK_UP_SIGNUP_AT_EMAIL_VERIFICATION',
  props<{email: string, secret: string}>()
);

export const pickUpSignupAtPasswordRecovery = createAction(
  '[ Auth/Signup ][ Auth/Routing/ROUTE_TO_SIGNUP_PASSWORD_RECOVERY Effect ] PICK_UP_SIGNUP_AT_PASSWORD_RECOVERY',
  props<{email: string, secret: string}>()
);

export const pickUpSignupAtComplete = createAction(
  '[ Auth/Signup ][ Auth/Routing/ROUTE_TO_SIGNUP_COMPLETE Effect ] PICK_UP_SIGNUP_AT_COMPLETE',
);

export const pickUpSignupAtPasswordRecovered = createAction(
  '[ Auth/Signup ][ Auth/Routing/ROUTE_TO_PASSWORD_RECOVERED Effect ] PICK_UP_SIGNUP_AT_PASSWORD_RECOVERED',
);

export const decodeOktaVerificationUri = createAction(
  '[ Auth/Signup ][ PICK_UP_SIGNUP_AT_EMAIL_VERIFICATION Effect ] DECODE_OKTA_VERIFICATION_URI',
  props<{email: string, secret: string}>()
);

export const decodeOktaRecoveryUri = createAction(
  '[ Auth/Signup ][ PICK_UP_SIGNUP_AT_PASSWORD_RECOVERY Effect ] DECODE_OKTA_RECOVERY_URI',
  props<{email: string, secret: string}>()
);

export const passwordSubmitted = createAction(
  '[ Auth/Signup ][ StepPasswordComponent ] PASSWORD_SUBMITTED',
  props<{password: string, isPasswordRecovery: boolean}>()
);

export const activateAccount = createAction(
  '[ Auth/Signup ][ PASSWORD_SUBMITTED Effect ] ACTIVATE_ACCOUNT',
  props<{password: string}>()
);

export const activateAccountSuccess = createAction(
  '[ Auth/Signup ][ ACTIVATE_ACCOUNT Effect ] ACTIVATE_ACCOUNT_SUCCESS',
  props<{email: string, password: string}>()
);

export const activateAccountFailure = createAction(
  '[ Auth/Signup ][ ACTIVATE_ACCOUNT Effect ] ACTIVATE_ACCOUNT_FAILURE',
  props<{errorMsg: string}>()
);

export const recoverPassword = createAction(
  '[ Auth/Signup ][ PASSWORD_SUBMITTED Effect ] RECOVER_PASSWORD',
  props<{password: string}>()
);

export const recoverPasswordSuccess = createAction(
  '[ Auth/Signup ][ RECOVER_PASSWORD Effect ] RECOVER_PASSWORD_SUCCESS',
  props<{email: string, password: string}>()
);

export const recoverPasswordFailure = createAction(
  '[ Auth/Signup ][ RECOVER_PASSWORD Effect ] RECOVER_PASSWORD_FAILURE',
  props<{errorMsg: string}>()
);

export const passwordRecoveryProcessFinished = createAction(
  '[ Auth/Signup ][ StepPasswordRecoveredComponent ] PASSWORD_RECOVERY_PROCESS_FINISHED',
);

export const signupProcessFinished = createAction(
  '[ Auth/Signup ][ StepCompleteComponent ] SIGNUP_PROCESS_FINISHED',
);

export const resendActivationEmailClicked = createAction(
  '[ Auth/Signup ][ StepVerificationComponent ] RESEND_ACTIVATION_EMAIL_CLICKED',
);

export const resetSignup = createAction(
  '[ Auth/Signup ][ SIGNUP_PROCESS_FINISHED EFFECT | Auht/Signin/SIGN_IN Effect ] RESET_SIGNUP',
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE,
);

const all = union({
  privacyPolicyLinkClicked,
  setStepDetails,
  setStepVerification,
  setStepPasswordCreate,
  setStepComplete,
  setStepPasswordRecover,
  setStepPasswordRecovered,
  oktaAccountDetailsSubmitted,
  createOktaAccount,
  createOktaAccountSuccess,
  createOktaAccountFailure,
  decodeOktaVerificationUri,
  decodeOktaRecoveryUri,
  passwordSubmitted,
  activateAccount,
  activateAccountSuccess,
  activateAccountFailure,
  recoverPassword,
  recoverPasswordSuccess,
  recoverPasswordFailure,
  pickUpSignupAtEmailVerification,
  pickUpSignupAtPasswordRecovery,
  pickUpSignupAtComplete,
  pickUpSignupAtPasswordRecovered,
  signupProcessFinished,
  passwordRecoveryProcessFinished,
  resendActivationEmailClicked,
  resetSignup,
});
export type Union = typeof all;
