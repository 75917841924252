import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as SignupActions from '@signup/store/signup.actions';
import { SignupFacade } from '@signup/store/signup.facade';

@Component({
  selector: 'app-step-password-recovered',
  templateUrl: './step-password-recovered.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepPasswordRecoveredComponent {

  constructor(private rxSignup: SignupFacade) { }

  onComplete() {
    this.rxSignup.dispatch(SignupActions.passwordRecoveryProcessFinished());
  }

}
