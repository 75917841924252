<div class="card-title">
  <span>Privacy Policy</span>
  <div class="flex-grow-1"></div>
  <mat-icon class="sds-color-base-grey_medium-25W sds-btn-any no-appearance" mat-dialog-close data-test="close">close</mat-icon>
</div>

<div class="card-body">

  <h4 class="pt-0 mb-3">Privacy Policy</h4>
  <p class="mb-5">Last updated: 22-11-2019</p>

  <p> We, Slyp, have developed this policy for you, an individual or organisation with whom we interact, whereby you may be a customer, partner, visitor to our site, or a combination of the above. In other words, this policy is not limited to current customers. It applies to all individuals who deal with us for any business purpose. </p>
  <p> We have developed this policy to clarify to you how we are embracing the Australian Privacy Act. </p>
  <p> Please note this policy may be updated from time to time to ensure compliance to applicable laws. You can always find the most up-to-date version on our website. We use some specific terminology throughout this policy, which are explained in Appendix 1 for your reference. </p>

  <h5> 1. About Slyp </h5>

  <p> <b>1.1 Who are we:</b> We use ‘we’ (or ‘our’ or ‘us’) to refer to Slyp Pty. Ltd. We are committed to delivering innovative and elegant solutions that solve real problems for real people. Our vision is to help Australian consumers and merchants eliminate the need for paper tax receipts. Our purpose is to reduce the impact that paper receipts have on the environment while also helping customers and businesses transact in a more digitally friendly way. </p>
  <p> <b>1.2 What do we do:</b> We have created the Slyp Smart Receipt. Our solution replaces redundant paper receipts with a digitized, feature-rich transaction record that may be delivered in an online banking portal, or via an SMS or email linking to an online location where the Smart Receipt will be hosted. Slyp Smart Receipts offer new ways for consumers and retailers to digitally connect. </p>

  <h5> 2. Your privacy is very important to us </h5>

  <p> <b>2.1 Our privacy stance:</b> At Slyp, the protection of your data is of critical importance. This policy is laid out in an easy to understand way so that you can quickly understand our position on data privacy and our commitment to protecting the data and personal information we collect. </p>
  <p> <b>2.2 Your agreement to this policy:</b> By using this site and/or our services, you agree to the terms and conditions of this Privacy Policy. </p>

  <h5> 3. Information we may collect from you </h5>

  <i class="d-inline-block mb-3">
    Information we collect from you may include some personal information, whichis
    information which can be used to directly or indirectly identify an individual. This section details
    how we collect and create personal information, what categories of personal information we may
    process, as well as our stance on sensitive personal information.
  </i>

  <p> <b>3.1 Categories of information we may collect, create or process:</b> We may collect, create or process the following categories of personal information about you: </p>
  <ul>
    <li> Identity information: gender, age range, postcode or Statistical Area Level 2 (as defined by the Australian Bureau of Statistics); </li>
    <li> Contact details: email address, mobile phone number (Those will only be collected when explicitly provided by you at the point of checkout for our SMS/email delivery solutions); </li>
    <li> Consent records: records of any consents you give to us, a merchant or your bank, together with the date and time, means of consent and any related information; </li>
    <li> Purchase details: records of purchases and prices; </li>
    <li> Payment details: invoice records (e.g. receipt information), payment records, de-identified credit card information; </li>
    <li> Data relating to our sites: your device type, operating system, browser type and settings, IP address, dates and times of connecting to a site, pixel tags, and other technical communications information which we need in order to optimise the quality of our service offering for you; and </li>
    <li> Views and opinions: any views and opinions that you choose to send to us, or publicly post about us on social media platforms. </li>
  </ul>

  <p> In addition to collecting personal information directly from you, we may collect or obtain your personal information from third parties who provide it to us (e.g. banks and merchants we work with to provide our service to you). </p>

  <p> <b>3.2 Sensitive personal information:</b> We will not collect sensitive personal information about you, such as information about your religion, ethnicity, health or biometrics. </p>

  <h5> 4. How we use information about you </h5>

  <i class="d-inline-block mb-3">
    We use information we collect and create to provide a service to you. This section details which
    services and mediums require or are involved in the processing of your personal information.
  </i>

  <p> We may use personal information about you for the following purposes: </p>

  <p><b>4.1 Providing services to you, such as:</b></p>

  <ul>
    <li> To generate Slyp Smart Receipts and display them in your online banking applications; </li>
    <li> To generate Slyp Smart Receipts, host and display them online and provide you with a unique link to access them; </li>
    <li> To enable merchants to deliver relevant, in-context offers displayed on smart receipts; </li>
    <li> To provide merchants with rating and feedback information you have willingly supplied; </li>
    <li> To enable you to use services associated with the Smart Receipt; </li>
    <li> To provide merchants with analytics products and insights using anonymized consumer behaviour data; </li>
    <li> To provide customer support to you; </li>
    <li> To continue to improve and develop our services, including by developing new services we could offer to you. </li>
  </ul>


  <p><b>4.2 Offering our sites to you: </b>We may collect information about the device you use to access our sites, which may include your IP address, operating system and browser type. This helps us to manage our sites and improve the quality of our service.</p>

  <p><b>4.3 Communicating with you, in the context of:</b></p>

  <ul>
    <li> Service updates and news about our services; </li>
    <li> News items and other information in which you may be interested; </li>
    <li> Maintaining and updating your contact information where appropriate; </li>
    <li> Obtaining your prior, opt-in consent; </li>
    <li> Conducting surveys; </li>
    <li> IT operations management of our communications systems, our IT security systems, and/or IT security audits. </li>
  </ul>

  <p> Of course, we will always ensure that such communications are provided to you in compliance with applicable laws. </p>

  <p> <b>4.4 Business partner management:</b> If you are our merchant or business partner, we may process information relating to sales, finance, billing, corporate audit, and vendor/supplier management (including contractors). </p>
  <p> <b>4.5 Security:</b> We may use your information to help us maintain electronic security of our services (such as login records and access details). </p>
  <p> <b>4.6 Legal compliance:</b> we may process information about you to comply with our legal and regulatory obligations under applicable law. </p>
  <p> <b>4.7 Legal proceedings:</b> We may process information about you to establish, exercise and defend legal rights. </p>

  <h5> 5. Disclosure of your information </h5>

  <i class="d-inline-block mb-3">The provision of our service to you may require us to share your personal information with other parties. This section details which third parties we may need to disclose your personal information to, including those outside Australia.</i>

  <p> <b>5.1 Disclosure of information to third parties:</b> We may disclose your personal information to other entities for legitimate business purposes and in accordance with applicable law. These include: </p>

  <ul>
    <li> Your bank, which may receive the information about your purchases which are to be displayed in the Smart Receipt; </li>
    <li> Merchants that you shop with may receive de-identified demographic information about you; </li>
    <li> Third party service providers to whom we may outsource certain organisational functions e.g. information technology support, direct marketing etc., may need to process or store your information, subject to binding contractual obligations of confidentiality; </li>
    <li> Accountants, auditors, lawyers and other outside professional advisors we employ may also require access to your information, subject to binding contractual obligations of confidentiality; </li>
    <li> Third party acquirer(s) may also require access to your information, in the event that we sell or transfer all or any relevant portion of our business or assets. </li>
  </ul>

  <p> <b>5.2 International transfer of personal information:</b> Some of the third parties to whom we disclose your personal information (as set out above) may be located outside of Australia (including the in USA). If we disclose your personal information to a third party located outside of Australia, we will take reasonable steps to ensure that the recipient complies with the Australian Privacy Act in respect of that information. Nonetheless, other jurisdictions may have different privacy laws and data protection compliance requirements to those that apply in Australia. </p>

  <h5> 6. Keeping your data secure and maintaining accuracy of your data </h5>

  <i class="d-inline-block mb-3">Protecting your privacy and maintaining the accuracy of the data we use to serve you is vitally important to us. This section details the measures we take to achieve this.</i>

  <p> <b>6.1 Securing Data:</b> We have implemented appropriate technical and organisational security measures designed to protect your personal information against unauthorised or unlawful destruction, loss, alteration, disclosure, and other unlawful or unauthorised forms of processing. A selection of these measures are listed below: </p>

  <ul>
    <li> Staff education: We train and remind our staff of their obligations with regard to your personal information and data. </li>
    <li> System security: When you transact with us on the internet via our website, mobile apps and APIs we encrypt data sent from your computer to our systems. </li>
    <li> Building security: We have protection in our buildings against unauthorised access such as alarms and cameras. </li>
    <li> Disposal of data which is no longer required: We have defined policies to ensure that we only keep personal information for as long as required (for example, to meet user, legal requirements or our internal needs). </li>
  </ul>

  <p> <b>6.2 Maintaining Data Accuracy:</b> On an ongoing basis, we take every reasonable step to ensure that: </p>
  <ul>
    <li> Your personal information is accurate and has been kept up to date (where necessary); </li>
    <li> Any of your personal information that we process that is inaccurate (having regard to the purposes for which it is processed) is erased or rectified if we become aware of the inaccuracy. </li>
  </ul>

  <p> From time to time we may ask you to confirm the accuracy of your personal information. </p>

  <h5> 7. Cookies and similar technologies </h5>

  <i class="d-inline-block mb-3">We use cookies to provide our service to you. You can opt out, though this may impact our ability to provide our service to you.</i>

  <p> <b>7.1 How we use cookies:</b> When you visit our site, we may place cookies onto your device, or read cookies already on your device, subject always to obtaining your consent, where required. We use cookies to record information about your device, your browser and, in some cases, your preferences and browsing habits. We may process your personal information through cookies. </p>
  <p> <b>7.2 How you can stop cookies:</b> Your browser should give you the ability to control cookies. How you do so, depends on your browser and the type of cookie. Certain browsers can be set to reject all browser cookies. If you configure your computer to block all cookies, you may disrupt certain web page features, and limit the functionality we can provide when you visit or use our sites. If you continue without changing your settings, we will assume that you are happy to receive all cookies on this site. You can change your cookie settings at any time. </p>

  <h5> 8. Accessing, updating and correcting your personal information </h5>

  <i class="d-inline-block mb-3">We will help you to access, update and correct your personal information.</i>

  <p> <b>8.1 Accessing your data:</b> You can ask for access to your personal information (for example what transactions you’ve made) by contacting us. We try to make your information available within 30 days of your request. Before we give you the information, we’ll need to confirm your identity. </p>
  <p> <b>8.2 Denying or limiting your request for access:</b> In certain circumstances and where permitted by law, we may need to deny your request, or limit the access we provide. For example, we might not provide access to commercially sensitive information. Whatever the outcome, we’ll write to you explaining our decision. </p>
  <p> <b>8.3 Correcting your personal information:</b> You can ask us to correct any inaccurate personal information we hold or have provided to others by contacting us. If the personal information that is corrected is personal information we have provided to others, you can ask us to notify them of the correction. If we’re able to correct your personal information, we’ll inform you when the process is complete. </p>
  <p> <b>8.4 Disagreement on personal information correction required:</b> If we disagree with you that personal information should be corrected, we’ll let you know our reasons in writing. You can then respond to further indicate your view that the personal information is inaccurate, misleading, incomplete, irrelevant or out-of-date. We will take reasonable steps to comply with your request. </p>

  <h5> 9. Contact us or find out more </h5>

  <p> <b>9.1 Contacting us:</b> If you have any questions regarding this privacy policy or our treatment of your personal information, or complaints, or if you would like to access or amend your personal information, please contact us at <a class="sds-btn-any" [href]="'mailto:'+infoMail">{{ infoMail }}</a> . </p>
  <p> <b>9.2 Complaints:</b> If you contact us to raise a complaint, we will consider your complaint promptly and contact you to seek to resolve the matter. We shall assign a reference number and we will keep you updated on the progress we’re making towards fixing the problem. If we’re unable to provide a final response within 45 days, we’ll contact you to explain why and discuss a timeframe to resolve the complaint. </p>
  <p> <b>9.3 Office of the Australian Information Commissioner:</b> Under the Privacy Act, you are also entitled to make a complaint to the Australian Privacy Commissioner if you have concerns about how we handle your personal information. For more information about the Australian Privacy Principles, or if we are unable to satisfactorily resolve your concerns about our handling of your personal information, you can contact the Office of the Australian Information Commissioner: </p>

  <p> GPO Box 5218, Sydney NSW 2001 </p>
  <p> Email: <a class="sds-btn-any" href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a> </p>
  <p> Tel: 1300 363 992 </p>
  <p> <a class="sds-btn-any" href="www.oaic.gov.au">www.oaic.gov.au</a> </p>


  <h4> Appendix 1: Definitions </h4>

  <table class="table-bordered">
    <tr>
      <th>Term</th>
      <th>Definition</th>
    </tr>
    <tr>
      <td>
        Personal Information
      </td>
      <td>
        <P> Any information or opinion about an identified individual, or an individual who is reasonably identifiable: </P>
        <ul>
          <li> whether the information or opinion is true or not; or </li>
          <li> whether the information or opinion is recorded in a material form or not.</li>
        </ul>
      </td>
    </tr>
    <tr>
      <td>
        “Process”, “Processing” or “Processed”
      </td>
      <td>
        Anything that is done with any Personal Information, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
      </td>
    </tr>
    <tr>
      <td>
        Site
      </td>
      <td>
        <p> Any related web pages located under the following domain names: </p>
        <ul>
          <li> slyp.com.au </li>
          <li> my.slyp.com.au </li>
          <li> api.slyp.com.au </li>
          <li> auth.api.slyp.com.au • receipts.slyp.com.au </li>
        </ul>
        <p> and any integration with third party systems or websites which provide data to us through the web pages listed above. </p>
      </td>
    </tr>
    <tr>
      <td>
        Cookie
      </td>
      <td>
        A small file that is placed on your device when you visit a website (including our Sites).
      </td>
    </tr>
  </table>

</div>
