import { LocalStorageService } from '@services/local-storage.service';
import { Injectable } from '@angular/core';
import * as AppActions from '@app/store/app.actions';
import * as fromAuth from '@auth/store/auth.reducer';
import { environment } from '@environments/environment';
import { IOktaIDToken, IOktaSession, IOktaErrorResponse } from '@interfaces/auth.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as RoutingActions from '@routing/store/routing.actions';
import { SessionService } from '@services/session.service';
import * as SigninActions from '@signin/store/signin.actions';
import * as SigninSelectors from '@signin/store/signin.selectors';
import * as SignupActions from '@signup/store/signup.actions';
import { NotifierService } from 'angular-notifier';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { UserRoleType } from '@app/core/interfaces/role.interface';


@Injectable()
export class SigninEffects {

  setDummySession$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.setDummySession),
    map(() => RoutingActions.authenticatedUserRouteDecisionNeeded())
  ));

  retrieveSavedOktaSession$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.retrieveSavedOktaSession),
    mergeMap(() => from(this.session.oktaGetCachedIdToken()).pipe(
      map((cookieIdToken: IOktaIDToken) => !!cookieIdToken ?
        SigninActions.renewOktaIdToken({ cookieIdToken }) :
        SigninActions.clearOktaSession()
      ),
      catchError((response: IOktaErrorResponse) => of(SigninActions.handleAuthFailure({ msg: response.errorSummary })))
    )),
  ));

  renewOktaIdToken$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.renewOktaIdToken),
    mergeMap(({ cookieIdToken }) => this.session.oktaRenewIdToken(cookieIdToken).pipe(
      map(oktaIdToken => SigninActions.setOktaIDToken({ oktaIdToken })),
      catchError((response: IOktaErrorResponse) => of(SigninActions.handleAuthFailure({
        msg: response.errorCode === 'login_required' ? 'Session expired, sign in required.' : response.errorSummary
      })))
    )),
  ));

  signIn$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.signIn),
    mergeMap(({ email, password }) => [
      SigninActions.signInWithOkta({ email, password }),
      SignupActions.resetSignup() // if signup is not at initialState, prevent routing from detecting it when user has successfully authenticated
    ])
  ));

  signInWithOkta$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.signInWithOkta),
    mergeMap(({ email, password }) => this.session.oktaSignIn(email, password).pipe(
      map((session: IOktaSession) => SigninActions.fetchOktaIDTokenForSession({ session })),
      catchError((response: IOktaErrorResponse) => of(SigninActions.handleAuthFailure({
        msg: response.errorCode === 'login_required' ? 'Wrong credentials or session expired' : response.errorSummary
      })))
    )),
  ));

  fetchOktaIDTokenForSession$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.fetchOktaIDTokenForSession),
    mergeMap(({ session }) => this.session.oktaFetchIDTokenForSession(session.sessionToken).pipe(
      map((oktaIdToken: IOktaIDToken) => SigninActions.setOktaIDToken({ oktaIdToken })),
      catchError((response: IOktaErrorResponse) => of(SigninActions.handleAuthFailure({
        msg: response.errorCode === 'login_required' ? 'Session expired, sign in required.' : response.errorSummary
      })))
    )),
  ));

  setOktaIDToken$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.setOktaIDToken),
    switchMap(({ oktaIdToken }) => this.session.setLocalOktaSession(oktaIdToken)),
    map(() => environment.demo.isActive ? SigninActions.authorizeWithSlypApiUsingTestCredentials() : SigninActions.authorizeWithSlypApi())
  ));

  authorizeWithSlypApi$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.authorizeWithSlypApi),
    withLatestFrom(this.rxStore.select(SigninSelectors.selectOktaIdToken)),
    mergeMap(([_, oktaIdToken]) => this.session.authorizeWithSlypAPI(oktaIdToken.idToken).pipe(
      map(slypToken => SigninActions.setSlypToken({ slypToken: slypToken })),
      catchError(response => of(SigninActions.handleAuthFailure({ msg: response.error.msg })))
    )),
  ));

  authorizeWithSlypApiUsingTestCredentials$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.authorizeWithSlypApiUsingTestCredentials),
    map(() => environment.demo),
    mergeMap(({ apiKey, merchantId }) => this.session.authorizeWithSlypApiUsingTestCredentials(apiKey, merchantId).pipe(
      tap(() => this.notifier.show({
        type: 'info',
        message: `[AUTH][DEBUG] Accessing data of merchant ${merchantId} for the purpose of ${environment.demo.purpose}`
      }
      )),
      map(({ jwt_token }) => SigninActions.setSlypToken({ slypToken: { merchantId, userId: 'TEST_USER', accessToken: jwt_token, userRole: UserRoleType.merchantDefault } })),
      catchError(response => of(SigninActions.handleAuthFailure({ msg: response.error.msg })))
    )),
  ));

  setSlypToken$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.setSlypToken),
    withLatestFrom(this.rxStore.select(SigninSelectors.selectGivenName)),
    tap(([_, userName]) => this.notifier.show({ type: 'success', message: `Welcome back ${userName}!` })),
    tap(([{ slypToken }]) => this.localStorage.initMerchantId(slypToken.merchantId)),
    map(() => RoutingActions.authenticatedUserRouteDecisionNeeded())
  ));

  handleAuthFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.handleAuthFailure),
    mergeMap(({ msg }) => [
      AppActions.notifyError({ errorTrigger: 'authenticating', errorMsg: msg }),
      SigninActions.clearOktaSession()
    ])
  ));

  signOut$ = createEffect(() => this.actions$.pipe(
    ofType(
      SigninActions.signOutOnboarding,
      SigninActions.signOutSidebar,
      SigninActions.signOutUserProfile,
      SigninActions.signOutGeneral,
    ),
    map(() => RoutingActions.routeToSignOutV2()),
  ));


  closeOktaSession$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.closeOktaSession),
    tap(() => this.session.clearLocalOktaSession()),
    tap(() => this.localStorage.resetMerchantId()),
    mergeMap(() => [
      AppActions.signalReset(),
      RoutingActions.decideSignedOutUserRoute()
    ])
  ))

  signOutOkta$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.signOutOkta),
    tap(() => from(this.session.oktaSignOut())),
    tap(() => this.notifier.show({ type: 'success', message: 'Signed out successfully.' })),
    map(() => SigninActions.closeOktaSession()),
  ))

  clearOktaSession$ = createEffect(() => this.actions$.pipe(
    ofType(SigninActions.clearOktaSession),
    mergeMap(() => this.session.oktaIsAuthenticated()),
    mergeMap((isCookiePresent) => (isCookiePresent ? from(this.session.oktaSignOut()) :
      of(null)).pipe(
        tap(() => isCookiePresent && this.notifier.show({ type: 'success', message: 'Signed out successfully.' })),
        map(() => null),
        catchError(() => of(null))
      )),
    map(() => SigninActions.closeOktaSession()),
  ));

  constructor(
    private actions$: Actions,
    private rxStore: Store<fromAuth.AppState>,
    private notifier: NotifierService,
    private session: SessionService,
    private localStorage: LocalStorageService
  ) { }

}
