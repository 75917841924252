import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as AppActions from '@app/store/app.actions';
import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import * as fromAuth from '@auth/store/auth.reducer';
import { DIALOG_COMPONENT_WIDTH_WIDE } from '@configs/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as RoutingActions from '@routing/store/routing.actions';
import { SignupService } from '@services/signup.service';
import * as SigninActions from '@signin/store/signin.actions';
import { PrivacyPolicyComponent } from '@signup/privacy-policy/privacy-policy.component';
import * as SignupActions from '@signup/store/signup.actions';
import * as SignupSelectors from '@signup/store/signup.selectors';
import { ErrorsUtils } from '@utils/errors';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ContentfulAdapterService } from '@app/core/services/adapters/contentful-adapter.service';


@Injectable()
export class SignupEffects {

  privacyPolicyLinkClicked$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.privacyPolicyLinkClicked),
    tap(() => this.dialogs.open(PrivacyPolicyComponent, {
      width: DIALOG_COMPONENT_WIDTH_WIDE + 'px',
      autoFocus: false,
      panelClass: ['card-dialog']
    }))
  ), {dispatch: false});

  oktaAccountDetailsSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.oktaAccountDetailsSubmitted),
    map(({first, last, email}) => SignupActions.createOktaAccount({first, last, email}))
  ));

  createOktaAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.createOktaAccount),
    mergeMap(({first, last, email}) => this.contentfulAdapter.fetchLatestTermsAndConditionsVersion().pipe(
      mergeMap(termsAndConditionsVersion => this.signup.signUpWithOkta(first, last, email, termsAndConditionsVersion).pipe(
        map(() => SignupActions.createOktaAccountSuccess()),
        catchError(response => of(SignupActions.createOktaAccountFailure({errorMsg: ErrorsUtils.getMsg({response})})))
      )),
      catchError(response => of(SignupActions.createOktaAccountFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    )),
  ));

  createOktaAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.createOktaAccountSuccess),
    map(() => SignupActions.setStepVerification())
  ));

  createOktaAccountFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.createOktaAccountFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'creating account', errorMsg}))
  ));

  pickUpSignupAtEmailVerification$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.pickUpSignupAtEmailVerification),
    map(({email, secret}) => ({
      email: email.replace(/ /g, '+'), // handle special case when email can be like name+tag@domain
      secret
    })),
    map(({email, secret}) => SignupActions.decodeOktaVerificationUri(({email, secret})))
  ));

  pickUpSignupAtPasswordRecovery$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.pickUpSignupAtPasswordRecovery),
    map(({email, secret}) => ({
      email: email.replace(/ /g, '+'), // handle special case when email can be like name+tag@domain
      secret
    })),
    map(({email, secret}) => SignupActions.decodeOktaRecoveryUri(({email, secret})))
  ));

  pickUpSignupAtComplete$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.pickUpSignupAtComplete),
    map(() => SignupActions.setStepComplete())
  ));

  pickUpSignupAtPasswordRecoveryComplete$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.pickUpSignupAtPasswordRecovered),
    map(() => SignupActions.setStepPasswordRecovered())
  ));

  decodeOktaVerificationUri$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.decodeOktaVerificationUri),
    map(() => SignupActions.setStepPasswordCreate())
  ));

  decodeOktaRecoveryUri$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.decodeOktaRecoveryUri),
    map(() => SignupActions.setStepPasswordRecover())
  ));

  passwordSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.passwordSubmitted),
    map(({password, isPasswordRecovery}) => isPasswordRecovery
      ? SignupActions.recoverPassword({password})
      : SignupActions.activateAccount({password})
    )
  ));

  activateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.activateAccount),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectAccountEmail)),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectOktaVerificationSecret)),
    mergeMap(([[{password}, email], secret]) => this.signup.activateWithOkta(email, password, secret).pipe(
      map(() => SignupActions.activateAccountSuccess({email, password})),
      catchError(response => of(SignupActions.activateAccountFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    )),
  ));

  activateAccountSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.activateAccountSuccess),
    map(({email, password}) => SigninActions.signInWithOkta({email, password}))
  ));

  activateAccountFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.activateAccountFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'activating account', errorMsg}))
  ));

  recoverPassword$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.recoverPassword),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectAccountEmail)),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectOktaRecoverySecret)),
    mergeMap(([[{password}, email], secret]) => this.signup.recoverPassword(email, password, secret).pipe(
      map(() => SignupActions.recoverPasswordSuccess({email, password})),
      catchError(response => of(SignupActions.recoverPasswordFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    ))
  ));

  recoverPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.recoverPasswordSuccess),
    map(({email, password}) => SigninActions.signInWithOkta({email, password}))
  ));

  recoverPasswordFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.recoverPasswordFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'recovering password', errorMsg}))
  ));

  signupProcessFinished$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.signupProcessFinished),
    mergeMap(() => [
      RoutingActions.decideSignedUpUserRoute(),
      SignupActions.resetSignup()
    ])
  ));

  passwordRecoveryProcessFinished$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.passwordRecoveryProcessFinished),
    mergeMap(() => [
      RoutingActions.authenticatedUserRouteDecisionNeeded(),
      SignupActions.resetSignup()
    ])
  ));

  resendActivationEmailClicked$ = createEffect(() => this.actions$.pipe(
    ofType(SignupActions.resendActivationEmailClicked),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectAccountEmail)),
    map(([_, email]) => ResendActivationEmailActions.activationEmailResendRequested({email}))
  ));

  constructor (
    private actions$: Actions,
    private rxStore: Store<fromAuth.AppState>,
    private dialogs: MatDialog,
    private signup: SignupService,
    private contentfulAdapter: ContentfulAdapterService
  ) {}

}
