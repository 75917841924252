export class DragDropUtils {

  private static getTopScrollArea(viewPortEl) {
    return {
      left: 0,
      right: viewPortEl.innerWidth,
      top: viewPortEl.scrollY,
      bottom: viewPortEl.scrollY + 100
    };
  }

  private static getBottomScrollArea(viewPortEl) {
    return {
      left: 0,
      right: viewPortEl.innerWidth,
      top: viewPortEl.innerHeight + viewPortEl.scrollY - 100,
      bottom: viewPortEl.innerHeight + viewPortEl.scrollY
    };
  }

  static getScrollTargetForDrag(dragPosition: {x: number, y: number}, scrollingElement: HTMLElement, container = window): number { // return scroll difference

    // return null if container does not scroll
    if (container.innerHeight >= scrollingElement.scrollHeight) {
      return null;
    }

    // decide which scroll area user has dragged item into
    const direction = this.isPointInsideOf(dragPosition, this.getTopScrollArea(container)) ?
      'top' :
      this.isPointInsideOf(dragPosition, this.getBottomScrollArea(container)) ?
      'bottom' :
      null;

    // if user has dragged item into either of scroll target areas
    if (direction) {
      // calc target scrollY
      let target = scrollingElement.scrollTop + (direction === 'bottom' ? 250 : -250);

      const maxScrollTop = scrollingElement.scrollHeight - container.innerHeight;
      const isLesserThanMinScrollTop = direction === 'top' && target < 0;
      const isGreaterThanMaxScrollTop = direction === 'bottom' && target > maxScrollTop;

      // ensure that target scrollY is not outside scrolling element boundries
      target = isLesserThanMinScrollTop ?
        0 :
        isGreaterThanMaxScrollTop ?
        maxScrollTop :
        target;

      return target;
    }

    return null;
  }

  static getSmallestAreaOrthogonalToContainedZAxis(point: {x: number, y: number}, areas: HTMLElement[]): HTMLElement {

    const sortedApplicableAreas = areas
      .map(el => ({el, rect: el.getBoundingClientRect()})) // annotate rects
      .filter(({el, rect}) => this.isPointInsideOf(point, rect)) // filter out not applicable
      .map(({el, rect}) => ({el, area: rect.width * rect.height})) // annotate areas
      .sort((prev, next) => prev.area - next.area); // sort asc

    const smallestApplicableArea = sortedApplicableAreas[0];

    return smallestApplicableArea ? smallestApplicableArea.el : null;
  }

  static findDropTargetContaingPoint(point: {x: number, y: number}, dropTargets: {id: string, el: HTMLElement}[]): {id: string, el: HTMLElement} {

    const applicableTarget = dropTargets
      .map(({el, id}) => ({id, el, rect: el.getBoundingClientRect()}))
      .find(({el, rect}) => this.isPointInsideOf(point, rect))

    return applicableTarget || null;
  }

  static isPointInsideOf(position: {x: number, y: number}, rect: DOMRect | ClientRect | {top: number, left: number, right: number, bottom: number}) {
    return position.x >= rect.left &&
           position.x <= rect.right &&
           position.y >= rect.top &&
           position.y <= rect.bottom;
  }

}
