import { IPOSProviderOption, ICloudPOSProviderName, IPOSProviderProcess, IPOSSyncItemName } from '@interfaces/pos.interface';

export const POS_PROVIDER_OPTIONS: IPOSProviderOption[] = [
  {label: 'Hike', value: 'hike', process: 'redirect'},
  {label: 'Lightspeed', value: 'kounta', process: 'redirect', searchProp: 'kounta'},
  {label: 'Magenta', value: 'magenta', process: 'legacy'},
  {label: 'Abacus', value: 'abacus', process: 'legacy'},
  {label: 'Pronto', value: 'pronto', process: 'legacy'},
  {label: 'Sympac', value: 'sympac', process: 'legacy'},
  {label: 'Gap Solutions', value: 'gap_solutions', process: 'legacy'},
  {label: 'Idealpos', value: 'idealpos', process: 'legacy'},
  {label: 'Advance Retail', value: 'advance_retail', process: 'legacy'},
  {label: 'Apparel21', value: 'apparel21', process: 'legacy'},
  {label: 'Future Business Systems', value: 'future_business_systems', process: 'legacy'},
  {label: 'H&L', value: 'hnl', process: 'legacy'},
  {label: 'Imagatec', value: 'imagatec', process: 'legacy'},
  {label: 'Infinity RMS', value: 'infinity_rms', process: 'legacy'},
  {label: 'Island Pacific', value: 'island_pacific', process: 'legacy'},
  {label: 'Starrtec', value: 'starrtec', process: 'legacy'},
  {label: 'Swiftpos', value: 'swiftpos', process: 'legacy'},
  {label: 'Futura 4 Retail', value: 'futura4retail', process: 'legacy'},
  {label: 'Surefire', value: 'surefire', process: 'legacy'},
  {label: 'WorldSmart', value: 'worldsmart', process: 'legacy'},
  {label: 'Sterland', value: 'sterland', process: 'legacy'},
  {label: 'Canary', value: 'canary', process: 'legacy'},
  {label: 'FutureNet', value: 'futurenet', process: 'legacy'},
  {label: 'Impos', value: 'impos', process: 'legacy'},
  {label: 'Tower', value: 'tower', process: 'legacy'},
  {label: 'Roqqio', value: 'roqqio', process: 'legacy'},
  {label: 'Jensen', value: 'jensen', process: 'legacy'},
  {label: 'Striven', value: 'striven', process: 'legacy'},
  {label: 'TCS', value: 'tcs', process: 'legacy'},
  {label: 'Custom', value: 'custom', process: 'legacy'},

  // --- decommissioned until further notice
  // {label: 'Sterland', value: 'sterland', process: 'legacy'},
  // {label: 'Erply', value: 'erply', process: 'credentials'},
];

export const POS_PROVIDER_LOGOS_PATH = '/assets/images/pos/';


export const CLOUD_POS_PROVIDERS: ICloudPOSProviderName[] = [
  // 'erply', // decommissioned until further notice
  'kounta',
  'hike'
];

export const POS_SYNC_ITEM_NAMES: IPOSSyncItemName[] = [
  'merchant',
  'stores',
  'products',
  'webhooks',
];

export const POS_SYNC_ITEM_LABELS: {[key in IPOSSyncItemName]: string} = {
  merchant: 'Merchant details',
  stores: 'Store details',
  products: 'Product details',
  webhooks: 'Webhooks',
};

export const POS_PROVIDER_RESYNC_PROCESS_ELABORATIONS: {[process in IPOSProviderProcess]?: (providerName: string) => string} = {
  redirect: (providerName: string) => `You will be redirected to ${providerName} to grant proper access.`,
  credentials: (providerName: string) => `You will be prompted for ${providerName} credentials. We will resync your data automatically.`,
  legacy: (providerName: string) => `MySlyp doesn't support direct sync with ${providerName}.<br/>Organisations, Stores and API keys data need to be updated manually.`,
};

export const POS_SYNC_STATUS_POLL_INTERVAL = 4000;
