import { Directive, HostListener } from '@angular/core';

interface IComponentCanDeactivateConfig {
  isPartialForDeactivationDecision: boolean;
}

const DEFAULT_DEACTIVATION_PROTECTED_COMPONENT_CONFIG = {
  isPartialForDeactivationDecision: false,
};

@Directive() // need this because of Angular 10 requirement when using Angular decorators on properties
export abstract class ComponentCanDeactivate {

  constructor(private canDeactivateConfig: IComponentCanDeactivateConfig = DEFAULT_DEACTIVATION_PROTECTED_COMPONENT_CONFIG) {}

  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivateConfig.isPartialForDeactivationDecision && this.canDeactivate()) {
      $event.returnValue = true;
    }
  }
}
