import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, union, props } from '@ngrx/store';

export const forgotPasswordClicked = createAction(
  '[ Auth/ForgotPassword ][ SigninComponent ] FORGOT_PASSWORD_CLICKED',
);

export const usernameSubmitted = createAction(
  '[ Auth/ForgotPassword ][ ForgotPasswordComponent ] USERNAME_SUBMITTED',
  props<{username: string}>()
);

export const requestForgotPasswordEmail = createAction(
  '[ Auth/ForgotPassword ][ ForgotPasswordComponent ] REQUEST_FORGOT_PASSWORD_EMAIL',
  props<{username: string}>()
);

export const requestForgotPasswordEmailSuccess = createAction(
  '[ Auth/ForgotPassword ][ REQUEST_FORGOT_PASSWORD_EMAIL Effect ] REQUEST_FORGOT_PASSWORD_EMAIL_SUCCESS',
);

export const requestForgotPasswordEmailFailure = createAction(
  '[ Auth/ForgotPassword ][ REQUEST_FORGOT_PASSWORD_EMAIL Effect ] REQUEST_FORGOT_PASSWORD_EMAIL_FAILURE',
  props<{errorMsg: string}>()
);


export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

const all = union({
  forgotPasswordClicked,
  usernameSubmitted,
  requestForgotPasswordEmail,
  requestForgotPasswordEmailSuccess,
  requestForgotPasswordEmailFailure,
  receiveReset,
});
export type Union = typeof all;
