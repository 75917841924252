import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';

import { NotifierModule } from 'angular-notifier';
import { notifierOptions } from '@configs/notifier';

import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';

import { DimmerModule } from '@components/dimmer/dimmer.module';
import { SplashModule } from '@components/splash/splash.module';

const componentModules = [
  DimmerModule,
  SplashModule,
];

import { TooltipModule } from 'ngx-bootstrap/tooltip';

const bootstrapModules = [
  TooltipModule.forRoot()
];

// --- components
import { AppComponent } from '@app/app.component';
import { NotFoundComponent } from '@app/not-found/not-found.component';

// --- ngrx
import * as fromApp from '@app/store/app.reducer';
import { UIEffects } from '@ui/store/ui.effects';
import { AppEffects } from '@app/store/app.effects';
import { OptionsEffects } from '@options/store/options.effects';

const ngrxEffects = [
  AppEffects,
  UIEffects,
  OptionsEffects,
];

const ngrxModules = [
  StoreModule.forRoot(fromApp.reducerToken),
  StoreRouterConnectingModule.forRoot(),
  EffectsModule.forRoot(ngrxEffects),
];

import { UIFacade } from '@ui/store/ui.facade';
import { OptionsFacade } from '@options/store/options.facade';

const ngrxFacades = [
  UIFacade,
  OptionsFacade
];

import { DataTypesAdapterService } from '@adapters/data-types-adapter.service';
import { MockAdapterService } from '@adapters/mock-adapter.service';

const adapters = [
  MockAdapterService,
  DataTypesAdapterService,
];

import { DEVTOOLS_OPTIONS } from '@configs/store-devtools';

if (environment.ngrxDevTools) {
  ngrxModules.push(StoreDevtoolsModule.instrument(DEVTOOLS_OPTIONS));
}

import { LocalStorageService } from '@services/local-storage.service';
import { IconRegistryService } from '@services/icon-registry.service';

// --- modules
import { AuthModule } from '@auth/auth.module';
import { AppRoutingModule } from '@app/app-routing.module';

import { TokenInterceptor } from '@interceptors/token.interceptor';
import { ApiInterceptor } from '@interceptors/api.interceptor';

import { SentryModule } from '@core/sentry/sentry.module';
const sentryModules = [];

if (environment.isSentryConfigured) {
  sentryModules.push(SentryModule.forRoot({dsn: environment.sentryDsn}));
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NotifierModule.withConfig(notifierOptions),
    // 1st party modules
    ngrxModules,
    componentModules,
    bootstrapModules,
    sentryModules,
    AuthModule,
    AppRoutingModule,
  ],
  providers: [
    adapters,
    fromApp.reducerProvider,
    ngrxFacades,
    LocalStorageService,
    IconRegistryService,
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
