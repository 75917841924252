import { ILuminanceMode } from '@interfaces/ui.interface';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-brand',
  host: {'brand': 'true'},
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandComponent {
  @Input() mode: ILuminanceMode = 'light';
}
