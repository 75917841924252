<div class="card-title sds-border-color-base-white">
  <div class="flex-grow-1"></div>
  <mat-icon class="sds-color-base-grey_medium-25W sds-btn-any no-appearance" mat-dialog-close (click)="onNo()" data-test="close">close</mat-icon>
</div>
<div class="card-body" #content>
  <div class="row no-gutters" [class.justify-content-center]="context.isCentered">
    <div class="col col-8">
      <h4 class="confirm-dialog__question"
        [class.sds-color-system-danger]="context.color === 'danger'"
        [class.text-center]="context.isCentered"
      >{{context.question}}</h4>
      <p class="sds-text-body-1 confirm-dialog__text d-block mt-4_5 mb-0" *ngIf="context.elaboration"
        [class.text-center]="context.isCentered"
        [innerHTML]="context.elaboration | safeHtml"
      ></p>
    </div>
  </div>
</div>
<div class="card-actions mt-3">
  <button class="sds-btn confirm-dialog__button--no mr-3" *ngIf="context.noAnswer" #noButton
    mat-dialog-close
    (click)="onNo()"
    data-test="noButton">
    {{context.noAnswer}}
  </button>
  <div class="flex-grow-1"></div>
  <button class="sds-btn primary emphasized confirm-dialog__button--yes" #yesButton
    [ngClass]="context.color"
    mat-dialog-close
    (click)="onYes()"
    data-test="yesButton">
    {{context.yesAnswer}}
  </button>
</div>
