import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: 'form[appBlockRedundantSubmit]'
})
export class BlockRedundantSubmitDirective {

  @Input('appBlockRedundantSubmit') isInProgress = false;
  @Output() validNgSubmit = new EventEmitter<any>();

  @HostListener('ngSubmit', ['$event'])
  onSubmit(ev) {
    if (this.isInProgress) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    } else {
      this.validNgSubmit.emit(ev);
    }
  }
}
