import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { IDToken, OktaAuth, Token } from '@okta/okta-auth-js';
import { environment } from '@environments/environment';
import { IOktaSession, IOktaIDToken, ISlypOktaAuthResponse, ISlypToken, IOktaTokenResponse } from '@interfaces/auth.interface';
import { UserRoleType } from '@interfaces/role.interface';

@Injectable()
export class SessionService {

  private okta = new OktaAuth({
    ...environment.okta,
    redirectUri: environment.okta.redirectUri.replace(/{ORIGIN}/, window.location.origin)
  });

  constructor(private http: HttpClient) {}

  oktaGetCachedIdToken(): Observable<IOktaIDToken> {
    return from(this.okta.tokenManager.getTokens())
      .pipe(map(({idToken}) => idToken as IOktaIDToken));
  }

  oktaIsAuthenticated(): Observable<boolean> {
    return this.oktaGetCachedIdToken().pipe(
      map(token => !(!token || !this.okta.tokenManager.hasExpired(token as Token)))
    );
  }

  oktaSignIn(email: string, password: string): Observable<IOktaSession> {
    return from(this.okta.signInWithCredentials({username: email, password})) as Observable<IOktaSession>;
  }

  oktaFetchIDTokenForSession(sessionToken: string): Observable<IOktaIDToken> {
    return (from(this.okta.token.getWithoutPrompt({responseType: 'id_token', scopes: ['openid', 'email', 'profile'], sessionToken})) as Observable<IOktaTokenResponse>)
      .pipe(map(response => response.tokens.idToken));
  }

  oktaRenewIdToken(idToken: IOktaIDToken): Observable<IOktaIDToken> {
    return from(this.okta.token.renew(idToken as Token)) as Observable<IOktaIDToken>;
  }

  oktaSignOut(): Observable<void> {
    return from(this.okta.closeSession()).pipe(map(() => null)) as Observable<void>;
  }

  clearLocalOktaSession() {
    this.okta.tokenManager.clear();
  }

  setLocalOktaSession(data: IOktaIDToken): Observable<void> {
    return new Observable(observer => {
      this.okta.authStateManager.subscribe(() => {
        observer.next();
        observer.complete();
      });
      this.okta.tokenManager.setTokens({idToken: data as IDToken});
    });
  }

  authorizeWithSlypAPI(idToken: string): Observable<ISlypToken> {
    const headers = new HttpHeaders({Authorization: `Bearer ${idToken}`});
    return this.http.post<ISlypOktaAuthResponse>('/v1/okta/authenticate', {}, {headers})
      .pipe(map(response => ({
        accessToken: response.pingdata_response.jwt_token,
        merchantId: response.pingdata_response.userRole.merchant_default || response.pingdata_response.userRole.store_ops,
        userRole: UserRoleType.storeOps in response.pingdata_response.userRole ? UserRoleType.storeOps : UserRoleType.merchantDefault,
        userId: response.user_id,
      })));
  }

  authorizeWithSlypApiUsingTestCredentials(apiKey: string, merchantId: string): Observable<{jwt_token: string}> {
    return this.http.post<{jwt_token: string}>('/v1/authenticate', {
      apiKey,
      requiredRole: {
        merchant_default: merchantId
      }
    });
  }

}
