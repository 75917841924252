import { animate, state, style, transition, trigger, query, stagger, keyframes } from '@angular/animations';


export class AppearAnimationsFactory {

  static fade(triggerSelector: string) {
    return trigger(triggerSelector, [
      state('void', style({opacity: '0'})),
      state('in',   style({opacity: '1'})),
      transition('void <=> *', animate('.2s ease')),
    ]);
  }

  static fadeUp(triggerSelector: string) {
    return trigger(triggerSelector, [
      state('void', style({
        transform: 'translateY(-1rem)',
        opacity: '0',
      })),
      state('in', style({
        transform: 'translateY(0px)',
        opacity: '1',
      })),
      transition('void <=> *', animate('.2s ease')),
    ]);
  }

  static fadeDown(triggerSelector: string) {
    return trigger(triggerSelector, [
      state('void', style({
        transform: 'translateY(1rem)',
        opacity: '0',
      })),
      state('in', style({
        transform: 'translateY(0px)',
        opacity: '1',
      })),
      transition('void <=> *', animate('.2s ease')),
    ]);
  }

  static fadeRight(triggerSelector: string) {
    return trigger(triggerSelector, [
      state('void', style({
        transform: 'translateX(1rem)',
        opacity: '0',
      })),
      state('in', style({
        transform: 'translateX(0px)',
        opacity: '1',
      })),
      transition('void <=> *', animate('.2s ease')),
    ]);
  }

  static fadeLeft(triggerSelector: string) {
    return trigger(triggerSelector, [
      state('void', style({
        transform: 'translateX(-1rem)',
        opacity: '0',
      })),
      state('in', style({
        transform: 'translateX(0px)',
        opacity: '1',
      })),
      transition('void <=> *', animate('.2s ease')),
    ]);
  }

  static fadeLeftStaggered(triggerSelector: string) {
    return trigger(triggerSelector, [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('.1s', [
          animate('.2s ease-in', keyframes([
            style({ opacity: 0, transform: 'translateX(-1rem)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1 }),
          ]))]), { optional: true }),

        query(':leave', stagger('.1s', [
          animate('.2s ease-out', keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 0, transform: 'translateX(-1rem)', offset: 1 }),
          ]))]), { optional: true })
      ]),
    ]);
  }

}
