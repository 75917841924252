import { createAction, union } from '@ngrx/store';
import { ONBOARDING_RESET_SIGNAL_TYPE } from '@onboarding/store/shared-action-types';
import * as CompanyInfoActions from '@onboarding/store/company-info/company-info.actions';
import * as OrganisationActions from '@onboarding/store/organisation/organisation.actions';
import * as PosActions from '@onboarding/store/pos/pos.actions';
import * as ProcessActions from '@onboarding/store/process/process.actions';
import * as ReceiptDesignActions from '@onboarding/store/receipt-design/receipt-design.actions';
import * as PosRequestActions from '@onboarding/store/pos-request/pos-request.actions';

export const signalResetOnboarding = createAction(
  ONBOARDING_RESET_SIGNAL_TYPE,
);

const all = union({
  signalResetOnboarding
});

export type Union
  = typeof all
  | CompanyInfoActions.Union
  | OrganisationActions.Union
  | PosActions.Union
  | ProcessActions.Union
  | ReceiptDesignActions.Union
  | PosRequestActions.Union
  ;
