import { ResendActivationEmailMountService } from '@auth/resend-activation-email/resend-activation-email-mount.service';
import { EmailsService } from '@services/emails.service';
import { ErrorsUtils } from '@utils/errors';
import * as AppActions from '@app/store/app.actions';
import { NotifierService } from 'angular-notifier';
import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import * as ResendActivationEmailSelectors from '@auth/resend-activation-email/store/resend-activation-email.selectors';
import * as fromAuth from '@auth/store/auth.reducer';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class ResendActivationEmailEffects {

  resendActivationEmailClicked$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.resendActivationEmailClicked),
    tap(() => this.resendActivationEmailMount.openDialog()),
  ), {dispatch: false});

  emailForActivationResendSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.emailForActivationResendSubmitted),
    map(({email}) => ResendActivationEmailActions.activationEmailResendRequested({email})),
  ));

  activationEmailResendRequested$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.activationEmailResendRequested),
    map(() => ResendActivationEmailActions.requestActivationEmailResend()),
  ));

  requestActivationEmailResend$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.requestActivationEmailResend),
    withLatestFrom(this.rxStore.select(ResendActivationEmailSelectors.selectEmail)),
    mergeMap(([_, email]) => this.emails.requestActivationEmailResend(email).pipe(
      map(() => ResendActivationEmailActions.requestActivationEmailResendSuccess()),
      catchError(response => of(ResendActivationEmailActions.requestActivationEmailResendFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    )),
  ));

  requestActivationEmailResendSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.requestActivationEmailResendSuccess),
    withLatestFrom(this.rxStore.select(ResendActivationEmailSelectors.selectEmail)),
    tap(([_, email]) => this.notifier.show({type: 'success', message: `Activation email successfully sent to ${email}.`})),
    map(() => ResendActivationEmailActions.reset())
  ));

  requestActivationEmailResendFailure$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.requestActivationEmailResendFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'requesting another activation email', errorMsg}))
  ));

  closeDialog$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationEmailActions.reset),
    tap(() => this.resendActivationEmailMount.closeDialog()),
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private rxStore: Store<fromAuth.AppState>,
    private notifier: NotifierService,
    private emails: EmailsService,
    private resendActivationEmailMount: ResendActivationEmailMountService
  ) {}

}
