import { Injectable } from '@angular/core';
const IS_SIDENAV_EXPANDED_ON_DESKTOP_LOCALSTORAGE_KEY = 'isSidenavExpandedOnDesktop';

@Injectable()
export class LocalStorageService {

  private merchantId: string;

  private getKey = (key: string) => `${this.merchantId}__${key}`;

  initMerchantId(merchantId: string) {
    this.merchantId = merchantId;
  }

  resetMerchantId() {
    this.merchantId = null;
  }

  setIsSidenavExpandedOnDesktop(isExpanded: boolean) {
    localStorage.setItem(
      this.getKey(IS_SIDENAV_EXPANDED_ON_DESKTOP_LOCALSTORAGE_KEY),
      JSON.stringify(isExpanded)
    );
  }

  getIsSidenavExpandedOnDesktop(): boolean {
    const key = this.getKey(IS_SIDENAV_EXPANDED_ON_DESKTOP_LOCALSTORAGE_KEY);
    return JSON.parse(localStorage.getItem(key));
  }

}
