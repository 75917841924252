import * as AppSelectors from '@app/store/app.selectors';
import { USER_PROFILE_ROUTE } from '@configs/routing';
import { createSelector } from '@ngrx/store';
import * as AuthSelectors from '@auth/store/auth.selectors';

export const selectState = createSelector(
  AuthSelectors.selectState,
  state => state.routing
);

export const selectIsDecidingRouteState = createSelector(
  selectState,
  state => state.isDecidingRoute
);

export const selectIsOnboardingInProgress = createSelector(
  selectState,
  state => state.isOnboardingInProgress
);

export const selectReachedDecidedRoute = createSelector(
  selectState,
  state => state.reachedDecidedRoute
);

export const selectSectionTitle = createSelector(
  selectState,
  state => state.sectionTitle
);

export const selectPageTitle = createSelector(
  selectState,
  ({sectionTitle, viewTitle}) => [sectionTitle, viewTitle]
    .filter(chunk => !!chunk)
    .join(' - ')
);

export const selectIsProfileRouteActive = createSelector(
  AppSelectors.selectUrl,
  url => url === USER_PROFILE_ROUTE.url
);
