import { ValidateOnBlurDirective } from '@directives/validate-on-blur/validate-on-blur.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ValidateOnBlurDirective],
  imports: [ CommonModule ],
  exports: [ValidateOnBlurDirective],
})
export class ValidateOnBlurModule {}
