import { NgControl } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appValidateOnBlur]',
})
export class ValidateOnBlurDirective {
  constructor(private formDirective: NgControl) {}

  @HostListener('focusout') onFocusout = () => this.update();
  @HostListener('blur') onBlur = () => this.update();

  private update() {
    if (this.formDirective.control) {
      this.formDirective.control.markAsTouched();
      this.formDirective.control.updateValueAndValidity();
    }
  }
}
