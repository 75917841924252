import { BlockRedundantSubmitDirective } from './block-redundant-submit.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [BlockRedundantSubmitDirective],
  imports: [ CommonModule ],
  exports: [BlockRedundantSubmitDirective],
})
export class BlockRedundantSubmitModule {}
