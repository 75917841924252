import { BlockRedundantClickDirective } from './block-redundant-click.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [BlockRedundantClickDirective],
  imports: [ CommonModule ],
  exports: [BlockRedundantClickDirective],
})
export class BlockRedundantClickModule {}
