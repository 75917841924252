import * as RoutingActions from '@routing/store/routing.actions';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { take, filter, switchMap, tap, map } from 'rxjs/operators';
import { AuthFacade } from '@auth/store/auth.facade';
import { SigninFacade } from '@signin/store/signin.facade';
import { RoutingFacade } from '@app/auth/routing/store/routing.facade';
import { combineLatest } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private rxAuth: AuthFacade,
    private rxRouting: RoutingFacade,
    private rxSignin: SigninFacade
  ) {}

  canActivate(): Promise<boolean> {
    return this.isAuthenticated();
  }

  canActivateChild(): Promise<boolean> {
    return this.isAuthenticated();
  }

  canLoad(): Promise<boolean> {
    return this.isAuthenticated();
  }

  // Wait until state changes to a point where authentication process has finished successfully or not
  // then, react only once to the applicable state emission and handle final state of isAuthenticated
  // state.isAuthenticating is switched to true by REAUTHORIZE action dispatched by AppComponent in OnInit - before guard is invoked
  private isAuthenticated(): Promise<boolean> {
    return this.rxAuth.isAuthLoaded$
      .pipe(
        filter(isLoaded => isLoaded),
        switchMap(() => combineLatest([
          this.rxSignin.state$,
          this.rxRouting.isDecidingRoute$
        ])),
        filter(([{isAuthenticating}, isDecidingRoute]) => !isAuthenticating && !isDecidingRoute),
        take(1),
        map(([{isAuthenticated}]) => isAuthenticated),
        tap(isAuthenticated => !isAuthenticated && this.rxRouting.dispatch(RoutingActions.decideUnauthorizedUserRoute()))
      )
      .toPromise(); // returning Observable breaks change detection for some reason - returning Promise works
  }

}
