import { IconRegistryService } from '@services/icon-registry.service';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, AfterContentInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router, NavigationEnd } from '@angular/router';
import { DimmerComponent } from '@components/dimmer/dimmer.component';
import * as RoutingActions from '@routing/store/routing.actions';
import * as UIActions from '@ui/store/ui.actions';
import { UIFacade } from '@ui/store/ui.facade';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, takeUntil, filter, withLatestFrom } from 'rxjs/operators';
import { RoutingFacade } from './auth/routing/store/routing.facade';
import { environment } from '@environments/environment';

declare const gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AppearAnimationsFactory.fade('fadeState')
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {

  reachedDecidedRoute$ = of(false);

  private overlayRef: OverlayRef;
  private overlayDimmerInstance: DimmerComponent;
  private resize$: Observable<void>;
  private destroy$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private overlay: Overlay,
    private iconRegistry: IconRegistryService,
    private rxRouting: RoutingFacade,
    private rxUI: UIFacade,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId, { 'page_path': event.urlAfterRedirects });
      }
    })
  }


  ngOnInit() {
    this.setupResizeListener();
    this.setupModuleLoadingIndicator();
    this.iconRegistry.registerIcons();
    this.rxRouting.dispatch(RoutingActions.decideUnathenticatedUserRoute());
    this.gtagSrc();
    this.gtagDataLayer();
  }

  ngAfterContentInit() {
    this.reachedDecidedRoute$ = this.rxRouting.reachedDecidedRoute$;
    this.ref.markForCheck();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupModuleLoadingIndicator() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.rxRouting.reachedDecidedRoute$),
        filter(([_, reachedDecidedRoute]) => !!reachedDecidedRoute)
      )
      .subscribe(([ev]) => {
        if (ev instanceof RouteConfigLoadStart) {
          this.showLoading();
        } else if (ev instanceof RouteConfigLoadEnd) {
          this.hideLoading();
        }
        this.ref.markForCheck();
      });
  }


  private gtagSrc() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id="' + environment.googleAnalyticsId + '"';
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
  private gtagDataLayer() {
    const script = document.createElement('script');
    script.innerHTML = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '" + environment.googleAnalyticsId + "');";
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
  private showLoading() {
    this.overlayRef = this.overlay.create({ panelClass: 'loading-overlay-panel' });
    this.overlayDimmerInstance = this.overlayRef.attach(new ComponentPortal(DimmerComponent)).instance;
    this.overlayDimmerInstance.isDimmed = true;
  }

  private hideLoading() {
    this.overlayRef.dispose();
    this.overlayRef = null;
    this.overlayDimmerInstance = null;
  }

  private setupResizeListener() {
    this.resize$ = new Observable<void>(observer => {
      window.onresize = () => observer.next();
    });

    this.resize$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(150),
      )
      .subscribe(() => this.rxUI.dispatch(UIActions.screenResized()));

    this.rxUI.dispatch(UIActions.screenResized());
  }
}
