import { IMerchantAttributes } from '@interfaces/merchant.interface';
import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { IOnboardingPhase } from '@interfaces/onboarding.interface';
import { IPOSRedirectProviderName, IPOSProviderName } from '@interfaces/pos.interface';
import { createAction, props, union } from '@ngrx/store';

// Event indicating that user has been authenticated
export const authenticatedUserRouteDecisionNeeded = createAction(
  '[ Auth/Routing ][ Auth/Signin/(SET_SLYP_TOKEN | SET_DUMMY_SESSION) | Auth/Sigunup/PASSWORD_RECOVERY_PROCESS_FINISHED Effect ] AUTHENTICATED_USER_ROUTE_DECISION_NEEDED'
);

export const fetchMerchantAttributes = createAction(
  '[ Auth/Routing ][ AUTHENTICATED_USER_ROUTE_DECISION_NEEDED Effect ] FETCH_MERCHANT_ATTRIBUTES'
);

export const fetchMerchantAttributesSuccess = createAction(
  '[ Auth/Routing ][ FETCH_MERCHANT_ATTRIBUTES Effect ] FETCH_MERCHANT_ATTRIBUTES_SUCCESS',
  props<{ attrs: IMerchantAttributes }>()
);

export const fetchMerchantAttributesFailure = createAction(
  '[ Auth/Routing ][ FETCH_MERCHANT_ATTRIBUTES Effect ] FETCH_MERCHANT_ATTRIBUTES_FAILURE',
  props<{ errorMsg: string }>()
);

export const decideAuthenticatedUserRoute = createAction(
  '[ Auth/Routing ][ FETCH_MERCHANT_ATTRIBUTES_SUCCESS Effect ] DECIDE_AUTHENTICATED_USER_ROUTE',
  props<{ attrs: IMerchantAttributes }>()
);

// Redirect user that has jsut finished onboarding
export const decideOnboardedUserRoute = createAction(
  '[ Auth/Routing ][ Onboarding/Process/FINISH_ONBOARDING Effect ] DECIDE_ONBOARDED_USER_ROUTE'
);

// Redirect unauthenticated user that may be the middle of onboarding process
export const decideUnathenticatedUserRoute = createAction(
  '[ Auth/Routing ][ AppComponent ] DECIDE_UNAUTHENTICATED_USER_ROUTE'
);

// Redirect authenticated user that has just finished sign up process
export const decideSignedUpUserRoute = createAction(
  '[ Auth/Routing ][ Auth/Signup/SIGNUP_PROCESS_FINISHED Effect ] DECIDE_SIGNED_UP_USER_ROUTE'
);

// Redirect user that has been signed out
export const decideSignedOutUserRoute = createAction(
  '[ Auth/Routing ][ Auth/Signin/SIGN_OUT_OKTA Effect ] DECIDE_SIGNED_OUT_USER_ROUTE'
);

// Recide route for user that tried to access a route for which he's not authorized
export const decideUnauthorizedUserRoute = createAction(
  '[ Auth/Routing ][ AUTH GUARD ] DECIDE_UNAUTHORIZED_USER_ROUTE'
);

// Navigate to signup, pick up signup at proper step
export const routeToSignupEmailVerification = createAction(
  '[ Auth/Routing ][ DECIDE_UNAUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_SIGNUP_EMAIL_VERIFICATION',
  props<{ email: string, secret: string }>()
);

// Navigate to signup, pick up signup at proper step
export const routeToSignupPasswordRecovery = createAction(
  '[ Auth/Routing ][ DECIDE_UNAUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_SIGNUP_PASSWORD_RECOVERY',
  props<{ email: string, secret: string }>()
);

// Navigate to signup, pick up signup at proper step
export const routeToSignupComplete = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_SIGNUP_COMPLETE',
);

// Navigate to signup, pick up signup at proper step
export const routeToPasswordRecovered = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_PASSWORD_RECOVERED',
);

// Navigate to onboarding, pick up at proper step
export const routeToOnboardingPosRedirect = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_ONBOARDING_POS_REDIRECT',
  props<{ code: string, posName: IPOSRedirectProviderName }>()
);

// Navigate to onboarding, pick up at proper step
export const routeToOnboarding = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE | DECIDE_SIGNED_UP_USER_ROUTE Effect ] ROUTE_TO_ONOBARDING',
  props<{ phase: IOnboardingPhase, posName: IPOSProviderName }>()
);

export const routeToOnboardingV2 = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE | DECIDE_SIGNED_UP_USER_ROUTE Effect ] ROUTE_TO_ONBOARDING_V2'
);

export const routeToOnboardingV2PosRedirectSync = createAction(
  '[ Auth/Routing ][ DECIDE_USER_ROUTE Effect ] ROUTE_TO_ONBOARDING_V2_POS_REDIRECT_SYNC',
  props<{ code: string, state: string }>()
);

export const routeToOnboardingV2PosRedirectError = createAction(
  '[ Auth/Routing ][ DECIDE_USER_ROUTE Effect ] ROUTE_TO_ONBOARDING_V2_POS_REDIRECT_ERROR',
  props<{ error: string }>()
);

// Navigate to company-profile/pos-providers, pick up resync at redirect code parse stage
export const routeToCompanyProfilePosRedirect = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_COMPANY_PROFILE_POS_REDIRECT',
  props<{ code: string, posName: IPOSRedirectProviderName, flowType: 'sync' | 'resync' }>()
);

// Navigate to company-profile/pos-providers, pick up interrupted sync
export const routeToCompanyProfileInterruptedPosSync = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_COMPANY_PROFILE_INTERRUPTED_POS_SYNC',
  props<{ posName: IPOSProviderName }>()
);

// Navigate to /home
export const routeToMyHub = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_MY_HUB'
);

// Navigate to /smart-receipt/company-info
export const routeToCompanyInfo = createAction(
  '[ Auth/Routing ][ DECIDE_ONBOARDED_USER_ROUTE Effect ] ROUTE_TO_COMPANY_INFO'
);

// Navigate to /auth - used by guard to route user to public part of the app
export const routeToAuth = createAction(
  '[ Auth/Routing ][ DECIDE_UNAUTHORIZED_USER_ROUTE | DECIDE_SIGNED_OUT_USER_ROUTE | Effect ] ROUTE_TO_AUTH'
);

// Navigate to receipt feed when user role is store ops
export const routeToReceiptFeed = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHORIZED_USER_ROUTE Effect ] ROUTE_TO_RECEIPT_FEED'
);

// Mark isDecidingRoute: false so that angular can route user further
export const routeToCurrentUrl = createAction(
  '[ Auth/Routing ][ DECIDE_AUTHENTICATED_USER_ROUTE Effect ] ROUTE_TO_CURRENT_URL'
);

// Mark isDecidingRoute: false - take off splash screen, allow guard to proceed
export const routingCompleted = createAction(
  '[ Auth/Routing ][ every ROUTE_TO_... Effect ] ROUTING_COMPLETED'
);

// Remove all query params in url bar (after action requiring them has completed)
// so that they don't get detected again in case of authentication status change
export const tidyUpQueryParams = createAction(
  '[ Auth/Routing ][ ROUTE_TO_SIGNUP_EMAIL_VERIFICATION | ROUTE_TO_ONBOARDING_POS_REDIRECT | ROUTE_TO_COMPANY_PROFILE_POS_REDIRECT Effect ] TIDY_UP_QUERY_PARAMS'
);

export const routeTitleChanged = createAction(
  '[ Auth/Routing ][ ROUTER_NAVIGATED Effect ] ROUTE_TITLE_CHANGED',
  props<{ sectionTitle: string, viewTitle: string }>()
);

// restore inital auth state, remove cookies
export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

//Redirect to v2 logout
export const routeToSignOutV2 = createAction(
  '[ Auth/Routing ][  Auth/Signin/SIGN_OUT_OKTA Effect ] ROUTE_TO_SIGN_OUT_V2'
);

const all = union({
  authenticatedUserRouteDecisionNeeded,
  fetchMerchantAttributes,
  fetchMerchantAttributesSuccess,
  fetchMerchantAttributesFailure,
  decideAuthenticatedUserRoute,
  decideOnboardedUserRoute,
  decideUnathenticatedUserRoute,
  decideSignedUpUserRoute,
  decideSignedOutUserRoute,
  decideUnauthorizedUserRoute,
  routeToSignupEmailVerification,
  routeToSignupPasswordRecovery,
  routeToSignupComplete,
  routeToPasswordRecovered,
  routeToOnboardingPosRedirect,
  routeToOnboarding,
  routeToOnboardingV2PosRedirectSync,
  routeToOnboardingV2PosRedirectError,
  routeToCompanyProfilePosRedirect,
  routeToCompanyProfileInterruptedPosSync,
  routeToMyHub,
  routeToReceiptFeed,
  routeToCompanyInfo,
  routeToAuth,
  routeToCurrentUrl,
  routingCompleted,
  tidyUpQueryParams,
  routeTitleChanged,
  receiveReset,
  routeToSignOutV2
});
export type Union = typeof all;
