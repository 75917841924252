import { take, delay, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { IAddress } from '@interfaces/location.interface';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '@configs/ui';

// Static utilities of varying nature
export class MiscUtils {

  // Check if traversal path is accessible and return that nested member. If not accessible, return null.
  static getNested(obj: {}, path: string[]) {
    if (obj == null) {
      return null;
    }
    for (const member of path) {
      if (obj[member] === undefined) {
        return null;
      } else {
        obj = obj[member];
      }
    }
    return obj;
  }

  static isMobile(): boolean {
    return window.innerWidth < BREAKPOINT_MOBILE;
  }

  static isTablet(): boolean {
    return window.innerWidth > BREAKPOINT_MOBILE && window.innerWidth <= BREAKPOINT_TABLET;
  }

  static isDesktop(): boolean {
    return window.innerWidth > BREAKPOINT_TABLET;
  }

  static formatAddressLines(address: IAddress): string[] {

    const format = (chunks: string[]) => chunks
      .filter(comp => !!comp)
      .map(comp => comp.trim())
      .join(' ');

    const firstLine = format([
      address.street,
      address.street_2,
      address.suburb,
    ]);

    const secondLine = format([
      address.state,
      address.postcode,
      address.country_code
    ]);

    return [firstLine, secondLine].filter(line => !!line.replace(/\s/g, '').length);
  }

  static formatAddress(address: IAddress): string {
    const formattedLines = this.formatAddressLines(address);
    return formattedLines.length ? formattedLines.join(' ') : null;
  }

  static getSizeMB(size: number): number {
    const sizeMB = size / 1024 / 1024;
    return Math.round(sizeMB * 100) / 100; // round to 2 precision
  }

  static getSizeKB(size: number): number {
    const sizeMB = size / 1024;
    return Math.round(sizeMB * 100) / 100; // round to 2 precision
  }

  static getFileExt(file: File): string {
    const chunks = file.name.split('.');
    return chunks[chunks.length - 1];
  }

  static blobToFile(blob: Blob, name: string): File {
    const file: any = blob;
    file.lastModifiedDate = new Date();
    file.name = name;
    return file;
  }

  static getAcronym(name: string) {
    if (!name) {
      return null;
    }
    return name
      .split(' ')
      .map(word => word[0])
      .join('');
  }

  static rgbToHex(r: number, g: number, b: number): string {
    const componentToHex = (c: number) => {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    return `${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  static setObservableTimeout(fn: (...args: any[]) => any, timeout: number) {
    of(null)
      .pipe(
        take(1),
        delay(timeout)
      )
      .subscribe(fn);
  }

  static saveBlobToDisk(blob: Blob | File, fileName: string) {
    const file = blob instanceof Blob ? this.blobToFile(blob, fileName) : blob;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';

    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  static range = (start: number, stop: number, step: number = 1) => Array.from(
    { length: (stop - start) / step + 1},
    (_, i) => start + (i * step)
  )

  /**
   * Trims all strings recursively in the object
   */
  static trimAllStringsRecursively(value) {
    if (!value) return value;

    if (typeof value === 'string') {
      return value.trim();
    }

    if (Array.isArray(value)) {
      return value.map(item => this.trimAllStringsRecursively(item));
    }

    if (typeof value === 'object') {
      return Object.keys(value).reduce((acc, curr) => {
        acc[curr] = this.trimAllStringsRecursively(value[curr]);
        return acc;
      }, {});
    }

    return value;
  }
}
