import { IOktaIDToken, ISlypToken } from '@interfaces/auth.interface';
import { UserRoleType } from '@interfaces/role.interface';

export const dummyOktaIDToken: IOktaIDToken = {
  expiresAt: 9999999999999,
  idToken: 'DUMMY',
  claims: {
    sub: 'dummy',
    email: 'dummy@dummy.com',
    given_name: 'Dev',
    family_name: 'McDummy',
  }
};

export const dummySlypToken: ISlypToken = {
  accessToken: 'DUMMY',
  merchantId: 'DUMMY',
  userId: 'DUMMY',
  userRole: UserRoleType.merchantDefault
};
