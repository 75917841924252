import { ConfirmDialogComponent, IConfirmDialogContext, CONFIRM_DIALOG_CONTEXT_DEFAULT } from './confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { v4 as uuid } from 'uuid';
import { take, takeUntil, publish } from 'rxjs/operators';
import { Subject, Observable, ConnectableObservable } from 'rxjs';
import { ComponentCanDeactivate } from '@models/component-can-deactivate.model';
import { DIALOG_COMPONENT_WIDTH_WIDE, DIALOG_COMPONENT_WIDTH_NARROM, DIALOG_COMPONENT_WIDTH_MEDIUM, UNSAVED_CHANGES_PROMPT_CONFIG } from '@configs/ui';

@Injectable()
export class ConfirmService {

  private protectedDialogs: {
    [key: string]: {
      dialogRef: MatDialogRef<any>,
      destroy$: Subject<void>,
      onClose?: (...args: any[]) => void
    }
  } = {};

  constructor(private dialogs: MatDialog) {}

  private confirmBeforeDialogDeactivation<T extends ComponentCanDeactivate>(dialogId: string) {
    const componentInstance: T = this.protectedDialogs[dialogId].dialogRef.componentInstance;
    const shouldWarn = componentInstance.canDeactivate();

    if (shouldWarn) {
      this.confirmWithDialog({
        ...UNSAVED_CHANGES_PROMPT_CONFIG,
        onYes: () => this.closeProtectedDialog(dialogId)
      });
    } else {
      this.closeProtectedDialog(dialogId);
    }
  }

  private closeProtectedDialog(dialogId: string) {
    const dialogMeta = this.protectedDialogs[dialogId];
    dialogMeta.dialogRef.close();
    if (dialogMeta.onClose) {
      dialogMeta.onClose();
    }
    dialogMeta.destroy$.next();
    dialogMeta.destroy$.complete();
    delete this.protectedDialogs[dialogId];
  }

  private getCssWidth(widthVariant: 'narrow' | 'medium' | 'wide'): string {
    return {
      wide: DIALOG_COMPONENT_WIDTH_WIDE,
      medium: DIALOG_COMPONENT_WIDTH_MEDIUM,
      narrow: DIALOG_COMPONENT_WIDTH_NARROM
    }[widthVariant] + 'px';
  }

  openDeactivationProtectedDialog<T extends ComponentCanDeactivate>({
    contentComponent,
    context = {},
    onClose,
    widthVariant = 'wide'
  }: {
    contentComponent: ComponentType<T>,
    context?: {},
    onClose?: (...args: any[]) => void,
    widthVariant?: 'narrow' | 'medium' | 'wide'
  }): MatDialogRef<T> {
    const dialogId = uuid();
    const destroy$ = new Subject<void>();
    const confirmBeforeDeactivationFn = () => this.confirmBeforeDialogDeactivation(dialogId);

    const dialogRef = this.dialogs.open<T>(contentComponent, {
      data: { ...context, confirmBeforeDeactivationFn },
      width: this.getCssWidth(widthVariant || 'wide'),
      panelClass: ['card-dialog'],
      autoFocus: false
    });

    dialogRef.disableClose = true;
    dialogRef.backdropClick()
      .pipe(takeUntil(destroy$))
      .subscribe(confirmBeforeDeactivationFn);

    this.protectedDialogs[dialogId] = {
      dialogRef,
      destroy$,
      onClose,
    };
    return dialogRef;
  }

  confirmWithDialog(context: IConfirmDialogContext): Observable<boolean> {
    const obs$ = new Observable<boolean>(subscriber => {

      const ref = this.dialogs.open(ConfirmDialogComponent, {
        data: {
          ...CONFIRM_DIALOG_CONTEXT_DEFAULT,
          ...context,
        },
        width: this.getCssWidth('medium'),
        panelClass: ['card-dialog'],
        autoFocus: false,
      });

      const dialogEmitted$ = new Subject<void>();
      const backdropEmitted$ = new Subject<void>();

      // handle backdrop click as negative reaction
      ref.backdropClick()
        .pipe(
          takeUntil(dialogEmitted$),
          take(1),
        )
        .subscribe(() => {
          if (context.onNo) {
            context.onNo()
          }
          backdropEmitted$.next();
          subscriber.next(false);
        });

      // handle positive reaction
      ref.componentInstance.yes
        .pipe(
          takeUntil(backdropEmitted$),
          take(1)
        )
        .subscribe(() => {
          dialogEmitted$.next();
          subscriber.next(true);
        });

      // handle negative reaction
      ref.componentInstance.no
        .pipe(
          takeUntil(backdropEmitted$),
          take(1)
        )
        .subscribe(() => {
          dialogEmitted$.next();
          subscriber.next(false);
        });

      // tidy up after dialog has been closed for whatever reason
      ref.afterClosed().subscribe(() => {
        subscriber.complete();
        backdropEmitted$.complete();
        dialogEmitted$.complete();
      });
    }).pipe(
      publish()
    );

    // publish/connect is effectively to make the returned observable hot so it's usable in effects
    (obs$ as ConnectableObservable<boolean>).connect();

    return obs$;
  }

}
