<div class="row no-gutters signup-container">
  <div class="col d-flex justify-content-end">
    <!-- ------------- right half sizing container ------------- -->
    <div class="row no-gutters form-container container-half-left flex-grow-1">
      <div class="col d-flex flex-column pl-5">
        <div class="row no-gutters flex-grow-1">
          <div class="col col-10 d-flex flex-column justify-content-center">

            <div class="row no-gutter mb-3" *ngIf="(currentStep$ | async) !== 'step-details'" [@fadeState]>
              <div class="col">
                <app-brand></app-brand>
              </div>
            </div>

            <!-- ---------------- Step ---------------- -->
            <div class="row no-gutters flex-grow-1 mb-3">
              <div class="col d-flex align-items-center">
                <app-multi-view class="view flex-grow-1" [current]="currentStep$ | async">
                  <app-step-details *appMultiViewItem="'step-details'"></app-step-details>
                  <app-step-verification *appMultiViewItem="'step-verification'"></app-step-verification>
                  <app-step-password *appMultiViewItem="'step-password-create'"></app-step-password>
                  <app-step-complete *appMultiViewItem="'step-complete'"></app-step-complete>
                  <app-step-password *appMultiViewItem="'step-password-recover'" [isPasswordRecovery]="true"></app-step-password>
                  <app-step-password-recovered *appMultiViewItem="'step-password-recovered'"></app-step-password-recovered>
                </app-multi-view>
              </div>
            </div>

          </div>
        </div>
        <!-- ------------- footer ------------- -->
        <div class="row no-gutters" *ngIf="!(isAuthenticated$ | async)" [@fadeState]>
          <div class="col col-10 py-3 d-flex align-items-center sds-border-top sds-border-color-base-grey_medium-50W">
            <span class="mr-3 sds-color-base-grey_medium-25W">Already have an account?</span>
            <a class="my-3 sds-btn" [routerLink]="signinRoute" data-test="signIn">SIGN IN</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col" *ngLet="stepsCount$ | async as stepsCount">
    <!-- <app-progress *ngIf="stepsCount.current <= stepsCount.total" [currentStepIndex]="stepsCount.current" [steps]="progressSteps$ | async"></app-progress> -->
    <picture class="h-100 w-100 d-flex align-items-center justify-content-center">
      <source [srcset]="imgPath + 'sign-up@1x.webp 1x,' +
                        imgPath + 'sign-up@2x.webp 2x'" type="image/webp">
      <source [srcset]="imgPath + 'sign-up@1x.png 1x,' +
                        imgPath + 'sign-up@2x.png 2x'" type="image/png">
      <img class="w-100 h-100 hero-image" [src]="imgPath + 'sign-up@1x.png'" alt="feature-rich-smart-receipt" appImageSmooth>
    </picture>
  </div>
</div>

<!-- just for debug -->
<app-debug-panel class="debug-panel" *ngIf="debugSteps.length">
  <div class="d-flex flex-column">
    <button class="sds-btn mb-2" *ngFor="let step of debugSteps" (click)="setStepDebug(step.action)">{{ step.name }}</button>
  </div>
</app-debug-panel>
