import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, union, props } from '@ngrx/store';

export const initDefaults = createAction(
  '[ Home/Navigation ][ HomeComponent ] INIT_DEFAULTS',
);

export const initDefaultSidenavState = createAction(
  '[ Home/Navigation ][ INIT_DEFAULTS ] INIT_DEFAULT_SIDENAV_STATE',
  props<{isSidenavExpandedOnDesktopByDefault: boolean}>()
);

export const expandSidenavClicked = createAction(
  '[ Home/Navigation ][ Home/HeaderComponent | Home/SidenavComponent ] EXPAND_SIDENAV_CLICKED',
);

export const adjustSidenavToScreenWidth = createAction(
  '[ Home/Navigation ][ UI/RECALCULATE_IS_MOBILE Effect ] ADJUST_SIDENAV_TO_SCREEN_WIDTH',
  props<{isDesktop: boolean}>()
);

export const shrinkSidenavClicked = createAction(
  '[ Home/Navigation ][ Home/HeaderComponent | HomeComponent ] SHRINK_SIDENAV_CLICKED',
);

export const storeDesktopSidenavState = createAction(
  '[ Home/Navigation ][ EXPAND_SIDENAV_CLICKED | SHRINK_SIDENAV_CLICKED ] STORE_DESKTOP_SIDENAV_STATE',
);

export const leftSidebarTriggerClicked = createAction(
  '[ Home/Navigation ][ Home/SidenavComponent ] LEFT_SIDEBAR_TRIGGER_CLICKED',
);

export const leftSidebarCloseTriggerClicked = createAction(
  '[ Home/Navigation ][ Home/CompanyInfoComponent ] LEFT_SIDEBAR_CLOSE_TRIGGER_CLICKED',
);

export const rightSidebarTriggerClicked = createAction(
  '[ Home/Navigation ][ Home/HeaderComponent ] RIGHT_SIDEBAR_TRIGGER_CLICKED',
);

export const rightSidebarCloseTriggerClicked = createAction(
  '[ Home/Navigation ][ Home/ProfileComponent ] RIGHT_SIDEBAR_CLOSE_TRIGGER_CLICKED',
);

export const secondaryBackdropClicked = createAction(
  '[ Home/Navigation ][ HomeComponent ] SECONDARY_BACKDROP_CLICKED',
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE
);

const all = union({
  initDefaults,
  initDefaultSidenavState,
  expandSidenavClicked,
  shrinkSidenavClicked,
  storeDesktopSidenavState,
  adjustSidenavToScreenWidth,
  leftSidebarTriggerClicked,
  leftSidebarCloseTriggerClicked,
  rightSidebarTriggerClicked,
  rightSidebarCloseTriggerClicked,
  secondaryBackdropClicked,
  receiveReset,
});
export type Union = typeof all;
