<div class="row no-gutters signin-container">
  <div class="col">
    <picture
      class="h-100 w-100 d-flex align-items-center justify-content-center"
    >
      <source
        [srcset]="
          imgPath + 'sign-in@1x.webp 1x,' + imgPath + 'sign-in@2x.webp 2x'
        "
        type="image/webp"
      />
      <source
        [srcset]="
          imgPath + 'sign-in@1x.png 1x,' + imgPath + 'sign-in@2x.png 2x'
        "
        type="image/png"
      />
      <img
        class="w-100 h-100 hero-image"
        [src]="imgPath + 'sign-in@1x.png'"
        alt="145-million-trees-used-globally-to-print-receipts"
        appImageSmooth
      />
    </picture>
  </div>

  <div class="col">
    <!-- ------------- right half sizing container ------------- -->
    <div class="row no-gutters form-container container-half-right">
      <div class="col d-flex flex-column pr-5">
        <!-- ------------- form ------------- -->
        <div class="row no-gutters flex-grow-1">
          <div
            class="col col-8 offset-2 d-flex flex-column justify-content-center"
          >
            <form
              [formGroup]="form"
              [@formState]
              [appBlockRedundantSubmit]="isAuthenticating$ | async"
              (validNgSubmit)="onSignIn()"
            >
              <div class="row row-brand">
                <div class="col">
                  <app-brand></app-brand>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <h2
                    class="sds-color-base-grey_medium-50B brand-underline sds-heading-3-thin"
                  >
                    Sign in
                  </h2>
                  <p class="sds-color-base-grey_medium mb-0">
                    Please enter your credentials to proceed.
                  </p>
                </div>
              </div>
              <div class="row">
                <mat-form-field
                  appearance="fill"
                  class="col-12 sds-form-field primary"
                >
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    type="email"
                    name="email"
                    autocomplete="email"
                    formControlName="email"
                    data-test="email"
                    required
                    appValidateOnBlur
                  />
                  <mat-error
                    *ngIf="errors['email$'] | async as error"
                    data-test="error"
                    >{{ error }}</mat-error
                  >
                </mat-form-field>
              </div>
              <div class="row">
                <div
                  class="col col-12 d-flex sds-form-field-btn-container with-trailing-btn"
                >
                  <mat-form-field
                    appearance="fill"
                    class="flex-grow-1 sds-form-field primary"
                  >
                    <mat-label>Password</mat-label>
                    <input
                      matInput
                      [type]="isPasswordHidden ? 'password' : 'text'"
                      name="password"
                      autocomplete="password"
                      formControlName="password"
                      data-test="password"
                      required
                      appValidateOnBlur
                    />
                    <mat-error
                      *ngIf="errors['password$'] | async as error"
                      data-test="error"
                      >{{ error }}</mat-error
                    >
                  </mat-form-field>
                  <div class="form-field-btn-wrapper">
                    <div
                      class="sds-btn form-field-btn"
                      (click)="onTogglePasswordVisibility()"
                    >
                      <mat-icon
                        [class.sds-color-brand-primary]="!isPasswordHidden"
                        >remove_red_eye</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="mb-3 sds-btn-any sds-text-link sds-color-base-grey_medium-50W"
                    data-test="forgotPassword"
                    (click)="onForgotPassword()"
                  >
                    Forgot password
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button
                    type="submit"
                    class="my-3 sds-btn primary emphasized"
                    data-test="signIn"
                  >
                    <app-dimmer
                      class="sds-bg-brand-primary sds-color-base-white"
                      [isDimmed]="isAuthenticating$ | async"
                      [spinnerDiameter]="20"
                      [strokeWidth]="1.5"
                    ></app-dimmer>
                    SIGN IN
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- ------------- footer ------------- -->
        <div class="row no-gutters">
          <div
            class="col col-10 offset-2 py-3 d-flex align-items-center sds-border-top sds-border-color-base-grey_medium-50W"
          >
            <span class="mr-3 sds-color-base-grey_medium-25W"
              >Don't have a Slyp Account?</span
            >
            <a
              [href]="redirectToV2Signup"
              data-test="createAccount"
              class="my-3 sds-btn"
              >CREATE ACCOUNT</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
