import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromApp from '@app/store/app.reducer';
import * as fromUI from '@ui/store/ui.reducer';

export const selectState = createFeatureSelector<fromApp.AppState, fromUI.State>('ui');

export const selectIsMobile = createSelector(
  selectState,
  ({isMobile}) => isMobile
);

export const selectIsTablet = createSelector(
  selectState,
  ({isTablet}) => isTablet
);

export const selectIsDesktop = createSelector(
  selectState,
  ({isDesktop}) => isDesktop
);

export const selectScrollingElement = createSelector(
  selectState,
  ({scrollingElementId}) => scrollingElementId
    ? document.querySelector('#' + scrollingElementId) as HTMLElement
    : null
);
