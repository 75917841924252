import { createReducer, on } from '@ngrx/store';
import * as RoutingActions from '@routing/store/routing.actions';

export interface State {
  isOnboardingInProgress: boolean;
  isDecidingRoute: boolean;
  reachedDecidedRoute: boolean;
  sectionTitle: string;
  viewTitle: string;
}

const initialState: State = {
  isOnboardingInProgress: false,
  isDecidingRoute: false,
  reachedDecidedRoute: false,
  sectionTitle: null,
  viewTitle: null
};


export const reducer = createReducer<State, RoutingActions.Union>(
  initialState,
  on(
    RoutingActions.decideUnathenticatedUserRoute,
    RoutingActions.authenticatedUserRouteDecisionNeeded,
    RoutingActions.decideAuthenticatedUserRoute,
    RoutingActions.decideOnboardedUserRoute,
    RoutingActions.decideSignedOutUserRoute,
    RoutingActions.decideUnauthorizedUserRoute,
    RoutingActions.decideSignedUpUserRoute,
    state => ({
      ...state,
      isDecidingRoute: true
    })
  ),
  on(
    RoutingActions.fetchMerchantAttributesFailure,
    state => ({
      ...state,
      isDecidingRoute: false
    })
  ),
  on(
    RoutingActions.routeToSignupEmailVerification,
    RoutingActions.routeToSignupPasswordRecovery,
    RoutingActions.routeToSignupComplete,
    RoutingActions.routeToPasswordRecovered,
    RoutingActions.routeToCompanyProfilePosRedirect,
    RoutingActions.routeToCompanyProfileInterruptedPosSync,
    RoutingActions.routeToCompanyInfo,
    RoutingActions.routeToMyHub,
    RoutingActions.routeToAuth,
    RoutingActions.routeToCurrentUrl,
    RoutingActions.routeToReceiptFeed,
    state => ({
      ...state,
      isDecidingRoute: false,
      isOnboardingInProgress: false,
    })
  ),
  on(
    RoutingActions.routeToOnboardingPosRedirect,
    RoutingActions.routeToOnboarding,
    RoutingActions.routeToOnboardingV2,
    RoutingActions.routeToOnboardingV2PosRedirectSync,
    RoutingActions.routeToOnboardingV2PosRedirectError,
    state => ({
      ...state,
      isDecidingRoute: false,
      isOnboardingInProgress: true,
    })
  ),
  on(RoutingActions.fetchMerchantAttributesSuccess, (state, {attrs}) => ({
    ...state,
    isOnboardingInProgress: attrs.phase !== 'onboarded'
  })),
  on(RoutingActions.routingCompleted, state => ({
    ...state,
    reachedDecidedRoute: true
  })),
  on(RoutingActions.routeTitleChanged, (state, {viewTitle, sectionTitle}) => ({
    ...state,
    viewTitle,
    sectionTitle,
  })),
);
