import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SentryErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request.clone()).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          this.logError(request, response);
        }
        return throwError(response);
      }),
    );
  }

  private logError(request: HttpRequest<any>, response: HttpErrorResponse) {
    Sentry.withScope(scope => {
      scope.setExtra('Response body', response.error);
      scope.setExtra('Request body', request.body);
      Sentry.captureMessage(this.getMessage(request, response));
    });
  }

  private getMessage(request: HttpRequest<any>, response: HttpErrorResponse): string {
    const msgTmpl = 'Http request failed. ({method}, {status}, {url})';
    const chunkMap = {
      method: request.method,
      url: request.url,
      status: response.status,
    };

    return Object.keys(chunkMap).reduce((resultMsg, key) => resultMsg.replace(`{${key}}`, chunkMap[key]), msgTmpl);
  }

}
