import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class EmailsService {

  constructor(private http: HttpClient) {}

  requestForgotPasswordEmail(username: string): Observable<void> {
    return this.http.post<any>('/v1/managed-users/forgot-password', {username});
  }

  requestActivationEmailResend(username: string): Observable<void> {
    return this.http.post<void>('/v1/okta/resend-invite', {username});
  }

}
