import { ImageSmoothDirective } from './image-smooth.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ImageSmoothDirective],
  imports: [ CommonModule ],
  exports: [ImageSmoothDirective],
})
export class ImageSmoothModule {}
