import * as ResendActivationEmailActions from '@auth/resend-activation-email/store/resend-activation-email.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  email: string;
  isRequesting: boolean;
}

const initialState: State = {
  email: null,
  isRequesting: false,
};

export const reducer = createReducer<State, ResendActivationEmailActions.Union>(
  initialState,
  on(ResendActivationEmailActions.activationEmailResendRequested, (state, {email}) => ({
    ...state,
    email
  })),
  on(ResendActivationEmailActions.requestActivationEmailResend, state => ({
    ...state,
    isRequesting: true
  })),
  on(
    ResendActivationEmailActions.requestActivationEmailResendSuccess,
    ResendActivationEmailActions.requestActivationEmailResendFailure,
    state => ({
      ...state,
      isRequesting: false,
    })
  ),
  on(
    ResendActivationEmailActions.reset,
    ResendActivationEmailActions.receiveReset,
    state => ({ ...initialState, })
  ),
);
