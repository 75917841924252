import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { MiscUtils } from '@utils/misc';
import { Subject, zip } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { MultiViewItemDirective } from './multi-view-item.directive';

/*
 * This component aims to provide abstracted way of showing a few variants of content with ngSwitch based routing.
 * Queue related logic makes sure that component reflects every view change, no matter how fast they were supplied.
 *
 * <app-multi-view [current]="mode">
 *   <mat-icon *appMultiViewItem="'someMode1'">some_icon1</mat-icon>
 *   <mat-icon *appMultiViewItem="'someMode2'">some_icon2</mat-icon>
 *   <mat-icon *appMultiViewItem="'someMode3'">some_icon3</mat-icon>
 * </app-multi-view>
 *
 *
 * User can supply his own animations in parent component like:
 *
 * [animationWrapperClass]="'multi-view__animation--rotate" // must match class name
 * [animationDuration]="200" // must match transition length in css
 * [animationHasIntermediaryStep]="true" // true if animation-state-intermediary is necessary
 *
 * ::ng-deep {
 * .multi-view__animation--rotate {
 *
 *   &.animation-state-intermediary {
 *     transform: rotateX(-90deg);
 *     opacity: 0;
 *   }
 *
 *   &.animation-state-in {
 *     transform: rotateX(0);
 *     opacity: 1;
 *     transition: all .2s ease;
 *     transition-property: transform, opacity;
 *   }
 *
 *   &.animation-state-out {
 *     transform: rotateX(90deg);
 *     opacity: 0;
 *     transition: all .2s ease;
 *     transition-property: transform, opacity;
 *   }
 * }
 *
 */

type IViewState = 'intermediary' | 'in' | 'out';

@Component({
  selector: 'app-multi-view',
  templateUrl: './multi-view.component.html',
  styleUrls: ['./multi-view.component.scss'],
  host: {
    '[class.multi-view]': 'true'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiViewComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  @ContentChildren(MultiViewItemDirective, {descendants: true}) views: QueryList<MultiViewItemDirective>;

  @Input() animationWrapperClass = 'transition-opacity';
  @Input() animationHasIntermediaryStep = false;
  @Input() animationDuration = 200;
  @Input() current: string;
  currentView: string = undefined;

  viewState: IViewState = 'out';

  private isInitialized = false;

  private waitForShowAnimation$ = new Subject<void>();
  private waitForHideAnimation$ = new Subject<void>();
  private pushView$ = new Subject<string>(); // views queue stream
  private wasPreviousShown = new Subject<void>();
  private wasPreviousHidden = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {
    this.setupViewSwitch();
  }

  ngOnInit() {
    if (this.current != null) {
      this.initFirstView();
    }

    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isInitialized && changes.current && changes.current.currentValue != null) {
      const viewKey = changes.current.currentValue;
      const availableKeys = this.views.toArray().map(itemDirective => itemDirective.name);
      if (!availableKeys.includes(viewKey)) {
        throw new Error(`You did not pass view named "${viewKey}" to multi-view component. Remember to mark the name of the view with *appMultiViewItem="'view-name'"`);
      }

      this.pushView$.next(viewKey);
    }
  }

  ngAfterContentInit() {
    this.wasPreviousShown.next(); // kickstart first view transition
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.pushView$.complete();
    this.wasPreviousShown.complete();
    this.wasPreviousHidden.complete();
    this.waitForShowAnimation$.complete();
    this.waitForHideAnimation$.complete();
  }

  private setupViewSwitch() {
    zip(
      this.pushView$,
      this.wasPreviousShown
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe(([viewKey, _]) => this.hideView());

    zip(
      this.pushView$,
      this.wasPreviousHidden
    )
    .pipe(
      takeUntil(this.destroy$),
      tap(([viewKey, _]) => this.setView(viewKey)),
      tap(() => {
        if (this.animationHasIntermediaryStep) {
          MiscUtils.setObservableTimeout(() => this.prepareAnimationIntermediaryStep(), 0);
        }
      })
    )
    .subscribe(() => MiscUtils.setObservableTimeout(() => this.showView(), this.animationHasIntermediaryStep ? 50 : 0)); // timeout waits for given view to render (while still out)

    this.waitForShowAnimation$
      .pipe(
        takeUntil(this.destroy$),
        delay(this.animationDuration),
      )
      .subscribe(() => this.wasPreviousShown.next());

    this.waitForHideAnimation$
      .pipe(
        takeUntil(this.destroy$),
        delay(this.animationDuration),
      )
      .subscribe(() => {
        this.currentView = null;
        this.ref.markForCheck();
        this.wasPreviousHidden.next(); // need to show next view
      });
  }

  private initFirstView() {
    this.currentView = this.current;
    this.viewState = 'in';
    this.ref.markForCheck();
  }

  private setView(viewKey) {
    this.currentView = viewKey;
    this.ref.markForCheck();
  }

  private prepareAnimationIntermediaryStep() {
    this.viewState = 'intermediary';
    this.ref.markForCheck();
  }

  private showView() {
    this.viewState = 'in'; // have to create symmetrical effect for setting out and null
    this.ref.markForCheck();
    this.waitForShowAnimation$.next();
  }

  private hideView() {
    this.viewState = 'out'; // have to create symmetrical effect for setting out and null
    this.ref.markForCheck();
    this.waitForHideAnimation$.next();
  }

  trackViewsBy = (_, view) => view;
}
