import { NotifierOptions } from 'angular-notifier';
import { environment } from '@environments/environment';
import { TOAST_TIMEOUT, TOAST_TIMEOUT_UAT } from '@configs/ui';

export const notifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 15
    },
    vertical: {
      distance: 15,
      gap: 15
    }
  },
  theme: 'material',
  animations: {
    hide: {
      preset: 'slide',
    },
  },
  behaviour: {
    autoHide: environment.production ? TOAST_TIMEOUT : TOAST_TIMEOUT_UAT,
  }
};
