import { ResendActivationEmailFacade } from '@auth/resend-activation-email/store/resend-activation-email.facade';
import * as SignupActions from '@signup/store/signup.actions';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SignupFacade } from '@signup/store/signup.facade';

@Component({
  selector: 'app-step-verification',
  templateUrl: './step-verification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepVerificationComponent {

  isRequesting$ = this.rxResendActivationEmail.isRequesting$;
  email$ = this.rxSignup.accountEmail$;

  constructor(
    private rxSignup: SignupFacade,
    private rxResendActivationEmail: ResendActivationEmailFacade
  ) { }

  onResendEmail() {
    this.rxSignup.dispatch(SignupActions.resendActivationEmailClicked());
  }
}
