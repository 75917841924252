import { CustomValidators } from '@utils/validators';
import { ValidatorFn } from '@angular/forms';
import { IReceiptCardType, IReceiptFeedFilterableField, IReceiptDeliveryChannel } from '@interfaces/receipt.interface';
import * as moment from 'moment';

export const RECEIPT_FEED_FILTERABLE_FIELDS_OPTIONS: {prop: IReceiptFeedFilterableField, label: string}[] = [
  {prop: 'merchant', label: 'Organisation'},
  {prop: 'store', label: 'Store'},
  {prop: 'amount_min', label: 'Amount (min)'},
  {prop: 'amount_max', label: 'Amount (max)'},
  {prop: 'external_id', label: 'Receipt ID'},
];

export const RECEIPT_FEED_FILTER_WIDGET_TYPES: {[key in IReceiptFeedFilterableField]?: 'text' | 'number' | 'select'} = {
  amount_min: 'number',
  amount_max: 'number',
  merchant: 'select',
  store: 'select',
  external_id: 'text',
};

export const RECEIPT_DELIVERY_CHANNEL_OPTIONS: {value: IReceiptDeliveryChannel, label: string}[] = [
  {value: 'bank', label: 'Bank'},
  {value: 'sms', label: 'SMS'},
];

export const RECEIPT_CARD_TYPE_OPTIONS: {value: IReceiptCardType, label: string}[] = [
  {value: 'mastercard', label: 'MasterCard'},
  {value: 'visa', label: 'VISA'},
  {value: 'amex', label: 'AMEX'},
  {value: 'other', label: 'Other'},
];

export const RECEIPT_FEED_OPTIONS_FIELDS: {[key in IReceiptFeedFilterableField]?: {label: string, value: string}} = {
  store: {label: 'name', value: 'id'},
  merchant: {label: 'name', value: 'id'},
};

export const RECEIPT_FEED_FILTER_VALUE_VALIDATORS: {[key in IReceiptFeedFilterableField]?: ValidatorFn[]} = {
  card_last_4: [CustomValidators.exactLength(4), CustomValidators.integer()],
};

export const RECEIPT_FEED_DISALLOWED_CHARACTERS: {[key in IReceiptFeedFilterableField]?: string[]} = {
  amount_min: ['e'],
  amount_max: ['e'],
};

export function getDefaultBeginRange() {
  return moment().startOf('day');
}
export function getDefaultEndRange() {
  return moment().endOf('day');
}

