import { ISignupStep } from '@interfaces/signup.interface';
import * as SignupActions from '@signup/store/signup.actions';
import { environment } from '@environments/environment';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';
import { AUTH_ROUTES } from '@configs/routing';
import { SigninFacade } from '@signin/store/signin.facade';
import { SignupFacade } from '@signup/store/signup.facade';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    AppearAnimationsFactory.fade('fadeState')
  ]
})


export class SignupComponent {
  currentStep$ = this.rxSignup.step$;
  stepsCount$ = this.rxSignup.stepsCount$;
  progressSteps$ = this.rxSignup.stepsCount$.pipe(
    map(stepsCount => Array.from({ length: stepsCount.total }, (v, k) => k + 1))
  );

  isAuthenticated$ = this.rxSignin.isAuthenticated$;

  debugSteps: { name: ISignupStep, action: SignupActions.Union }[] = !environment.debugSignup
    ? []
    : [
      { name: 'step-details', action: SignupActions.setStepDetails() },
      { name: 'step-verification', action: SignupActions.setStepVerification() },
      { name: 'step-password-create', action: SignupActions.setStepPasswordCreate() },
      { name: 'step-complete', action: SignupActions.setStepComplete() },
      { name: 'step-password-recover', action: SignupActions.setStepPasswordRecover() },
      { name: 'step-password-recovered', action: SignupActions.setStepPasswordRecovered() },
    ];

  signinRoute = AUTH_ROUTES.signin.url;
  imgPath = '/assets/images/auth/sign-up/';

  constructor(
    private rxSignup: SignupFacade,
    private rxSignin: SigninFacade
  ) { }
  
  setStepDebug(action: SignupActions.Union) {
    this.rxSignup.dispatch(action);
  }
}
