import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { IOnboardingPhase } from '@interfaces/onboarding.interface';
import { IPOSProviderName, IPOSRedirectProviderName } from '@interfaces/pos.interface';
import { createAction, props, union } from '@ngrx/store';
import { ONBOARDING_RESET_SIGNAL_TYPE } from '@onboarding/store/shared-action-types';

export const setStepPosSelect = createAction(
  '[ Onboarding/Process ][ Onboarding/Pos/HANDLE_POS_REDIRECT_CALLBACK | Onboarding/Process/PICK_UP_ONBOARDING_AT_START Effect | StepPosLegactApiKeyComponent ] SET_STEP_POS_SELECT',
);

export const setStepPosLegacyApiKey = createAction(
  '[ Onboarding/Process ][ Onboarding/Pos/FETCH_LEGACY_POS_API_KEY_SUCCESS Effect ] SET_STEP_POS_LEGACY_API_KEY',
);

export const setStepPosSyncComplete = createAction(
  '[ Onboarding/Process ][ Onboarding/Pos/POS_SUCCESS Effect ] SET_STEP_POS_SYNC_COMPLETE',
);

export const setStepCompanyInfo = createAction(
  '[ Onboarding/Process ][ Onboarding/Pos/CONNECT_POS_ACKNOWLEDGED_CLICKED Effect | StepDesignReceiptComponent ] SET_STEP_COMPANY_INFO',
);

export const setStepDesignReceipt = createAction(
  '[ Onboarding/Process ][ Onboarding/CompanyInfo/SAVE_COMPANY_INFO_SUCCESS Effect ] SET_STEP_DESIGN_RECEIPT',
);

export const setStepComplete = createAction(
  '[ Onboarding/Process ][ Onboarding/ReceiptDesign/SAVE_RECEIPT_DESIGN_SUCCESS Effect ] SET_STEP_COMPLETE',
);

export const pickUpInterruptedPOSSync = createAction(
  '[ Onboarding/Process ][ PICK_UP_ONBOARDING_AT_PHASE Effect ] PICK_UP_INTERRUPTED_POS_SYNC',
);

export const pickUpOnboardingAtPhase = createAction(
  '[ Onboarding/Process ][ Auth/Routing/ROUTE_TO_ONBOARDING ] PICK_UP_ONBOARDING_AT_PHASE',
  props<{phase: IOnboardingPhase, posName: IPOSProviderName}>()
);

export const pickUpOnboardingAtPosRedirect = createAction(
  '[ Onboarding/Process ][ Auth/Routing/ROUTE_TO_ONBOARDING_POS_REDIRECT ] PICK_UP_ONBOARDING_AT_POS_REDIRECT',
  props<{code: string, posName: IPOSRedirectProviderName}>()
);

export const saveOnboardingPhase = createAction(
  '[ Onboarding/Process ][ SET_STEP_POS_LEGACY_API_KEY | SET_STEP_COMPANY_INFO | SET_STEP_DESIGN_RECEIPT | SET_STEP_COMPLETE | Onboarding/Pos/(VERIFY_POS | POS_FAILURE) Effect ] SAVE_ONBOARDING_PHASE',
  props<{phase: IOnboardingPhase}>()
);

export const saveOnboardingPhaseSuccess = createAction(
  '[ Onboarding/Process ][ SAVE_ONBOARDING_PHASE Effect ] SAVE_ONBOARDING_PHASE_SUCCESS',
);

export const saveOnboardingPhaseFailure = createAction(
  '[ Onboarding/Process ][ SAVE_ONBOARDING_PHASE Effect ] SAVE_ONBOARDING_PHASE_FAILURE',
  props<{errorMsg: string}>()
);

export const finishOnboarding = createAction(
  '[ Onboarding/Process ][ StepCompleteComponent ] FINISH_ONBOARDING',
);

export const receiveResetOnboarding = createAction(
  ONBOARDING_RESET_SIGNAL_TYPE,
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE,
);

const all = union({
  setStepPosSelect,
  setStepPosLegacyApiKey,
  pickUpInterruptedPOSSync,
  setStepPosSyncComplete,
  setStepCompanyInfo,
  setStepDesignReceipt,
  setStepComplete,
  pickUpOnboardingAtPhase,
  pickUpOnboardingAtPosRedirect,
  saveOnboardingPhase,
  saveOnboardingPhaseSuccess,
  saveOnboardingPhaseFailure,
  finishOnboarding,
  receiveResetOnboarding,
  receiveReset,
});
export type Union = typeof all;
