import { DataTypesAdapterService } from '@adapters/data-types-adapter.service';
import { Injectable } from '@angular/core';
import { ErrorsUtils } from '@app/core/utils/errors';
import * as AppActions from '@app/store/app.actions';
import * as fromApp from '@app/store/app.reducer';
import { MERCHANT_API_KEY_ALLOWED_ROLES } from '@configs/api-key';
import { RECEIPT_DELIVERY_CHANNEL_OPTIONS, RECEIPT_FEED_FILTERABLE_FIELDS_OPTIONS, RECEIPT_CARD_TYPE_OPTIONS } from '@configs/receipt-feed';
import { MERCHANT_WEBHOOK_EVENTS } from '@configs/webhook';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as OptionsActions from '@options/store/options.actions';
import * as fromOptions from '@options/store/options.reducer';
import * as OptionsSelectors from '@options/store/options.selectors';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

@Injectable()
export class OptionsEffects {

  optionsNeeded$ = createEffect(() => this.actions$.pipe(
    ofType(OptionsActions.optionsNeeded),
    mergeMap(({neededKeys}) => this.rxStore.select(OptionsSelectors.selectMissingOptionsKeys(neededKeys)).pipe(
      take(1)
    )),
    mergeMap(missingKeys => !!missingKeys.length
      ? [OptionsActions.fetchOptions({missingKeys})]
      : []
    ),
  ));

  fetchOptions$ = createEffect(() => this.actions$.pipe(
    ofType(OptionsActions.fetchOptions),
    mergeMap(({missingKeys}) => forkJoin(this.makeOptionsRequests(missingKeys)).pipe(
      map(options => missingKeys.reduce((conf, key, i) => ({
        ...conf,
        [key]: options[i]
      }), {})),
      map(options => OptionsActions.fetchOptionsSuccess({options})),
      catchError(response => of(OptionsActions.fetchOptionsFailure({errorMsg: ErrorsUtils.getMsg({response})})))
    )),
  ));

  fetchOptionsFailure$ = createEffect(() => this.actions$.pipe(
    ofType(OptionsActions.fetchOptionsFailure),
    map(({errorMsg}) => AppActions.notifyError({errorTrigger: 'fetching options', errorMsg}))
  ));

  private optionsMethodsMap: {[key in fromOptions.IOptionSlice]: (...args: any) => Observable<any[]>} = {
    industries: () => this.dataTypesAdapter.fetchIndustries(),
    merchantTypes: () => this.dataTypesAdapter.fetchMerchantTypes(),
    timezones: () => this.dataTypesAdapter.fetchTimezones(),
    allowedApiKeyRoles: () => of(MERCHANT_API_KEY_ALLOWED_ROLES),
    merchantWebhookEvents: () => of(MERCHANT_WEBHOOK_EVENTS),
    receiptFeedFilterableFields: () => of(RECEIPT_FEED_FILTERABLE_FIELDS_OPTIONS),
    receiptDeliveryChannels: () => of(RECEIPT_DELIVERY_CHANNEL_OPTIONS),
    receiptCardTypes: () => of(RECEIPT_CARD_TYPE_OPTIONS)
  };

  constructor(
    private actions$: Actions,
    private rxStore: Store<fromApp.AppState>,
    private dataTypesAdapter: DataTypesAdapterService,
  ) {}

  private makeOptionsRequests(keys: fromOptions.IOptionSlice[]): Observable<any[]>[] {
    return keys.map(key => this.optionsMethodsMap[key]());
  }
}
