import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AppearAnimationsFactory } from '@animations/appear-animations.factory';
import * as SignupActions from '@signup/store/signup.actions';
import { SignupFacade } from '@signup/store/signup.facade';
import { FormsUtils } from '@utils/forms';
import { CustomValidators } from '@utils/validators';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-step-password',
  templateUrl: './step-password.component.html',
  styleUrls: ['./step-password.component.scss'],
  animations: [
    AppearAnimationsFactory.fadeUp('errorState')
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepPasswordComponent implements OnInit, OnDestroy {

  isRequesting$ = this.rxSignup.isRequesting$;

  @Input() isPasswordRecovery = false;

  isPasswordHidden = true;
  form: FormGroup;
  errorsList$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  isSubmitAttempted = false;

  passwordValidators: {validator: ValidatorFn, key: string, label: string}[] = [
    {validator: CustomValidators.zeroMinLength(8), key: 'minlength', label: '8 characters minimum'},
    {validator: CustomValidators.includes(/[a-z]/, 'lowercase_letter'), key: 'includes_lowercase_letter', label: 'one lowercase letter'},
    {validator: CustomValidators.includes(/[A-Z]/, 'uppercase_letter'), key: 'includes_uppercase_letter', label: 'one uppercase letter'},
    {validator: CustomValidators.includes(/[0-9]/, 'number'), key: 'includes_number', label: 'one number'},
    {validator: CustomValidators.includes(/[_~@#$%^&*+=`|{}:;!.?\"()\[\]-]/, 'special_character'), key: 'includes_special_character', label: 'one special character'},
  ];

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private notifier: NotifierService,
    private rxSignup: SignupFacade
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password: [null, [
        Validators.required,
        ...this.passwordValidators.map(config => config.validator)
      ]],
    });

    const control = this.form.get('password');

    control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.errorsList$.next(control.errors ? Object.keys(control.errors) : []));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTogglePasswordVisibility() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  onSubmit() {
    if (this.form.valid) {
      const { password } = this.form.value;
      this.rxSignup.dispatch(SignupActions.passwordSubmitted({password, isPasswordRecovery: this.isPasswordRecovery}));
    } else {
      FormsUtils.showAllErrors(this.form);
      this.isSubmitAttempted = true;
      this.notifier.show({type: 'warning', message: 'Please correct the errors and try again.'});
    }
  }

  trackValidatorsBy = (_, index) => index;
}
