<div class="view-container" [ngSwitch]="currentView">
  <ng-container *ngFor="let view of views; trackViewsBy">
    <div class="view-animation-wrapper" *ngSwitchCase="view.name"
      [ngClass]="animationWrapperClass"
      [class.animation-state-intermediary]="viewState === 'intermediary'"
      [class.animation-state-in]="viewState === 'in'"
      [class.animation-state-out]="viewState === 'out'"
    >
      <ng-template [ngTemplateOutlet]="view.template"></ng-template>
    </div>
  </ng-container>
</div>
