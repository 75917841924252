import { GLOBAL_RESET_SIGNAL_TYPE } from '@app/store/shared-actions';
import { createAction, union, props } from '@ngrx/store';

export const resendActivationEmailClicked = createAction(
  '[ Auth/ResendActivationEmail ][ StepDetailsComponent ] RESEND_ACTIVATION_EMAIL_CLICKED',
);

export const emailForActivationResendSubmitted = createAction(
  '[ Auth/ResendActivationEmail ][ StepResendActivationEmailComponent ] ACTIVATION_EMAIL_FOR_RESEND_SUBMITTED',
  props<{email: string}>()
);

export const activationEmailResendRequested = createAction(
  '[ Auth/ResendActivationEmail ][ ACTIVATION_EMAIL_FOR_RESEND_SUBMITTED | Auth/Signup/RESEND_ACTIVATION_EMAIL_CLICKED Effect ] ACTIVATION_EMAIL_RESEND_REQUESTED',
  props<{email: string}>()
);

export const requestActivationEmailResend = createAction(
  '[ Auth/ResendActivationEmail ][ RESEND_ACTIVATION_EMAIL_CLICKED Effect ] REQUEST_ACTIVATION_EMAIL_RESEND',
);

export const requestActivationEmailResendSuccess = createAction(
  '[ Auth/ResendActivationEmail ][ REQUEST_ACTIVATION_EMAIL_RESEND Effect ] REQUEST_ACTIVATION_EMAIL_RESEND_SUCCESS',
);

export const requestActivationEmailResendFailure = createAction(
  '[ Auth/ResendActivationEmail ][ REQUEST_ACTIVATION_EMAIL_RESEND Effect ] REQUEST_ACTIVATION_EMAIL_RESEND_FAILURE',
  props<{errorMsg: string}>()
);

export const reset = createAction(
  '[ Auth/ResendActivationEmail ][ REQUEST_ACTIVATION_EMAIL_RESEND_SUCCESS Effect ] RESET',
);

export const receiveReset = createAction(
  GLOBAL_RESET_SIGNAL_TYPE,
);

const all = union({
  resendActivationEmailClicked,
  emailForActivationResendSubmitted,
  activationEmailResendRequested,
  requestActivationEmailResend,
  requestActivationEmailResendSuccess,
  requestActivationEmailResendFailure,
  receiveReset,
});
export type Union = typeof all;
