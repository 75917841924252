import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appBlockRedundantClick]'
})
export class BlockRedundantClickDirective {

  @Input('appBlockRedundantClick') isInProgress = false;
  @Output() validClick = new EventEmitter<any>();

  @HostListener('click', ['$event'])
  onSubmit(ev) {
    if (this.isInProgress) {
      ev.preventDefault();
      ev.stopPropagation();
      return false;
    } else {
      this.validClick.emit(ev);
    }
  }
}
