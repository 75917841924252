import { CLOUD_POS_PROVIDERS } from '@configs/pos';
import { AUTH_ROUTES, COMPANY_PROFILE_ROUTES, ONBOARDING_ROUTE, MY_HUB_ROUTE, RECEIPTS_ROUTES, MYSLYP_V2_ROUTES } from '@configs/routing';
import { MerchantArtifactsAdapterService } from '@adapters/merchant-artifacts-adapter.service';
import { Location } from '@angular/common';
import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsUtils } from '@app/core/utils/errors';
import * as fromAuth from '@auth/store/auth.reducer';
import * as PosActions from '@company-profile/pos/store/pos.actions';
import { environment } from '@environments/environment';
import { IPOSProviderName, ICloudPOSProviderName } from '@interfaces/pos.interface';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATED, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as OnboardingActions from '@onboarding/store/onboarding.actions';
import * as fromOnboarding from '@onboarding/store/onboarding.reducer';
import * as ProcessActions from '@onboarding/store/process/process.actions';
import * as RoutingActions from '@routing/store/routing.actions';
import * as RoutingSelectors from '@routing/store/routing.selectors';
import * as SigninActions from '@signin/store/signin.actions';
import * as SigninSelectors from '@signin/store/signin.selectors';
import * as SignupActions from '@signup/store/signup.actions';
import * as SignupSelectors from '@signup/store/signup.selectors';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { UserRoleType } from '@app/core/interfaces/role.interface';


@Injectable()
export class RoutingEffects {

  authenticatedUserRouteDecisionNeeded$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.authenticatedUserRouteDecisionNeeded),
    map(() => RoutingActions.fetchMerchantAttributes())
  ));

  fetchMerchantAttributes$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.fetchMerchantAttributes),
    withLatestFrom(this.rxStore.select(SigninSelectors.selectMerchantId)),
    mergeMap(([_, merchantId]) => this.merchantArtifactsAdapter.fetchAttributes(merchantId).pipe( // check if currently logged in merchant is in the middle of onboarding
      map(attrs => RoutingActions.fetchMerchantAttributesSuccess({ attrs })),
      catchError(response => of(RoutingActions.fetchMerchantAttributesFailure({ errorMsg: ErrorsUtils.getMsg({ response }) })))
    )),
  ));

  fetchMerchantAttributesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.fetchMerchantAttributesSuccess),
    map(({ attrs }) => RoutingActions.decideAuthenticatedUserRoute({ attrs }))
  ));

  fetchMerchantAttributesFailure$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.fetchMerchantAttributesFailure),
    map(({ errorMsg }) => SigninActions.handleAuthFailure({ msg: `Couldn't fetch merchant attributes. ${errorMsg}` }))
  ));

  decideAuthenticatedUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideAuthenticatedUserRoute),
    withLatestFrom(this.rxStore.select(RoutingSelectors.selectIsOnboardingInProgress)),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectIsSignupInProgress)),
    withLatestFrom(this.rxStore.select(SignupSelectors.selectIsPasswordRecoveryInProgress)),
    withLatestFrom(this.rxStore.select(SigninSelectors.selectUserRole)),
    withLatestFrom(this.route.queryParams),
    map(([[[[[{ attrs }, isOnboardingInProgress], isSignupInProgress], isPasswordRecoveryInProgress], userRole], params]) => {
      if (isSignupInProgress) { // detect signup at confirm password success stage
        return RoutingActions.routeToSignupComplete();
      }

      if (isPasswordRecoveryInProgress) { // detect signup at recover password success stage
        return RoutingActions.routeToPasswordRecovered();
      }

      if (userRole === UserRoleType.storeOps) {
        return RoutingActions.routeToReceiptFeed();
      }
      const { code, state, error } = params;
      const [posName, target] = state ? state.split('_') : [null, null];
      const isOnboardingV2POSRedirectInProgress = code && state && `${state}`.endsWith("v2");
      const isOnboardingV2POSErrorRedirect = !!error;
      if (isOnboardingV2POSErrorRedirect) {
        return RoutingActions.routeToOnboardingV2PosRedirectError({ error });
      }
      if (isOnboardingV2POSRedirectInProgress) {
        return RoutingActions.routeToOnboardingV2PosRedirectSync({ code, state });
      }
      if (isOnboardingInProgress && attrs.phase === 'onboarding_v2') {
        return RoutingActions.routeToOnboardingV2();
      }

      // force user to complete onboarding process if it hasn't been done yet

      const isOnboardingPOSRedirectInProgress = isOnboardingInProgress && code && state && target === 'onboarding';
      if (isOnboardingPOSRedirectInProgress) { // detect redirection step
        return RoutingActions.routeToOnboardingPosRedirect({ code, posName });
      }

      if (isOnboardingInProgress) { // detect unfinished onboarding at different step than sync redirect
        return RoutingActions.routeToOnboarding({
          phase: attrs.phase,
          posName: attrs.pos_provider as IPOSProviderName
        });
      }

      const isCompanyProfilePOSRedirectInProgress = !isOnboardingInProgress && code && state && (target === 'resync' || target === 'sync');
      if (isCompanyProfilePOSRedirectInProgress) { // detect redirection step
        return RoutingActions.routeToCompanyProfilePosRedirect({ code, posName, flowType: target });
      }

      const isSavedPOSProviderCloudBased = CLOUD_POS_PROVIDERS.includes(attrs.pos_provider as ICloudPOSProviderName);
      const isCompanyProfilePOSSyncInProgress = !isOnboardingInProgress && isSavedPOSProviderCloudBased && attrs.sync_status === 'in_progress'; // no way of telling if sync/resync here
      if (isCompanyProfilePOSSyncInProgress) {
        return RoutingActions.routeToCompanyProfileInterruptedPosSync({ posName: attrs.pos_provider as IPOSProviderName });
      }

      const hasUserJustSignedIn = !isOnboardingInProgress && window.location.pathname.includes(AUTH_ROUTES.self.url);
      const hasUserJustOnboarded = !isOnboardingInProgress && window.location.pathname.includes(ONBOARDING_ROUTE.url);
      if (hasUserJustSignedIn || hasUserJustOnboarded) {
        return RoutingActions.routeToMyHub();
      }

      // this lets user through to path that he's entered in url bar
      return RoutingActions.routeToCurrentUrl();
    }),
    mergeMap(action => [
      action,
      SigninActions.authenticationProcessCompleted()
    ])
  ));

  decideOnboardedUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideOnboardedUserRoute),
    map(() => RoutingActions.routeToCompanyInfo())
  ));

  decideSignedOutUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideSignedOutUserRoute),
    map(() => RoutingActions.routeToAuth())
  ));

  decideSignedUpUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideSignedUpUserRoute),
    map(() => RoutingActions.routeToOnboarding({ phase: 'select_pos', posName: null }))
  ));

  decideUnathenticatedUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideUnathenticatedUserRoute),
    delay(500), // somehow necessary for angular to sync ActivatedRoute.queryParams
    withLatestFrom(this.route.queryParams),
    map(([_, params]) => {
      const { email, secret, target, code, state, error } = params;
      const shouldRouteToPasswordRecovery = email && secret && target === 'password_recovery';
      const shouldRouteToEmailVerification = email && secret && target === 'activation';
      const isOnboardingV2POSRedirectInProgress = code && state && `${state}`.endsWith("v2");
      const isOnboardingV2POSErrorRedirect = !!error;
      if (isOnboardingV2POSErrorRedirect) {
        return RoutingActions.routeToOnboardingV2PosRedirectError({ error });
      }
      if (isOnboardingV2POSRedirectInProgress) {
        return RoutingActions.routeToOnboardingV2PosRedirectSync({ code, state });
      }

      if (shouldRouteToPasswordRecovery) {
        return RoutingActions.routeToSignupPasswordRecovery({ email, secret });
      }

      if (shouldRouteToEmailVerification) {
        return RoutingActions.routeToSignupEmailVerification({ email, secret });
      }

      if (environment.devEmptyLogin) {
        return SigninActions.setDummySession();
      } else {
        return SigninActions.retrieveSavedOktaSession();
      }
    })
  ));

  decideUnauthorizedUserRoute$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.decideUnauthorizedUserRoute),
    mergeMap(() => this.router.url === '/' ? [RoutingActions.routeToAuth()] : [])
  ));

  routeToSignupEmailVerification$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToSignupEmailVerification),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([AUTH_ROUTES.signup.url]))).pipe(
      map(() => action)
    )),
    mergeMap(({ secret, email }) => [
      RoutingActions.routingCompleted(),
      SignupActions.pickUpSignupAtEmailVerification({ secret, email }),
      RoutingActions.tidyUpQueryParams(),
    ])
  ));

  routeToSignupPasswordRecovery$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToSignupPasswordRecovery),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([AUTH_ROUTES.signup.url]))).pipe(
      map(() => action)
    )),
    mergeMap(({ secret, email }) => [
      RoutingActions.routingCompleted(),
      SignupActions.pickUpSignupAtPasswordRecovery({ secret, email }),
      RoutingActions.tidyUpQueryParams(),
    ])
  ));

  routeToSignupComplete$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToSignupComplete),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([AUTH_ROUTES.signup.url]))).pipe(
      map(() => action)
    )),
    mergeMap(() => [
      RoutingActions.routingCompleted(),
      SignupActions.pickUpSignupAtComplete()
    ])
  ));

  routeToPasswordRecoveryComplete$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToPasswordRecovered),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([AUTH_ROUTES.signup.url]))).pipe(
      map(() => action)
    )),
    mergeMap(() => [
      RoutingActions.routingCompleted(),
      SignupActions.pickUpSignupAtPasswordRecovered()
    ])
  ));

  routeToCurrentUrl$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToCurrentUrl),
    map(() => RoutingActions.routingCompleted())
  ));

  routeToOnboarding$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToOnboarding),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([ONBOARDING_ROUTE.url]))).pipe( // cause onboarding module to lazy load
      map(() => action)
    )),
    mergeMap(({ phase, posName }) => [
      RoutingActions.routingCompleted(),
      ProcessActions.pickUpOnboardingAtPhase({ phase, posName })
    ])
  ));

  routeToOnboardingPosRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToOnboardingPosRedirect),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([ONBOARDING_ROUTE.url]))).pipe( // cause onboarding module to lazy load
      map(() => action)
    )),
    mergeMap(({ code, posName }) => [
      RoutingActions.routingCompleted(),
      ProcessActions.pickUpOnboardingAtPosRedirect({ code, posName }),
      RoutingActions.tidyUpQueryParams(),
    ])
  ));

  routeToOnboardingV2$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToOnboardingV2),
    switchMap(() => this.zone.runTask(() => from(this.router.navigate([MYSLYP_V2_ROUTES.onboarding.url])))),
    map(() => RoutingActions.routingCompleted())
  ));

  routeToOnboardingV2PosRedirectSync$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToOnboardingV2PosRedirectSync),
    switchMap(({ state, code }) => this.zone.runTask(() => {
      const url = new URL(['onboarding', 'connect-pos'].join('/'), environment.mySlypV2RedirectUrl);
      url.searchParams.set('code', code);
      url.searchParams.set('state', state);
      window.location.replace(url.href);
      return of(true);
    })),
  ), { dispatch: false });

  routeToOnboardingV2PosRedirectError$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToOnboardingV2PosRedirectError),
    switchMap(({ error }) => this.zone.runTask(() => {
      const url = new URL(['onboarding', 'connect-pos'].join('/'), environment.mySlypV2RedirectUrl);
      url.searchParams.set('error', error);
      window.location.replace(url.href);
      return of(true);
    })),
  ), { dispatch: false });

  routeToCompanyProfilePosRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToCompanyProfilePosRedirect),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([COMPANY_PROFILE_ROUTES.pos.url]))).pipe(
      map(() => action)
    )),
    mergeMap(({ code, posName, flowType }) => [
      RoutingActions.routingCompleted(),
      PosActions.handlePosRedirectCallback({ code, providerName: posName, flowType }),
      RoutingActions.tidyUpQueryParams(),
    ])
  ));

  routeToCompanyProfileInterruptedPosSync$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToCompanyProfileInterruptedPosSync),
    mergeMap(action => this.zone.runTask(() => from(this.router.navigate([COMPANY_PROFILE_ROUTES.pos.url]))).pipe(
      map(() => action)
    )),
    mergeMap(({ posName }) => [
      RoutingActions.routingCompleted(),
      PosActions.resumePosSync({ providerName: posName }),
      RoutingActions.tidyUpQueryParams(),
    ])
  ));


  routeToCompanyInfo$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToCompanyInfo),
    mergeMap(() => this.zone.runTask(() => from(this.router.navigate([COMPANY_PROFILE_ROUTES.companyInfo.url])))),
    mergeMap(() => [
      RoutingActions.routingCompleted(),
      OnboardingActions.signalResetOnboarding()
    ]) // because it's used in decideOnboardedUserRoute
  ));

  routeToMyHub$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToMyHub),
    switchMap(() => this.zone.runTask(() => from(this.router.navigate([MY_HUB_ROUTE.url])))),
    map(() => RoutingActions.routingCompleted())
  ));
  routeToReceiptFeed$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToReceiptFeed),
    switchMap(action => this.zone.runTask(() => from(this.router.navigate([RECEIPTS_ROUTES.feed.url]))).pipe(
      map(() => action),
    )),
    map(() => RoutingActions.routingCompleted())
  ));

  routeToAuth$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToAuth),
    map(() => this.router.url.includes('signup') ? AUTH_ROUTES.signup.url : AUTH_ROUTES.signin.url),
    switchMap(url => this.zone.runTask(() => from(this.router.navigate([url])))),
    map(() => RoutingActions.routingCompleted())
  ));

  routingCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routingCompleted),
    delay(500),
    tap(() => this.app.tick())
  ), { dispatch: false });

  tidyUpQueryParams$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.tidyUpQueryParams),
    switchMap(() => this.route.queryParams.pipe(take(1))),
    map(params => Object.keys(params).length),
    map(isTidyingUpNeeded => !isTidyingUpNeeded ? null : this.router.createUrlTree([{}], { relativeTo: this.route }).toString()), // construct url without query params if necessary
    tap(url => url && this.location.go(url)) // sync router but doesn't trigger naviation
  ), { dispatch: false });

  navigated$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    map((action: RouterNavigatedAction) => action.payload.routerState),
    map(routerState => ({
      sectionTitle: this.getRouteTitle(routerState, 'section'),
      viewTitle: this.getRouteTitle(routerState, 'view'),
    })),
    map(data => RoutingActions.routeTitleChanged(data))
  ));

  titleChanged$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeTitleChanged),
    withLatestFrom(this.rxStore.select(RoutingSelectors.selectPageTitle)),
    tap(([_, title]) => this.title.setTitle(`MySlyp - ${title}`))
  ), { dispatch: false });

  routeToSignOutV2$ = createEffect(() => this.actions$.pipe(
    ofType(RoutingActions.routeToSignOutV2),
    switchMap(() =>
      this.zone.runTask(() => {
        const url = new URL('logout', environment.mySlypV2RedirectUrl);
        window.location.replace(url.href);
        return of(true);
      })
    )
  ),
    { dispatch: false }
  );


  constructor(
    private app: ApplicationRef,
    private zone: NgZone,
    private title: Title,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private actions$: Actions,
    private rxStore: Store<fromAuth.AppState | fromOnboarding.AppState>,
    private merchantArtifactsAdapter: MerchantArtifactsAdapterService,
  ) { }

  private getRouteTitle(routerState: SerializedRouterStateSnapshot, kind: 'section' | 'view'): string {
    let route = routerState.root;
    const dataKey = kind + 'Title';

    while (!!route) {
      if (route.data && route.data[dataKey]) {
        return route.data[dataKey];
      }
      route = route.firstChild;
    }
    return null;
  }
}
