import { createSelector } from '@ngrx/store';
import * as AuthSelectors from '@auth/store/auth.selectors';

export const selectState = createSelector(
  AuthSelectors.selectState,
  state => state.forgotPassword
);

export const selectIsRequesting = createSelector(
  selectState,
  state => state.isRequesting
);

export const selectView = createSelector(
  selectState,
  state => state.view
);
